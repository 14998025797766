import React from "react";

function StampaMercatoBar(props) {
    return (
        <>
            <div className="d-flex justify-content-center align-items-center">
                <div className="box-bar mt-5 mb-5" style={{width: '50%'}}>
                    <div className="container mb-5 " >
                        <table class="table mt-5">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col" className="text-right">Dati PIVA</th>
                                    <th scope="col" className="text-right">Media Mercato</th>
                                    <th scope="col" className="text-right">%</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Totale Immobilizzazioni</td>
                                    <td className="text-right">{props.totaleIVA}</td>
                                    <td className="text-right">{props.totale}</td>
                                    <td className="text-right" style={{color: checkPositive(props.totaleVariation)}}><span><i class={checkPositiveArrow(props.totaleVariation)} style={{color: checkPositive(props.totaleVariation)}}></i></span>{props.totaleVariation !== '0' ? props.totaleVariation : ''}%</td>
                                </tr>
                                <tr>
                                    <td>Totale Valore Produzione</td>
                                    <td className="text-right">{props.tot_val_produzioneIVA}</td>
                                    <td className="text-right">{props.tot_val_produzione}</td>
                                    <td className="text-right" style={{color: checkPositive(props.tot_val_produzioneVariation)}}><span><i class={checkPositiveArrow(props.tot_val_produzioneVariation)} style={{color: checkPositive(props.tot_val_produzioneVariation)}}></i></span>{props.tot_val_produzioneVariation !== '0' ? props.tot_val_produzioneVariation : ''}%</td>
                                </tr>
                                <tr>
                                    <td>Totale Debiti</td>
                                    <td className="text-right">{props.totale_debitiIVA}</td>
                                    <td className="text-right">{props.totale_debiti}</td>
                                    <td className="text-right" style={{color: checkPositive(props.totale_debitiVariation)}}><span><i class={checkPositiveArrow(props.totale_debitiVariation)} style={{color: checkPositive(props.totale_debitiVariation)}}></i></span>{props.totale_debitiVariation !== '0' ? props.totale_debitiVariation : ''}%</td>
                                </tr>
                                <tr>
                                    <td>PFN Ebitda</td>
                                    <td className="text-right">{props.pfn_ebitdaIVA}</td>
                                    <td className="text-right">{props.pfn_ebitda}</td>
                                    <td className="text-right" style={{color: checkPositive(props.pfn_ebitdaVariation)}}><span><i class={checkPositiveArrow(props.pfn_ebitdaVariation)} style={{color: checkPositive(props.pfn_ebitdaVariation)}}></i></span>{props.pfn_ebitdaVariation !== '0' ? props.pfn_ebitdaVariation : ''}%</td>
                                </tr>
                                <tr>
                                    <td>Totale Fondi Rischi</td>
                                    <td className="text-right">{props.totale_fondi_rischiIVA}</td>
                                    <td className="text-right">{props.totale_fondi_rischi}</td>
                                    <td className="text-right" style={{color: checkPositive(props.totale_fondi_rischiVariation)}}><span><i class={checkPositiveArrow(props.totale_fondi_rischiVariation)} style={{color: checkPositive(props.totale_fondi_rischiVariation)}}></i></span>{props.totale_fondi_rischiVariation !== '0' ? props.totale_fondi_rischiVariation : ''}%</td>
                                </tr>
                                <tr>
                                    <td>Attivo Circolante</td>
                                    <td className="text-right">{props.attivo_circolanteIVA}</td>
                                    <td className="text-right">{props.attivo_circolante}</td>
                                    <td className="text-right" style={{color: checkPositive(props.attivo_circolanteVariation)}}><span><i class={checkPositiveArrow(props.attivo_circolanteVariation)} style={{color: checkPositive(props.attivo_circolanteVariation)}}></i></span>{props.attivo_circolanteVariation !== '0' ? props.attivo_circolanteVariation : ''}%</td>
                                </tr>
                                <tr>
                                    <td>Ratei Riscontri</td>
                                    <td className="text-right">{props.ratei_riscontiIVA}</td>
                                    <td className="text-right">{props.ratei_risconti}</td>
                                    <td className="text-right" style={{color: checkPositive(props.ratei_riscontiVariation)}}><span><i class={checkPositiveArrow(props.ratei_riscontiVariation)} style={{color: checkPositive(props.ratei_riscontiVariation)}}></i></span>{props.ratei_riscontiVariation !== '0' ? props.ratei_riscontiVariation : ''}%</td>
                                </tr>
                                <tr>
                                    <td>Ebitda</td>
                                    <td className="text-right">{props.ebitdaIVA}</td>
                                    <td className="text-right">{props.ebitda}</td>
                                    <td className="text-right" style={{color: checkPositive(props.ebitdaVariation)}}><span><i class={checkPositiveArrow(props.ebitdaVariation)} style={{color: checkPositive(props.ebitdaVariation)}}></i></span>{props.ebitdaVariation !== '0' ? props.ebitdaVariation : ''}%</td>
                                </tr>
                                <tr>
                                    <td>PFN</td>
                                    <td className="text-right">{props.pfnIVA}</td>
                                    <td className="text-right">{props.pfn}</td>
                                    <td className="text-right" style={{color: checkPositive(props.pfnVariation)}}><span><i class={checkPositiveArrow(props.pfnVariation)} style={{color: checkPositive(props.pfnVariation)}}></i></span>{props.pfnVariation !== '0' ? props.pfnVariation : ''}%</td>
                                </tr>
                                <tr>
                                    <td>Utile</td>
                                    <td className="text-right">{props.utileIVA}</td>
                                    <td className="text-right">{props.utile}</td>
                                    <td className="text-right" style={{color: checkPositive(props.utileVariation)}}><span><i class={checkPositiveArrow(props.utileVariation)} style={{color: checkPositive(props.utileVariation)}}></i></span>{props.utileVariation !== '0' ? props.utileVariation : ''}%</td>
                                </tr>
                                <tr>
                                    <td>Debiti Breve</td>
                                    <td className="text-right">{props.debiti_breveIVA}</td>
                                    <td className="text-right">{props.debiti_breve}</td>
                                    <td className="text-right" style={{color: checkPositive(props.debiti_breveVariation)}}><span><i class={checkPositiveArrow(props.debiti_breveVariation)} style={{color: checkPositive(props.debiti_breveVariation)}}></i></span>{props.debiti_breveVariation !== '0' ? props.debiti_breveVariation : ''}%</td>
                                </tr>
                                <tr>
                                    <td>Debiti Lungo</td>
                                    <td className="text-right">{props.debiti_lungoIVA}</td>
                                    <td className="text-right">{props.debiti_lungo}</td>
                                    <td className="text-right" style={{color: checkPositive(props.debiti_lungoVariation)}}><span><i class={checkPositiveArrow(props.debiti_lungoVariation)} style={{color: checkPositive(props.debiti_lungoVariation)}}></i></span>{props.debiti_lungoVariation !== '0' ? props.debiti_lungoVariation : ''}%</td>
                                </tr>
                                <tr>
                                    <td>Dipendenti</td>
                                    <td className="text-right">{props.dipendentiIVA}</td>
                                    <td className="text-right">{props.dipendenti}</td>
                                    <td className="text-right" style={{color: checkPositive(props.dipendentiVariation)}}><span><i class={checkPositiveArrow(props.dipendentiVariation)} style={{color: checkPositive(props.dipendentiVariation)}}></i></span>{props.dipendentiVariation !== '0' ? props.dipendentiVariation : ''}%</td>
                                </tr>
                                <tr>
                                    <td>Ebitda/Vendite</td>
                                    <td className="text-right">{props.evIVA}</td>
                                    <td className="text-right">{props.ev}</td>
                                    <td className="text-right" style={{color: checkPositive(props.evVariation)}}><span><i class={checkPositiveArrow(props.evVariation)} style={{color: checkPositive(props.evVariation)}}></i></span>{props.evVariation !== '0' ? props.evVariation : ''}%</td>
                                </tr>
                                <tr>
                                    <td>Ebitda Fatturato</td>
                                    <td className="text-right">{props.ebitda_fatturatoIVA}</td>
                                    <td className="text-right">{props.ebitda_fatturato}</td>
                                    <td className="text-right" style={{color: checkPositive(props.ebitda_fatturatoVariation)}}><span><i class={checkPositiveArrow(props.ebitda_fatturatoVariation)} style={{color: checkPositive(props.ebitda_fatturatoVariation)}}></i></span>{props.ebitda_fatturatoVariation !== '0' ? props.ebitda_fatturatoVariation : ''}%</td>
                                </tr>
                                <tr>
                                    <td>Fatturato</td>
                                    <td className="text-right">{props.fatturatoIVA}</td>
                                    <td className="text-right">{props.fatturato}</td>
                                    <td className="text-right" style={{color: checkPositive(props.fatturatoVariation)}}><span><i class={checkPositiveArrow(props.fatturatoVariation)} style={{color: checkPositive(props.fatturatoVariation)}}></i></span>{props.fatturatoVariation !== '0' ? props.fatturatoVariation : ''}%</td>
                                </tr>
                                <tr>
                                    <td>Oneri Finanziari Ebitda</td>
                                    <td className="text-right">{props.oneri_fin_ebitdaIVA}</td>
                                    <td className="text-right">{props.oneri_fin_ebitda}</td>
                                    <td className="text-right" style={{color: checkPositive(props.oneri_fin_ebitdaVariation)}}><span><i class={checkPositiveArrow(props.oneri_fin_ebitdaVariation)} style={{color: checkPositive(props.oneri_fin_ebitdaVariation)}}></i></span>{props.oneri_fin_ebitdaVariation !== '0' ? props.oneri_fin_ebitdaVariation : ''}%</td>
                                </tr>
                                <tr>
                                    <td>PN</td>
                                    <td className="text-right">{props.pnIVA}</td>
                                    <td className="text-right">{props.pn}</td>
                                    <td className="text-right" style={{color: checkPositive(props.pnVariation)}}><span><i class={checkPositiveArrow(props.pnVariation)} style={{color: checkPositive(props.pnVariation)}}></i></span>{props.pnVariation !== '0' ? props.pnVariation : ''}%</td>
                                </tr>
                                <tr>
                                    <td>Totale PN Passivo</td>
                                    <td className="text-right">{props.pn_tot_passivoIVA}</td>
                                    <td className="text-right">{props.pn_tot_passivo}</td>
                                    <td className="text-right" style={{color: checkPositive(props.pn_tot_passivoVariation)}}><span><i class={checkPositiveArrow(props.pn_tot_passivoVariation)} style={{color: checkPositive(props.pn_tot_passivoVariation)}}></i></span>{props.pn_tot_passivoVariation !== '0' ? props.pn_tot_passivoVariation : ''}%</td>
                                </tr>
                                <tr>
                                    <td>TFR</td>
                                    <td className="text-right">{props.tfrIVA}</td>
                                    <td className="text-right">{props.tfr}</td>
                                    <td className="text-right" style={{color: checkPositive(props.tfrVariation)}}><span><i class={checkPositiveArrow(props.tfrVariation)} style={{color: checkPositive(props.tfrVariation)}}></i></span>{props.tfrVariation !== '0' ? props.tfrVariation : ''}%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}
export default StampaMercatoBar

function checkPositive(data){

    data = parseInt(data)

    if(data > 0){
        return "green";
    } else if(data < 0) {
        return "red";
    } else {
        return "black"
    }
}

function checkPositiveArrow(data){

    data = parseInt(data)

    if(data > 0){
        return "bi bi-caret-up-fill"
    } else if (data === 0){
        return ""
    } else {
        return "bi bi-caret-down-fill"
    }
}