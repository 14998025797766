import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BackgroundComponent from "./BackgroundComponent";
import ButtonComponent from './ButtonComponent'
import SubTitleComponent from "./SubTitleComponent";
import TitleComponent from "./TitleComponent";
import VideoComponent from "./VideoComponent";

export default function TextVideoComponent({ classNameTitle = 'titleComponent', classNameContainer = 'spacing', className, labelTutorial2, backgroundImage, clickButton, path, reverse, container, align, titolo, body, checkButton, textButton, variationButton, backgroundColor, radius, labelTutorial }) {

    // const col = () => {
    //     return (
    //         <>

    //         </>
    //     )
    // }

    return (
        <>
            {
                !container ?
                    <>
                        <BackgroundComponent backgroundColor={backgroundColor} className={'py-5 spacing'} imageBackground={backgroundImage}>
                            <Row className="d-flex justify-content-center align-items-center">
                                {
                                    !reverse ?
                                        <>
                                            <Col xl='6'>
                                                <div className={`d-flex justify-content-center justify-content-xl-${align} align-items-center`}>
                                                    <div style={{ width: '642px' }} className="d-block">
                                                        <div className={`d-flex justify-content-${align}`}>
                                                            <TitleComponent className={`text-${align} pt-3 px-0 px-xl-0 titleComponent mt-5`} text={titolo} />
                                                        </div>
                                                        <SubTitleComponent text={body} className={`text-xl-${align} text-center pt-3 px-0 px-xl-0`} />
                                                        {
                                                            checkButton ?
                                                                <>
                                                                    <div className={`d-flex justify-content-center justify-content-${align} mb-4 mb-xl-0`}>
                                                                        <ButtonComponent text={textButton} handleClick={clickButton} typeBootstrapButton={variationButton} />
                                                                    </div>
                                                                </> :
                                                                null
                                                        }

                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl='6'>
                                                <VideoComponent path={path} />
                                            </Col>
                                        </> :
                                        <>
                                            <Col xl='6'>
                                                <VideoComponent path={path} />
                                            </Col>
                                            <Col xl='6'>
                                                <Container>
                                                    <div className={`d-flex justify-content-center justify-content-end align-items-center`}>
                                                        <div style={{ width: '642px' }} className="d-block">
                                                            <div className="d-flex justify-content-end">
                                                                <TitleComponent className={`text-xl-${align} text-center pt-3 px-0 px-xl-0 titleComponent mt-5`} text={titolo} />
                                                            </div>
                                                            <SubTitleComponent text={body} align={align} />
                                                            {
                                                                checkButton ?
                                                                    <>
                                                                        <div className={`d-flex justify-content-center justify-content-xl-${align} mb-4 mb-xl-0`}>
                                                                            <ButtonComponent text={textButton} handleClick={clickButton} typeBootstrapButton={variationButton} />
                                                                        </div>
                                                                    </> :
                                                                    null
                                                            }

                                                        </div>
                                                    </div>
                                                </Container>
                                            </Col>
                                        </>
                                }
                            </Row>
                        </BackgroundComponent>
                    </> :
                    <>
                        <Container className={`${classNameContainer} d-flex justify-content-center`}>
                            <BackgroundComponent className={`pt-3 py-xxl-5 h-100 px-0 px-md-5 d-flex justify-content-center ${className}`} radius={radius} backgroundSize='contain' backgroundColor={backgroundColor} imageBackground={backgroundImage}>
                                <Row className="d-flex justify-content-center align-items-center">
                                    {
                                        !reverse ?
                                            <>
                                                <Col xl='6' className="d-flex justify-content-center">
                                                    <Container className="d-flex justify-content-center py-3">
                                                        <div className={`d-flex justify-content-center justify-content-xl-${align} align-items-center`}>
                                                            <div className="d-block px-4">
                                                                <div className={`d-flex justify-content-xl-${align} mt-5 pt-5 pt-xl-0 mt-xl-0 justify-content-center px-5`}>
                                                                    <TitleComponent className={`text-xl-${align} text-center pt-3 px-0 px-xl-0 titleComponent`} text={titolo} />
                                                                </div>
                                                                <SubTitleComponent text={body} className={`text-xl-${align} text-center pt-3 px-0 px-xl-5`} />
                                                                {
                                                                    checkButton ?
                                                                        <>
                                                                            <div className={`d-flex justify-content-center justify-content-xl-${align} mb-4 mb-xl-0`}>
                                                                                <ButtonComponent text={textButton} handleClick={clickButton} typeBootstrapButton={variationButton} />
                                                                            </div>
                                                                        </> :
                                                                        null
                                                                }

                                                            </div>
                                                        </div>
                                                    </Container>
                                                </Col>
                                                <Col xl='6' className="d-flex justify-content-center">
                                                    <VideoComponent path={path} />
                                                </Col>
                                            </> :
                                            <>
                                                <Col xl='6' className="d-flex justify-content-center">
                                                    <VideoComponent path={path} />
                                                </Col>
                                                <Col xl='6' className="d-flex justify-content-center">
                                                    <Container className="d-flex justify-content-center py-3">
                                                        <div className={`d-flex justify-content-center align-items-center`}>
                                                            <div className="d-block">
                                                                {labelTutorial}
                                                                <div className={`d-flex justify-content-${align}`}>
                                                                    <TitleComponent className={`text-xl-${align} text-start pt-3 px-0 px-xl-0 ${window.location.pathname === '/analisi-bilancio' ? 'boxTitoloVideo' : window.location.pathname === '/analisi-cr' ? 'titleComponentVideoCR' : 'titleComponent'}`} text={titolo} />
                                                                </div>
                                                                {
                                                                    body !== '' ?
                                                                        <>
                                                                            <SubTitleComponent text={body} className={`text-xl-${align} text-center pt-3 px-0 px-xl-0`} />
                                                                        </> : null
                                                                }
                                                                {labelTutorial2}
                                                                {
                                                                    checkButton ?
                                                                        <>
                                                                            <div className={`d-flex justify-content-center justify-content-xl-${align} mb-4 mb-xl-0`}>
                                                                                <ButtonComponent text={textButton} handleClick={clickButton} typeBootstrapButton={variationButton} />
                                                                            </div>
                                                                        </> :
                                                                        null
                                                                }

                                                            </div>
                                                        </div>
                                                    </Container>
                                                </Col>
                                            </>
                                    }
                                </Row>
                            </BackgroundComponent>
                        </Container>
                    </>
            }
        </>
    )
}