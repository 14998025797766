import React from "react";
import { Col, Row, Image } from "react-bootstrap";
import BackgroundComponent from "../components/BackgroundComponent";
import backgroundResults from '../assets/images/backgroundResults.png'
import SubTitleComponent from '../components/SubTitleComponent'
import TitleComponent from '../components/TitleComponent'
import WhiteLineComponent from "../components/WhiteLineComponent";
import Card from '../components/Card'
import ButtonComponent from '../components/ButtonComponent'
import CardResults from "../components/CardResults";
import { AreaChart, BarChart, SteppedAreaChart } from "../components/general/results/Chart";
import TextButtonComponent from "./TextButtonComponent";
import fakeChart from './../assets/images/fake.png'

export default function ResultsComponent({ nameCompany, partitaIVA, anni, dipendenti, codiceAteco, costituzione, anniBilancio, fatturato, patrimonioNetto, ebitda, pfn, variation }) {

    return (
        <>
            <div className="d-flex justify-content-center">
                <div className="pt-5 mt-5" style={{ width: '1529px' }}>
                    <BackgroundComponent className={'py-5 px-2 px-xl-5 mt-5 mx-2 mx-xl-5'} radius='20px 20px 0px 0px' imageBackground={backgroundResults} linerGradient='180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%'>
                        <div className="d-flex justify-content-center mb-4">
                            <SubTitleComponent text={'I <strong>risultati della ricerca</strong> per la Partita IVA inserita'} className='subTitleComponent text-center' />
                        </div>
                        <Row className="d-flex justify-content-center mb-5">
                            <Col xl='3' className="bg-white me-xl-4 me-0 py-3" style={{ borderRadius: '20px', boxShadow: '0px 4px 33px rgba(0, 0, 0, 0.075)' }}>
                                <div className="d-flex justify-content-center">
                                    <SubTitleComponent className={'robotoNormal pt-4'} text='ANAGRAFICA' style={{ color: '#808080', fontSize: '16px', marginBottom: '-1px' }} />
                                </div>
                                <div className="d-flex justify-content-center mt-3 px-4 px-xl-3">
                                    <TitleComponent text={nameCompany} className='pb-4 subTitleComponent text-center' style={nameCompany.length < 20 ? { color: '#06152C' } : { color: '#06152C', fontSize: '15px' }} />
                                </div>
                                <div className="d-flex justify-content-center">
                                    <WhiteLineComponent color='#2269F4' size={'162px'} />
                                </div>
                                <Card titolo={'PARTITA IVA'} border='' width="20px" height="20px" className='mt-4 mb-3' classNameSubTitolo='roboto' styleSubTitolo={{ fontSize: '18px' }} styleTitolo={{ fontSize: '18px', color: '#808080' }} text={`${partitaIVA}`} icona='chartcircle.png' />
                                <Card titolo={'ANNI DI ATTIVITÀ'} border='' width="20px" height="20px" className='mb-3' classNameSubTitolo='roboto' styleSubTitolo={{ fontSize: '18px' }} styleTitolo={{ fontSize: '18px', color: '#808080' }} text={`${anni === '' ? '---' : anni}`} icona='calendar1.png' />
                                <Card titolo={'DIPENDENTI'} border='' width="20px" height="20px" className='mb-3' classNameSubTitolo='roboto' styleSubTitolo={{ fontSize: '18px' }} styleTitolo={{ fontSize: '18px', color: '#808080' }} text={`${dipendenti === '' ? '---' : dipendenti}`} icona='profile2user.png' />
                                <Card titolo={'CODICE ATECO'} border='' width="20px" height="20px" className='mb-3' classNameSubTitolo='roboto' styleSubTitolo={{ fontSize: '18px' }} styleTitolo={{ fontSize: '18px', color: '#808080' }} text={`${codiceAteco}`} icona='barcode.png' />
                                <Card titolo={'DATA DI COSTITUZIONE'} border='' width="20px" height="20px" classNameSubTitolo='roboto' styleSubTitolo={{ fontSize: '18px' }} styleTitolo={{ fontSize: '18px', color: '#808080' }} className='mb-5' text={`${costituzione === '' ? '---' : costituzione}`} icona='calendar2.png' />
                            </Col>
                            <Col xl='7' className="py-xl-3 mt-4 mt-xl-0 px-4 px-xl-0" style={{ backgroundColor: '#F5F8FF', borderRadius: '20px', boxShadow: '0px 4px 33px rgba(0, 0, 0, 0.075)' }}>
                                <div className="mx-5 d-none d-xl-block">
                                    <div className={`d-flex justify-content-center align-items-center`}>
                                        <div className="d-block">
                                            <SubTitleComponent className={'robotoNormal pt-4'} text='ANALISI BILANCIO' style={{ color: '#808080', fontSize: '16px', marginBottom: '-1px' }} />
                                            <TitleComponent text={``} className='pb-4 subTitleComponent' style={{ color: '#06152C' }} />
                                        </div>
                                        <div className="ms-auto">
                                            <ButtonComponent text={'<strong>Visualizza report e rating</strong>'} typeBootstrapButton='primary' handleClick={() => { window.location.href = 'https://data.pmiagile.com/' }} marginBottom={''} />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-block d-xl-none">
                                    <div className={`d-flex justify-content-center align-items-center`}>
                                        <div className="d-block text-center">
                                            <SubTitleComponent className={'roboto pt-4'} text='ANALISI BILANCIO' style={{ color: '#808080', fontSize: '16px', marginBottom: '-1px' }} />
                                            <TitleComponent text={``} className='pb-4 subTitleComponent' style={{ color: '#06152C' }} />
                                        </div>
                                    </div>
                                </div>
                                <Row className="d-flex justify-content-center">
                                    <CardResults
                                        variation={'---'}
                                        margin={'me-xl-4 me-0'}
                                        number={<SubTitleComponent style={{userSelect: 'none'}} text={'---'} className='subTitleComponent roboto' />}
                                        titolo={<SubTitleComponent className={'robotoNormal'} text='FATTURATO' style={{ color: '#808080', fontSize: '16px', marginTop: '-15px', userSelect: 'none' }} />}
                                        chart={<AreaChart anno1={1} anno2={2} anno3={3} year1={100} year2={50} year3={150} colorStart='#86ACF5' colorMid='#A0BDF4' colorEnd='#EBC1FF' />}
                                    />
                                    <CardResults
                                        variation={'---'}
                                        margin={'mt-4 mt-xl-0'}
                                        number={<SubTitleComponent style={{userSelect: 'none'}} text={'---'} className='subTitleComponent roboto' />}
                                        titolo={<SubTitleComponent className={'robotoNormal'} text='PATRIMONIO NETTO' style={{ color: '#808080', fontSize: '16px', marginTop: '-15px', userSelect: 'none' }} />}
                                        chart={<BarChart anno1={1} anno2={2} anno3={3} year1={150} year2={250} year3={500} colorStart='#ADA0DE' colorEnd='#BCCFFF' />}
                                    />
                                    <CardResults
                                        variation={'---'}
                                        margin={'mt-4'}
                                        number={<SubTitleComponent style={{userSelect: 'none'}} text={'---'} className='subTitleComponent roboto' />}
                                        titolo={<SubTitleComponent className={'robotoNormal'} text='EBITDA' style={{ color: '#808080', fontSize: '16px', marginTop: '-15px', userSelect: 'none' }} />}
                                        chart={<BarChart anno1={1} anno2={2} anno3={3} year1={300} year2={400} year3={600} colorStart='#48AEC5' colorEnd='#B8DDE6' />}
                                    />
                                    <CardResults
                                        variation={'---'}
                                        margin={'ms-0 ms-xl-4 mt-4'}
                                        number={<SubTitleComponent style={{userSelect: 'none'}} text={'---'} className='subTitleComponent roboto' />}
                                        titolo={<SubTitleComponent className={'robotoNormal'} text='PFN' style={{ color: '#808080', fontSize: '16px', marginTop: '-15px', userSelect: 'none' }} />}
                                        chart={<SteppedAreaChart anno1={1} anno2={2} anno3={3} year1={-500} year2={200} year3={-1000} colorStart='#53DCEF' colorEnd='#F884D0' />}
                                    />
                                </Row>
                                <div className="d-flex justify-content-center w-100 d-block d-xl-none">
                                    <ButtonComponent text={'Visualizza report e rating'} typeBootstrapButton='primary' handleClick={() => { }} marginBottom={'mt-4'} />
                                </div>
                            </Col>
                        </Row>
                        <TextButtonComponent align={'center'} text={'Vuoi avere accesso ai dati di bilancio <br/>completi di questa azienda?'} textButton='Registrati' typeBootstrapButton={'primary'} onClickButton={() => window.location.href = 'https://data.pmiagile.com/register/'} />
                    </BackgroundComponent>
                </div>
            </div>
        </>
    )
}