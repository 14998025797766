import React from "react";

function BoxPhoneAnalisi(props) {
    return(
        <>
        <div className="colorBackgroundBlu">
            <div className="sectionAB">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="d-block text-white">
                                <div>
                                    <h3><strong>{props.title1}</strong></h3>
                                    <h3><strong>{props.title2}</strong></h3>
                                </div>
                                <div className="mt-3">
                                    <p>{props.list1}</p>
                                    <p>{props.list2}</p>
                                    <p>{props.list3} <strong>{props.strongList}</strong></p>
                                    <p>{props.list4} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                            <img className="img-fluid" alt={props.alt} src={props.img} />   
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    );
} export default BoxPhoneAnalisi