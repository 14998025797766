import React from "react";
import { Col, Row } from "react-bootstrap";
import Card from "./Card";
import TitleComponent from "./TitleComponent";

export default function RivolgiamoCR({ textTitolo }) {
    // return (
    //     <>
    //         <div className="d-flex justify-content-center spacingRivolgiamo">
    //             <TitleComponent text={textTitolo} className='titleComponent text-center px-1 px-sm-0 mb-4' />
    //         </div>
    //         <div className="d-flex justify-content-center boxRivolgiamoContainer">
    //             <Row className="my-4 d-flex justify-content-center w-75">
    //                 <Col sm='12' xxl='4' className="me-xxl-4 me-0 mb-4 mb-xxl-0 d-flex justify-content-center align-items-center" style={{ border: '3px solid #2269F4' }}>
    //                     <div className="d-flex flex-xl-row justify-content-center">
    //                         <Card border="" style={{ border: '1px solid #B1B1B1', borderRadius: '20px' }} className='my-5 py-3 boxRivolgiamo bg-white d-flex align-items-center justify-content-center' titolo={'<strong>Aziende</strong>'} icona='buildings.png' />
    //                         <div className="d-flex justify-content-center justify-content-xxl-start align-items-center textRivolgiamo">
    //                             <TitleComponent className={'text-center text-xxl-start'} text={'Pensato per tutte le aziende che vogliono, in autonomia, tenere sotto controllo la propria posizione finanziaria'} />
    //                         </div>
    //                     </div>
    //                 </Col>
    //                 <Col sm='12' xxl='4' className="ms-xxl-4 ms-0 d-flex justify-content-center boxRivolgiamoReverseContainer" style={{ border: '3px solid #2269F4' }}>
    //                     <div style={{width: '100vw'}}>
    //                         <div className="d-flex flex-xl-row justify-content-center">
    //                             <div className="d-flex justify-content-end align-items-center textRivolgiamoReverse">
    //                                 <TitleComponent className={'text-center text-xxl-end'} text={'Pensato per tutti gli studi e consulenti che vogliono supportare la gestione dei propri clienti'} />
    //                             </div>
    //                             <Card border="" style={{ border: '1px solid #B1B1B1', borderRadius: '20px' }} className='my-5 py-3 boxRivolgiamoReverse bg-white d-flex justify-content-center align-items-center' titolo={'<strong>Consulenti</strong>'} icona='people.png' />
    //                         </div>
    //                     </div>
    //                 </Col>
    //             </Row>
    //         </div>
    //     </>
    // )

    return (
        <>
            <div className="d-flex justify-content-center spacingRivolgiamo">
                <TitleComponent text={textTitolo} className='titleComponent text-center px-1 px-sm-0 mb-4' />
            </div>
            <Row className="mt-5 d-none d-xxl-flex">
                <Col xl='6' className="d-flex justify-content-center flex-row">
                            <Card border="" style={{ border: '1px solid #B1B1B1', borderRadius: '20px' }} className='my-5 py-3 boxRivolgiamo bg-white d-flex align-items-center justify-content-center' titolo={'<strong>Aziende</strong>'} icona='buildings.png' />
                            <div className="d-flex justify-content-center justify-content-xxl-start align-items-center boxTextRivolgiamo" style={{ border: '3px solid #2269F4' }}>
                                <div className="ms-auto textRivolgiamo">
                                    <TitleComponent style={{ fontSize: '18px' }} className={'text-center text-xxl-start mx-4'} text={'Pensato per tutte le aziende che vogliono, in autonomia, tenere sotto controllo la propria posizione finanziaria'} />
                                </div>
                            </div>
                </Col>
                <Col xl='6' className="d-flex flex-row justify-content-center">
                            <div className="d-flex justify-content-center justify-content-xxl-start align-items-center boxTextRivolgiamoReverse" style={{ border: '3px solid #2269F4' }}>
                                <div className="textRivolgiamoReverse">
                                    <TitleComponent style={{ fontSize: '18px' }} className={'text-center text-xxl-end mx-4'} text={'Pensato per tutti gli studi e consulenti che vogliono supportare la gestione dei propri clienti'} />
                                </div>
                            </div>
                            <Card border="" style={{ border: '1px solid #B1B1B1', borderRadius: '20px' }} className='my-5 py-3 boxRivolgiamoReverse bg-white d-flex justify-content-center align-items-center' titolo={'<strong>Consulenti</strong>'} icona='people.png' />
                </Col>
            </Row>
            <div className="d-flex d-xxl-none justify-content-center">
                <div className="d-flex justify-content-center">
                    <div className="d-block">
                        <Card border="" style={{ border: '1px solid #B1B1B1', borderRadius: '20px' }} className='mb-5 mt-3 py-3 boxRivolgiamo bg-white d-flex align-items-center justify-content-center' titolo={'<strong>Aziende</strong>'} icona='buildings.png' />
                        <div className="d-flex justify-content-center">
                            <div className="d-flex justify-content-center justify-content-xxl-start align-items-center boxTextRivolgiamo" style={{ border: '3px solid #2269F4' }}>
                                <div className="textRivolgiamoReverse">
                                    <TitleComponent style={{ fontSize: '18px' }} className={'text-center text-xxl-end mx-4'} text={'Pensato per tutte le aziende che vogliono, in autonomia, tenere sotto controllo la propria posizione finanziaria'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex d-xxl-none justify-content-center">
                <div className="d-flex justify-content-center">
                    <div className="d-block">
                        <Card border="" style={{ border: '1px solid #B1B1B1', borderRadius: '20px' }} className='my-5 py-3 boxRivolgiamoReverse bg-white d-flex justify-content-center align-items-center' titolo={'<strong>Consulenti</strong>'} icona='people.png' />
                        <div className="d-flex justify-content-center">
                            <div className="d-flex justify-content-center justify-content-xxl-start align-items-center boxTextRivolgiamoReverse" style={{ border: '3px solid #2269F4' }}>
                                <div className="textRivolgiamoReverse">
                                    <TitleComponent style={{ fontSize: '18px' }} className={'text-center text-xxl-end mx-4'} text={'Pensato per tutti gli studi e consulenti che vogliono supportare la gestione dei propri clienti'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}