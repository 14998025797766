import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

export default function TeamComponent({ data }) {

    const col = data.map(p => {
        return (
            <>
                <Col className="d-flex justify-content-center" xl='3'>
                    <div className="d-block">
                        <div className="d-flex justify-content-center">
                            <Image className="mt-5" src={require('../assets/images/Team/' + p.imgSrc)} alt={p.nome} />
                        </div>
                        <p className="text-center mt-4" dangerouslySetInnerHTML={{ __html: p.nome }} />
                        <p className="text-center text-muted" style={{fontSize: '12px'}} dangerouslySetInnerHTML={{ __html: p.ruolo }} />
                    </div>
                </Col>
            </>
        )
    })

    return (
        <>
            <Container>
                <Row className="mb-1 px-5">
                    {col}
                </Row>
            </Container>
        </>
    )
}