import React from "react";
import { Helmet } from "react-helmet";
import BoxAcademy from "../components/BoxAcademy";
import Footer from "../components/Footer";
import HeaderComponent from "../components/HeaderComponent";
import NavbarComponent from "../components/NavbarComponent";
import backgroundHeader from './../assets/images/backgroundAcademy.png'

export default function Academy() {
    return (
        <>
            <Helmet>
                <title>Academy</title>
                <meta property="og:url" content="https://www.pmiagile.com/academy" />
                <meta
                    name="description"
                    content="Abbiamo creato un percorso di formazione con l’intento di educare tutte quelle aziende e persone curiose sulla cultura dei dati e l’Intelligenza artificiale."
                />
            </Helmet>
            <NavbarComponent />
            <HeaderComponent className={'justify-content-xl-start'} height="2" align="start" whiteLine={true} colorLine={'#06152c'} sizeLine={'287px'} backgroundImage={backgroundHeader} textSub={'Machine Learning, Data Science e Intelligenza Artificiale sono le parole del momento.'} textTitle={'<strong>Alla scoperta della Business Analytics e dell’Intelligenza Artificiale</strong>'} />
            <BoxAcademy />
            <Footer />
        </>
    )
}