import React from "react";
import BackgroundComponent from "./BackgroundComponent";
import backgroundImage from '../assets/images/backgroundBenchmark.png'
import BoxTitleSubtitle from './BoxTitleSubTitle'
import BoxAnalisi from "./BoxAnalisi";
import tickCircle from '../assets/images/Home/tickcircle.png'
import { Container } from "react-bootstrap";

export default function BoxConfrontaBenchmark() {
    return (
        <>
            <BackgroundComponent imageBackground={backgroundImage} className='py-0 py-xl-5 my-0 my-xl-5'>
                <Container className="py-5">
                    <BoxTitleSubtitle textTitle={'<strong>Confronta in pochi secondi i dati di bilancio di qualsiasi azienda</strong>'} textSubTitle='Un metodo immediato per condurre un’analisi approfondita dei dati economico-finanziari e patrimoniali dei tuoi concorrenti' />
                    <BoxAnalisi textSubTitle={''} backgroundColor="white" radius={'20px'} image1={tickCircle} image2={tickCircle} image3={tickCircle} image4={tickCircle} text1={'Aziende'} text3={'Fornitori'} text2='Clienti' text4='Concorrenti' className={'w-100 mt-5 py-5 boxAnalisi mx-5'} reverse={false} textTitle={'Chi puoi analizzare'} onClick={() => { window.location.href = 'https://data.pmiagile.com/' }} textButton='Attiva ora la prova gratuita' checkButton={true} />
                </Container>
            </BackgroundComponent>
        </>
    )
}