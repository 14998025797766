import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import imgSrc from '../assets/images/CR/boxAnalisiCR.png'
import SubTitleComponent from "./SubTitleComponent";
import TitleComponent from "./TitleComponent";

export default function BoxAnalisiCR() {
    return (
        <>
            <Container className="py-0 py-md-5 my-0 my-md-5">
                <Row className="d-flex justify-content-center my-3 py-3 align-items-center">
                    <Col xl='8'>
                        <p className="text-center text-xl-start" style={{ color: '#7021AE' }}>Perché?</p>
                        <div className="d-flex justify-content-center justify-content-xl-start">
                            <TitleComponent className={'titleComponent text-center text-xl-start'} text={'<strong>Analisi <br/>Centrale Rischi</strong>'} />
                        </div>
                        <SubTitleComponent className={'mt-3 text-center text-xl-start'} text={'Conoscere la propria struttura finanziaria è fondamentale per:'} />
                        <Row className="mt-5 mx-1 mx-xl-0">
                            <Col style={{ backgroundColor: '#EFF3FF', border: ' 1px solid #BEBEBE', borderRadius: '18px' }} xl='5' className="d-flex justify-content-center align-items-center py-4 mb-2 me-xl-2">
                                <label className="text-center">Evitare segnalazioni</label>
                            </Col>
                            <Col style={{ backgroundColor: '#EFF3FF', border: ' 1px solid #BEBEBE', borderRadius: '18px' }} xl='5' className="d-flex justify-content-center align-items-center py-4 mb-2 ms-xl-2">
                                <label className="text-center">Accedere al credito</label>
                            </Col>
                            <Col style={{ backgroundColor: '#EFF3FF', border: ' 1px solid #BEBEBE', borderRadius: '18px' }} xl='5' className="d-flex justify-content-center align-items-center py-4 mb-2 mt-xl-2 mb-xl-0 me-xl-2">
                                <label className="text-center">Gestire le risorse</label>
                            </Col>
                            <Col style={{ backgroundColor: '#EFF3FF', border: ' 1px solid #BEBEBE', borderRadius: '18px' }} xl='5' className="d-flex justify-content-center align-items-center py-4 mb-5 ms-xl-2 mb-xl-0 mt-xl-2">
                                <label className="text-center">Migliorare il proprio rating</label>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl='4' className="d-flex justify-content-center mb-5 mb-xl-0">
                        <Image src={imgSrc} className='img-fluid' alt="Analisi CR" />
                    </Col>
                </Row>
            </Container>
        </>
    )
}