import React from "react";
import BackgroundComponent from "./BackgroundComponent";
import TitleComponent from "./TitleComponent";
import { Row, Container, Col, Image } from 'react-bootstrap'
import FaqComponent from "./FaqComponent";

export default function BoxFaqImage({ data, imgSrc, imageBackground, imageBackgroundMobile }) {
    return (
        <>
            <BackgroundComponent imageBackground={imageBackground} backgroundSize='contain' className={'py-0 my-0 py-xxl-5 my-xxl-5 d-none d-xl-block'}>
                <Container className="py-5 my-5">
                    <div className="d-flex justify-content-center mb-4">
                        <TitleComponent className={'titleComponent'} text={'Cosa puoi analizzare con il nostro tool'} />
                    </div>
                    <Row className='d-flex justify-content-center align-items-center'>
                        <Col xl='6'>
                            <FaqComponent footer={false} borderBody="borderAnalisiFaqBody" imageBackground="" data={data} checkTitle={false} border='borderAnalisiFaq' radius="18px" />
                        </Col>
                        <Col xl='6' className="d-flex align-items-center">
                            <Image className="img-fluid mb-3" src={imgSrc} />
                        </Col>
                    </Row>
                </Container>
            </BackgroundComponent>
            <BackgroundComponent imageBackground={imageBackgroundMobile} backgroundSize='contain' className={'py-0 my-0 py-lg-5 my-lg-5 pb-5 mb-5 pb-lg-0 mb-lg-0 d-block d-xl-none'}>
                <Container className="py-5 my-5">
                    <div className="d-flex justify-content-center mt-5">
                        <TitleComponent className={'titleComponent text-center'} text={'Cosa puoi analizzare con il nostro tool'} />
                    </div>
                    <Row className='d-flex justify-content-center'>
                        <Col xl='6'>
                            <FaqComponent footer={false} borderBody="borderAnalisiFaqBody" imageBackground="" data={data} checkTitle={false} border='borderAnalisiFaq' radius="18px" />
                        </Col>
                        <Col xl='6'>
                            <Image className="mt-3 img-fluid" src={imgSrc} />
                        </Col>
                    </Row>
                </Container>
            </BackgroundComponent>
        </>
    )
}