import React from "react";
import HubspotForm from './../HubspotForm';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TitleComponent from "../TitleComponent";

function Newsletter(){
    return(
        <>
            <Container className="pb-5">
                <Row style={{background: '#EFF3FF', border: '1px solid #B1B1B1', borderRadius: '20px'}} className="d-flex align-items-center py-5">
                    <Col xl='4' className="d-flex justify-content-center mb-3 mb-xl-0">
                        <TitleComponent className={'titleComponent text-center text-xl-start'} text={'Resta <br/><strong>aggiornato</strong>'} />
                    </Col>
                    <Col xl='7'>
                        <p className="text-center text-xl-start" style={{fontSize: '14px', lineHeight: '21.97px'}}>Iscriviti alla nostra newsletter per ricevere gli articoli del nostro blog e rimanere aggiornato su temi come: <strong>intelligenza artificiale, analisi dati, finanza</strong> e tanto altro...</p>
                        <HubspotForm formId={'dedfe905-7422-42ed-9b61-33b62ccd7bbc'} isModal={false} />
                    </Col>
                </Row>
            </Container>
        </>
    )
} export default Newsletter