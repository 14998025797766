import React from "react";
import Footer from "../components/Footer";
import Header from "../components/NavbarComponent";
import { useEffect } from "react";
import { Helmet } from 'react-helmet'
import HeaderComponentImage from '../components/HeaderComponentImage'
import backgroundHeader from '../assets/images/backgroundHeaderBilancio.png'
import mockupHeader from '../assets/images/Bilancio/mockupHeader.png'
import AnimatedNumberComponent from "../components/AnimatedNumberComponent";
import dataNumber from '../assets/data/AnimatedNumber/numberBilancio.json'
import BoxComeFunzionaEnterprise from "../components/BoxComeFunzionaEnterprise";
import dataBilancio from '../assets/data/Bilancio/dataBilancio.json'
import TextVideoComponent from "../components/TextVideoComponent";
import BoxFaqImage from "../components/BoxFaqImage";
import dataFaqSmall from '../assets/data/Faq/faqBilancioSmall.json'
import imgSrc from '../assets/images/Bilancio/analisiEconomica.png'
import backgroundBolleFaqSmall from '../assets/images/Bilancio/backgroundFaq.png'
import backgroundBolleFaqSmallMobile from '../assets/images/Bilancio/backgroundFaqMobile.png'
import TextImageComponentSmall from "../components/TextImageComponentSmall";
import backgroundImageTextImageSmall from '../assets/images/Bilancio/backgroundSmallComponent.png'
import dataSmallComponent from '../assets/data/TextImage/textImageBilancioSmall.json'
import FaqComponent from "../components/FaqComponent";
import dataFaq from '../assets/data/Faq/faqHome.json'


function AnalisiBilancio(props) {
    useEffect(() => {
        if (localStorage.getItem('partitaIVA') !== null) {
            localStorage.removeItem('partitaIVA')
        }
    })
    return (
        <>
            <Helmet>
                <title>Analisi Azienda</title>
                <meta property="og:url" content="https://www.pmiagile.com/analisi-bilancio" />
                <meta
                    name="description"
                    content="Analisi Azienda ti permette di capire la situazione economica, finanziaria e patrimoniale di una società di capitali, partendo dal suo bilancio di esercizio."
                />
            </Helmet>
            <Header />
            <HeaderComponentImage imgSrc={mockupHeader} imgSrcMobile={mockupHeader} backgroundImage={backgroundHeader} onClick={() => window.location.href = '/'} textTitolo={'<strong>Analisi Azienda</strong>'} textSubTitolo='Con il tool di Analisi Azienda puoi capire esattamente la situazione economica, finanziaria e patrimoniale di una società di capitali, partendo dal suo bilancio di esercizio.' textButton={'Inserisci la Partita IVA'} />
            <AnimatedNumberComponent container={false} data={dataNumber} fontSizeTitolo='generalTitle' backgroundColor={'#F1F1F1'} />
            <BoxComeFunzionaEnterprise marginBottom="marginBottomFaqSmallBilancio" text={'<strong class="text-dark-blue">Perché scegliere<br/>l’Analisi di Bilancio di PMI Agile?</strong>'} data={dataBilancio} />
            <TextVideoComponent body={''} labelTutorial2={<label className="pt-3" style={{color: '#5278FC'}}>Guarda il tutorial</label>} path={'https://pmiagile-sito-assets.s3.eu-south-1.amazonaws.com/video_2.mp4'} align={'start'} container={true} reverse={true} labelTutorial={<label class="text-blue pt-3">Analizza qualsiasi azienda in modo</label>} titolo={'<strong>Semplice</strong><br/><strong>Chiaro</strong><br/><strong>Immediato</strong>'} textButton='Guarda il tutorial' checkButton={false}  />
            <BoxFaqImage imageBackgroundMobile={backgroundBolleFaqSmallMobile} imageBackground={backgroundBolleFaqSmall} data={dataFaqSmall} imgSrc={imgSrc} />
            <TextImageComponentSmall clickButton={() => { window.location.href = 'https://data.pmiagile.com/register' }} backgroundImage={backgroundImageTextImageSmall} radius='70px' data={dataSmallComponent} reverse={true} />
            <FaqComponent data={dataFaq} />
            <Footer />
        </>
    );
}
export default AnalisiBilancio