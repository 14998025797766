import { Component } from "react";
import config from './../.config.json'
import BackgroundComponent from "./BackgroundComponent";
import { LoadingResults } from "./general/results";
import TitleComponent from "./TitleComponent";

class BlogBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            item: [],
            backgroundBlog: props.backgroundBlog
        }
    }

    componentDidMount() {
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': config.RESULTS
            }
        }
        fetch("https://6kcj3trvik.execute-api.eu-south-1.amazonaws.com/v1/get_blog_posts", options)
            .then(res => res.json())
            .then((result) => {
                this.setState({
                    isLoaded: true,
                    item: result
                });
            },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    })
                })
    }



    render() {
        const items = this.state.item
        const error = this.state.error
        const isLoaded = this.state.isLoaded

        if (error) {
            return <div>Error: {error.message}</div>
        } else if (!isLoaded) {
            return <div className="colorBackground"><div className="d-flex justify-content-center align-items-center spinner"><LoadingResults /></div></div>
        } else {
            return (

                <>
                    <BackgroundComponent backgroundColor={`linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), url(${this.state.backgroundBlog})`} className={window.location.pathname !== '/contenuti' ? 'py-4 py-xl-5' : 'pb-4 pb-xl-5'} imageBackground={this.state.backgroundBlog}>
                        <div className="container">
                            <div className="d-flex justify-content-center">
                                <div className={`bg-white ${window.location.pathname !== '/contenuti' ? 'titleBlogBanner' : 'mb-4 rounded px-2'}`}>
                                    <div className="d-flex justify-content-center">
                                        <TitleComponent className={`text-center pt-3 px-0 px-xl-0 titleComponent`} text={'I nostri <strong>articoli</strong>'} />
                                    </div>
                                    <p className="text-center pb-2" style={{ marginTop: '8px', fontSize: '20px' }}><a style={{ fontSize: '18px', textDecoration: 'none' }} href="https://blog.pmiagile.com/">LEGGI TUTTI GLI ARTICOLI<i class="bi bi-arrow-right-short"></i></a></p>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center">
                                {stampa(items)}
                            </div>
                        </div>
                    </BackgroundComponent>
                </>
            )
        }
    }
} export default BlogBanner


function removeTagHtml(data) {
    const regex = /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g;
    const result = data.replace(regex, '');
    if (result.length === 1) {
        return result
    } else {
        return result.substring('', 100) + '...'
    }
}

function stampa(items) {
    items = items.result.objects.filter(p => p['published_url'] !== '')
    if (items.length === 0) {
       return (
        <>
        <div className="col">
            Nessun articolo
        </div>
        </>
       )

    } else if (items.length === 1) {
        return (
            <div className="col">
                <div style={{ border: 'none' }} className="card h-100 cardAnimation">
                    <img style={{ borderRadius: '10px' }} src={items[0].featured_image} className='img-fluid' alt="" />
                    <div className="card-body">
                        <h3 className="text-dark" style={{ fontWeight: 'bold' }}>{items[0].page_title}</h3>
                        <p className="text-muted">{removeTagHtml(items[0].meta.post_summary)}</p>
                    </div>
                    <div className="card-footer removeBackground">
                        <div className="animationA"><p className="generalLinkBlu"><a href={items[0].absolute_url}><span>Leggi di più</span></a></p></div>
                    </div>
                </div>
            </div>
        )
    } else if (items.length === 2) {
        return (
            <>
                <div className="col-xl-6">
                    <div style={{ border: 'none' }} className="card h-100 cardAnimation">
                        <img style={{ borderRadius: '10px' }} src={items[0].featured_image} className='img-fluid' alt="" />
                        <div className="card-body">
                            <h3 className="text-dark" style={{ fontWeight: 'bold' }}>{items[0].page_title}</h3>
                            <p className="text-muted">{removeTagHtml(items[0].meta.post_summary)}</p>
                        </div>
                        <div className="card-footer removeBackground">
                            <div className="animationA"><p className="generalLinkBlu"><a href={items[0].absolute_url}><span>Leggi di più</span></a></p></div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6">
                    <div style={{ border: 'none' }} className="card h-100 cardAnimation">
                        <img style={{ borderRadius: '10px' }} src={items[1].featured_image} className='img-fluid' alt="" />
                        <div className="card-body">
                            <h3 className="text-dark" style={{ fontWeight: 'bold' }}>{items[1].page_title}</h3>
                            <p className="text-muted">{removeTagHtml(items[1].meta.post_summary)}</p>
                        </div>
                        <div className="card-footer removeBackground">
                            <div className="animationA"><p className="generalLinkBlu"><a href={items[1].absolute_url}><span>Leggi di più</span></a></p></div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="col-xl-4">
                    <div style={{ border: 'none', borderRadius: '20px', boxShadow: '0px 4px 33px rgba(0, 0, 0, 0.15)' }} className="card h-100 cardAnimation px-3 py-3">
                        <div style={{ border: '1px solid #ededed', borderRadius: '20px' }}>
                            <img style={{ borderRadius: '20px' }} src={items[0].featured_image} className='img-fluid' alt="" />
                        </div>
                        <div className="card-body">
                            <p className="text-dark text-center" style={{ fontWeight: 'bold', fontSize: '16px' }}>{items[0].page_title}</p>
                            <div className="d-flex justify-content-center"><div className="w-50 my-3" style={{ border: '2px solid #2269F4', borderRadius: '20px' }}></div></div>
                        </div>
                        <div className="card-footer removeBackground">
                            <div className="animationA"><p className="generalLinkBlu" style={{ marginBottom: '-5px' }}><a href={items[0].absolute_url}><span>Leggi di più</span></a></p></div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 mt-3 mt-xl-0">
                    <div style={{ border: 'none', borderRadius: '20px', boxShadow: '0px 4px 33px rgba(0, 0, 0, 0.15)' }} className="card h-100 cardAnimation px-3 py-3">
                        <div style={{ border: '1px solid #ededed', borderRadius: '20px' }}>
                            <img style={{ borderRadius: '20px' }} src={items[1].featured_image} className='img-fluid' alt="" />
                        </div>
                        <div className="card-body">
                            <p className="text-dark text-center" style={{ fontWeight: 'bold', fontSize: '16px' }}>{items[1].page_title}</p>
                            <div className="d-flex justify-content-center"><div className="w-50 my-3" style={{ border: '2px solid #2269F4', borderRadius: '20px' }}></div></div>
                        </div>
                        <div className="card-footer removeBackground">
                            <div className="animationA"><p className="generalLinkBlu" style={{ marginBottom: '-5px' }}><a href={items[1].absolute_url}><span>Leggi di più</span></a></p></div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 mt-3 mt-xl-0">
                    <div style={{ border: 'none', borderRadius: '20px', boxShadow: '0px 4px 33px rgba(0, 0, 0, 0.15)' }} className="card h-100 cardAnimation px-3 py-3">
                        <div style={{ border: '1px solid #ededed', borderRadius: '20px' }}>
                            <img style={{ borderRadius: '20px' }} src={items[2].featured_image} className='img-fluid' alt="" />
                        </div>
                        <div className="card-body">
                            <p className="text-dark text-center" style={{ fontWeight: 'bold', fontSize: '16px' }}>{items[2].page_title}</p>
                            <div className="d-flex justify-content-center"><div className="w-50 my-3" style={{ border: '2px solid #2269F4', borderRadius: '20px' }}></div></div>
                        </div>
                        <div className="card-footer removeBackground">
                            <div className="animationA"><p className="generalLinkBlu" style={{ marginBottom: '-5px' }}><a href={items[2].absolute_url}><span>Leggi di più</span></a></p></div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

