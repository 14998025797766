import React from "react";
import NavbarComponent from '../components/NavbarComponent'
import Footer from '../components/Footer'
import BlogBanner from "../components/BlogBanner";
import backgroundBlog from '../assets/images/backgroundBlog.png'
import HeaderComponent from '../components/HeaderComponent'
import { Helmet } from "react-helmet";

export default function ContenutiPage() {
    return (
        <>
            <Helmet>
                <title>Contenuti</title>
                <meta property="og:url" content="https://www.pmiagile.com/contenuti" />
                <meta
                    name="description"
                    content="Parliamo di come aiutare le aziende a far crescere il proprio business attraverso i dati e l’intelligenza artificiale."
                />
            </Helmet>
            <NavbarComponent />
            <HeaderComponent backgroundImage={backgroundBlog} textTitle={'Parliamo di <strong>come aiutare le aziende a far crescere il proprio business attraverso i dati</strong>'} />
            <BlogBanner />
            <Footer />
        </>
    )
}