import React from "react";
import ResultsComponent from "../../ResultsComponent";
import FailureResults from "./CaseResults/FailureResults";
import LoadingResults from "./LoadingResults";
import TextButtonComponent from "../../TextButtonComponent";

function ResultsSearch(props) {
    if (props.error) {
        return <div>Error: {props.error.message}</div>
    } else if (!props.isLoaded) {
        return <div className="colorBackground" id="loading_screen"><div className="d-flex justify-content-center align-items-center spinner"><LoadingResults /></div></div>
    } else {
        return stampe(props.items)
    }
} export default ResultsSearch

function anniAttivita(anno) {
    let year = anno.split('/');
    let currentYear = new Date().getFullYear()
    return (currentYear - year[0]).toString()
}

function checkDot(fatt) {
    return new Intl.NumberFormat().format(fatt)
}

function getVariationPNF(year1, year2) {
    let cambioSegno = -1
    year1 = parseFloat(year1);
    year2 = parseFloat(year2);
    if (year2 !== 0) {
        if (year2 < 0) {
            let variation = parseFloat(((year1 - year2) / year2) * 100).toFixed(2)
            variation = variation.toString().replace('.', ',')
            return variation
        } else {
            let variation = parseFloat(((year1 - year2) / year2) * 100).toFixed(2)
            variation = variation * cambioSegno
            variation = variation.toString().replace('.', ',')
            return variation
        }
    } else {
        return 0
    }
}

function isExist(data) {
    if (data.year1 === undefined) {
        return '---'
    } else {
        return data
    }
}

function getVariation(year1, year2) {
    year1 = parseFloat(year1);
    year2 = parseFloat(year2);
    let cambioSegno = -1
    if (year2 !== 0) {
        if (year2 < 0) {
            let variation = parseFloat(((year1 - year2) / year2) * 100).toFixed(2)
            variation = variation * cambioSegno
            variation = variation.toString().replace('.', ',')
            return variation
        } else {
            let variation = parseFloat(((year1 - year2) / year2) * 100).toFixed(2)
            variation = variation.replace('.', ',')
            return variation
        }
    } else {
        return 0
    }
}

function formatterDate(data) {
    let year = data.split('/');
    return year[2] + '/' + year[1] + '/' + year[0]
}

function stampe(items) {
    if (items.message !== 'There is no data for this Vat_Number' && items.message !== 'Internal server error' && items.message !== 'Endpoint request timed out') {
        return (
            <>
                <ResultsComponent
                    nameCompany={items.result.ragione_sociale}
                    partitaIVA={items.result.PK}
                    anni={items.result.anni_attivita !== undefined ? items.result.anni_attivita : ''}
                    dipendenti={items.result.bilancio !== undefined ? items.result.bilancio.dipendenti : ''}
                    codiceAteco={items.result.codice_ateco}
                    costituzione={items.result.data_cost !== undefined ? formatterDate(items.result?.data_cost) : ''}
                    // anniBilancio={[isExist(items.results).year1.anno || '', isExist(items.results).year2.anno || '', isExist(items.results).year3.anno || '']}
                    // fatturato={[checkDot(isExist(items.results).year1.Ricavi_vendite_e_prestazioni) || '', isExist(items.results).year1.Ricavi_vendite_e_prestazioni || '', isExist(items.results).year2.Ricavi_vendite_e_prestazioni || '', isExist(items.results).year3.Ricavi_vendite_e_prestazioni || '']}
                    // patrimonioNetto={[checkDot(isExist(items.results).year1.TOTALE_PATRIMONIO_NETTO) || '', isExist(items.results).year1.TOTALE_PATRIMONIO_NETTO || '', isExist(items.results).year2.TOTALE_PATRIMONIO_NETTO || '', isExist(items.results).year3.TOTALE_PATRIMONIO_NETTO || '']}
                    // ebitda={[checkDot(isExist(items.results).year1.EBITDA) || '', isExist(items.results).year1.EBITDA || '', isExist(items.results).year2.EBITDA || '', isExist(items.results).year3.EBITDA || '']}
                    // pfn={[checkDot(isExist(items.results).year1['Posizione finanziaria netta EUR']) || '', isExist(items.results).year1['Posizione finanziaria netta EUR'] || '', isExist(items.results).year2['Posizione finanziaria netta EUR'] || '', isExist(items.results).year3['Posizione finanziaria netta EUR'] || '']}
                    // variation={[getVariation(isExist(items.results).year1.Ricavi_vendite_e_prestazioni, isExist(items.results).year2.Ricavi_vendite_e_prestazioni) || '', getVariation(isExist(items.results).year1.TOTALE_PATRIMONIO_NETTO, isExist(items.results).year2.TOTALE_PATRIMONIO_NETTO) || '', getVariation(isExist(items.results).year1.EBITDA, isExist(items.results).year2.EBITDA) || '', getVariationPNF(isExist(items.results).year1['Posizione finanziaria netta EUR'], isExist(items.results).year2['Posizione finanziaria netta EUR']) || '']}
                />
                
            </>
        )
    } else {
        return <FailureResults />
    }
}