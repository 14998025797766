import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import TextButtonComponent from "./TextButtonComponent";
import TitleComponent from "./TitleComponent";
function ProvaFreeBanner() {

    // const [checkScrollable, setCheckScrollable] = useState(true)

    // const onEnter = () => {
    //     window.addEventListener('scroll', function (scroll) {
    //         if (heightScroll < 150) {
    //             setHeightScroll(heightScroll + 10)
    //         }
    //     })
    // }

    return (
        <>
            {/* <ScrollTrigger onEnter={onEnter} onProgress={onEnter}> */}
            <Container fluid>
                <Row>
                    <Col>

                    </Col>
                    <Col id="lineBlue">
                        <div style={{ borderLeft: '5px solid #2269F4', height: `${150}px` }}></div></Col>
                </Row>
            </Container>
            <Container className="pb-0 mb-0">
                <div className="d-flex justify-content-center pb-3">
                    <TitleComponent className={`text-center pt-3 px-0 px-xl-0 titleComponent`} text={'Prova <strong>gratuitamente</strong> PMI Agile'} />
                </div>
                <TextButtonComponent onClickButton={() => {window.location.href = 'https://data.pmiagile.com/' }} align={'center'} fontSize='22px' text={'La nostra piattaforma è semplice e intuitiva, non richiede <br class="d-none d-xl-block"/>particolari competenze specifiche ed è utilizzabile anche <br class="d-none d-xl-block"/>da utenti non esperti in queste tipologie di analisi'} typeBootstrapButton={'primary'} className='mb-5' handleClick={() => window.location.href = 'https://data.pmiagile.com/'} textButton='Attiva ora il piano gratuito' />
            </Container>
            {/* </ScrollTrigger> */}
        </>
    );
}
export default ProvaFreeBanner