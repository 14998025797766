import React from "react";

function StampaMercato(props) {
    return (
        <>
            <div className="container mb-5">
                <div className="row">
                    <div className="col-md-6 mt-5 col-lg-3">
                        <div class="card h-100 " style={{  border: "none" }}>
                            <div class="card-body border-mercato">
                                <h2 class={ ` card-subtitle mb-2 underline ` }>{props.totale}</h2>
                                <h5 class="card-title text-center text-muted">Totale Immobilizzazioni</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-5 col-lg-3">
                        <div class="card h-100" style={{  border: "none" }}>
                            <div class="card-body border-mercato">
                                <h2 class={ ` card-subtitle mb-2 underline ` }>{props.tot_val_produzione}</h2>
                                <h5 class="card-title text-center text-muted">Totale Valore Produzione</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-5 col-lg-3">
                        <div class="card h-100" style={{  border: "none" }}>
                            <div class="card-body border-mercato">
                                <h2 class={ ` card-subtitle mb-2 underline ` }>{props.totale_debiti}</h2>
                                <h5 class="card-title text-center text-muted">Totale Debiti</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-5 col-lg-3">
                        <div class="card h-100" style={{  border: "none" }}>
                            <div class="card-body border-mercato">
                                <h2 class={ ` card-subtitle mb-2 underline ` }>{props.pfn_ebitda}</h2>
                                <h5 class="card-title text-center text-muted">PFN Ebitda</h5>
                            </div>
                        </div>
                    </div>
                </div>
               
                <div className="row">
                    <div className="col-md-6 mt-5 col-lg-3">
                        <div class="card h-100" style={{  border: "none" }}>
                            <div class="card-body border-mercato">
                                <h2 class={ ` card-subtitle mb-2 underline ` }>{props.totale_fondi_rischi}</h2>
                                <h5 class="card-title text-center text-muted">Totale Fondi Rischi</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-5 col-lg-3">
                        <div class="card h-100" style={{  border: "none" }}>
                            <div class="card-body border-mercato">
                                <h2 class={ ` card-subtitle mb-2 underline` }>{props.attivo_circolante}</h2>
                                <h5 class="card-title text-center text-muted">Attivo Circolante</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-5 col-lg-3">
                        <div class="card h-100" style={{  border: "none" }}>
                            <div class="card-body border-mercato">
                                <h2 class={ ` card-subtitle mb-2 underline ` }>{props.ratei_risconti}</h2>
                                <h5 class="card-title text-center text-muted">Ratei Riscontri</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-5 col-lg-3">
                        <div class="card h-100" style={{  border: "none" }}>
                            <div class="card-body border-mercato">
                                <h2 class={ ` card-subtitle mb-2 underline` }>{props.ebitda}</h2>
                                <h5 class="card-title text-center text-muted">Ebitda</h5>
                            </div>
                        </div>
                    </div>
                </div>
               
                <div className="row">
                    <div className="col-md-6 mt-5 col-lg-3">
                        <div class="card h-100" style={{  border: "none" }}>
                            <div class="card-body border-mercato">
                                <h2 class={ ` card-subtitle mb-2 underline ` }>{props.pfn}</h2>
                                <h5 class="card-title text-center text-muted">PFN</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-5 col-lg-3">
                        <div class="card h-100" style={{  border: "none" }}>
                            <div class="card-body border-mercato">
                                <h2 class={ ` card-subtitle mb-2 underline` }>{props.utile}</h2>
                                <h5 class="card-title text-center text-muted">Utile</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-5 col-lg-3">
                        <div class="card h-100" style={{  border: "none" }}>
                            <div class="card-body border-mercato">
                                <h2 class={ ` card-subtitle mb-2 underline ` }>{props.debiti_breve}</h2>
                                <h5 class="card-title text-center text-muted">Debiti Breve</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-5 col-lg-3">
                        <div class="card h-100" style={{  border: "none" }}>
                            <div class="card-body border-mercato">
                                <h2 class={ ` card-subtitle mb-2 underline ` }>{props.debiti_lungo}</h2>
                                <h5 class="card-title text-center text-muted">Debiti Lungo</h5>
                            </div>
                        </div>
                    </div>
                </div>
               
                <div className="row">
                    <div className="col-md-6 mt-5 col-lg-3">
                        <div class="card h-100" style={{  border: "none" }}>
                            <div class="card-body border-mercato">
                                <h2 class={ ` card-subtitle mb-2 underline ` }>{props.dipendenti}</h2>
                                <h5 class="card-title text-center text-muted">Dipendenti</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-5 col-lg-3">
                        <div class="card h-100" style={{  border: "none" }}>
                            <div class="card-body border-mercato">
                                <h2 class={ ` card-subtitle mb-2 underline ` }>{props.ev}</h2>
                                <h5 class="card-title text-center text-muted">Ebitda/Vendite</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-5 col-lg-3">
                        <div class="card h-100" style={{  border: "none" }}>
                            <div class="card-body border-mercato">
                                <h2 class={ ` card-subtitle mb-2 underline ` }>{props.ebitda_fatturato}</h2>
                                <h5 class="card-title text-center text-muted">Ebitda Fatturato</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-5 col-lg-3">
                        <div class="card h-100" style={{  border: "none" }}>
                            <div class="card-body border-mercato">
                                <h2 class={ ` card-subtitle mb-2 underline ` }>{props.fatturato}</h2>
                                <h5 class="card-title text-center text-muted">Fatturato</h5>
                            </div>
                        </div>
                    </div>
                </div>
               
                <div className="row">
                    <div className="col-md-6 mt-5 pb-5 col-lg-3">
                        <div class="card h-100" style={{  border: "none" }}>
                            <div class="card-body border-mercato">
                                <h2 class={ ` card-subtitle mb-2 underline ` }>{props.oneri_fin_ebitda}</h2>
                                <h5 class="card-title text-center text-muted">Oneri Finanziari Ebitda</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-5 pb-5 col-lg-3">
                        <div class="card h-100" style={{  border: "none" }}>
                            <div class="card-body border-mercato">
                                <h2 class={ ` card-subtitle mb-2 underline ` }>{props.pn}</h2>
                                <h5 class="card-title text-center text-muted">PN</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-5 pb-5 col-lg-3">
                        <div class="card h-100" style={{  border: "none" }}>
                            <div class="card-body border-mercato">
                                <h2 class={ ` card-subtitle mb-2 underline ` }>{props.pn_tot_passivo}</h2>
                                <h5 class="card-title text-center text-muted">Totale PN Passivo</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-5 pb-5 col-lg-3">
                        <div class="card h-100" style={{  border: "none" }}>
                            <div class="card-body border-mercato">
                                <h2 class={ ` card-subtitle mb-2 underline ` }>{props.tfr}</h2>
                                <h5 class="card-title text-center text-muted">TFR</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}
export default StampaMercato