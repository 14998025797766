import React from "react";
import { Image } from "react-bootstrap";
import BackgroundComponent from "./BackgroundComponent";
import SubTitleComponent from "./SubTitleComponent";
import VideoComponent from "./VideoComponent";
import background from '../assets/images/backgroundHeaderEnterprise.png'
import decisioni from '../assets/images/benchmark/decisioni.svg'
import decisioniMobile from '../assets/images/benchmark/decisioniMobile.png'
import BoxTitleSubTitle from "./BoxTitleSubTitle";

export default function BoxVideoBenchmark() {
    return (
        <>
            <BackgroundComponent className={'py-5 mx-0 mx-xl-5 spacing'} radius='38px' backgroundColor={`linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 101.35%), url(${background})`}>
                <div className="d-flex justify-content-center boxVideoBenchmark">
                    <div className="d-block">
                        <SubTitleComponent className={'text-center px-3 subTitleComponent'} text={'<strong>Scopri come ottenere informazioni sui tuoi concorrenti</strong>'} />
                        <p className="text-center" style={{ color: '#2056C0' }}>Guarda questo tutorial</p>
                        <div className="d-flex justify-content-center">
                            <div style={{maxWidth: '700px'}}>
                                <VideoComponent path={'https://pmiagile-sito-assets.s3.eu-south-1.amazonaws.com/video_3.mp4'} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <div className=" d-none d-xl-block me-5 boxImageBenchmark" style={{ width: '1300px', height: '840px', backgroundImage: `url(${decisioni})`, backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }}>
                        <div className="d-flex justify-content-center align-items-center">
                            <BoxTitleSubTitle className={'boxTextDecisioni w-50'} classNameContainerTitle="justify-content-center justify-content-xl-end" classNameTitle={'text-xl-end'} classNameSubTitle='text-xl-end' textTitle={'<strong>Migliora le tue <br/>decisioni</strong>'} textSubTitle={'Paragonando i tuoi dati con i concorrenti potrai comprendere come posizionarti meglio nel mercato di riferimento. <br/>Informazioni utili per determinare i punti di forza e di debolezza, importanti per decidere le strategie da attuare.'} />
                        </div>
                    </div>
                </div>
                <div className="d-block d-xl-none boxContainerMobile">
                    <BoxTitleSubTitle classNameContainerTitle="justify-content-center justify-content-xl-end" className={'boxTextDecisioni w-100'} classNameTitle={'text-xl-end'} classNameSubTitle='text-xl-end mx-0 mx-sm-5' textTitle={'<strong>Migliora le tue <br/>decisioni</strong>'} textSubTitle={'Paragonando i tuoi dati con i concorrenti potrai comprendere come posizionarti meglio nel mercato di riferimento. <br/>Informazioni utili per determinare i punti di forza e di debolezza, importanti per decidere le strategie da attuare.'} />
                    <div className="d-flex justify-content-center">
                        <Image src={decisioniMobile} alt={decisioniMobile} className='img-fluid boxImageDecisioni' />
                    </div>
                </div>
            </BackgroundComponent>
        </>
    )
}