import React from "react";
import { Accordion, Container } from "react-bootstrap";
import BackgroundComponent from "./BackgroundComponent";
import faqBackground from '../assets/images/backgroundFaq.png'
import TitleComponent from "./TitleComponent";
import ButtonComponent from "./ButtonComponent";

export default function FaqComponent({ data, imageBackground = faqBackground, checkTitle = true, radius = '34.5px', border = '', footer = true, borderHeader = '' }) {

    const stampaFaq = data.map((p) => {
        return (
            <>
                <Accordion.Item eventKey={p.id} className={`w-100 ${window.location.pathname !== '/analisi-bilancio' ? 'my-2' : 'my-3'} ${border}`} style={{ borderRadius: radius, borderColor: 'transparent' }}>
                    <Accordion.Header className={`w-100 ${borderHeader}`}><label className="w-75" dangerouslySetInnerHTML={{ __html: p.titolo }} /></Accordion.Header>
                    <Accordion.Body dangerouslySetInnerHTML={{ __html: p.body }} />
                </Accordion.Item>
            </>
        )
    })

    return (
        <>
            {
                window.location.pathname === '/faq' && footer  ?
                    <>
                        <div className="heightFaqComplete">
                            <BackgroundComponent className={'py-3 h-100 d-flex align-items-center'} imageBackground={imageBackground}>
                                <Container className="pb-5">
                                    {
                                        checkTitle ?
                                            <>
                                                <div className="d-flex justify-content-center">
                                                    <TitleComponent className={`text-center pt-xxl-3 pt-5 mt-5 mt-xxl-0 px-0 px-xl-0 titleComponent mb-4`} text={'<strong>FAQ</strong>'} />
                                                </div>
                                            </> : null
                                    }
                                    <Accordion>
                                        {stampaFaq}
                                    </Accordion>
                                </Container>
                            </BackgroundComponent>
                        </div>
                    </> :
                    <>
                        <BackgroundComponent className={'py-3 h-100 d-flex align-items-center'} imageBackground={imageBackground}>
                            {
                                window.location.pathname === '/piani' ?
                                <>
                                    <div className="pb-3 w-100">
                                        {
                                            checkTitle ?
                                                <>
                                                    <div className="d-flex justify-content-center">
                                                        <TitleComponent className={`text-center pt-3 px-0 px-xl-0 titleComponent`} text={'<strong>FAQ</strong>'} />
                                                    </div>
                                                </> : null
                                        }
                                        <Accordion>
                                            {stampaFaq}
                                        </Accordion>
                                        {
                                            footer ? <div className="d-flex justify-content-center mt-3">
                                            <ButtonComponent typeBootstrapButton="primary" text={'Consulta tutte le FAQ'} handleClick={() => { window.location.href = '/faq' }}  />
                                        </div> : null
                                        }
                                    </div>
                                </> :
                                <>
                                    <Container className="pb-3">
                                        {
                                            checkTitle ?
                                                <>
                                                    <div className="d-flex justify-content-center">
                                                        <TitleComponent className={`text-center pt-3 px-0 px-xl-0 titleComponent`} text={'<strong>FAQ</strong>'} />
                                                    </div>
                                                </> : null
                                        }
                                        <Accordion>
                                            {stampaFaq}
                                        </Accordion>
                                        {
                                            footer ? <div className="d-flex justify-content-center mt-3">
                                            <ButtonComponent typeBootstrapButton="primary" text={'Consulta tutte le FAQ'} handleClick={() => { window.location.href = '/faq' }}  />
                                        </div> : null
                                        }
                                    </Container>
                                </>
                            }
                        </BackgroundComponent>
                    </>
            }
        </>
    )
}