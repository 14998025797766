import React from "react";
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
export default function CardResults({ variation, number, titolo, margin, chart, className }) {
    return (
        <>
            <Col xl='5' className={`d-flex justify-content-center ${margin} py-3 ${className}`} style={{ boxShadow: '0px 4px 33px rgba(0, 0, 0, 0.075)', backgroundColor: '#fff', borderRadius: '20px', position: 'relative' }}>
                <div className={"d-block"}>
                    <div className="d-flex flex-row">
                        {
                            variation !== '---' ?
                                <>
                                    <div className="d-flex justify-content-center align-items-center w-100">
                                        <label className="text-center robotoNormal" style={{ fontSize: '13px', color: checkColorText(variation) }}>{variation}%<i class={`bi bi-arrow-${checkDirectionArrow(variation)}-short text-end`} style={{ fontSize: '13px', color: checkColorText(variation) }}></i></label>
                                    </div>
                                </> :
                                <></>
                        }
                    </div>
                    <div className="d-flex justify-content-center">
                        {number}
                    </div>
                    <div className="d-flex justify-content-center">
                        {titolo}
                    </div>
                    <div className="d-flex justify-content-center">
                        {chart}
                    </div>
                </div>
                <div className={`cardResults`}>
                    <FontAwesomeIcon color="white" size={'3x'} icon={faLock} />
                </div>
            </Col>
        </>
    )
}

function checkColorText(number) {
    if (parseInt(number) > 0) {
        return "#2269F4"
    } else {
        return "#F42222"
    }
}

function checkDirectionArrow(number) {
    if (parseInt(number) > 0) {
        return "up"
    } else {
        return "down"
    }
}