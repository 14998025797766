import React, { useEffect } from "react";
import imgError from '../assets/images/404.png'
import Header from "../components/NavbarComponent";
import Footer from "../components/Footer";
import logoPmi from '../assets/logos/logo-pmi.png'

function NotFound() {

    useEffect(() => {
        window.location.replace('/')
    }, [])

    return(
        <>
            <Header link='/' logo={logoPmi} />
            <div className="section">
                <div className="container">
                    <div className="d-flex justify-content-center align-items-center">
                        <img src={imgError} className='w-50 h-50' alt="" />
                    </div>
                    <div className="d-flex justify-content-center">
                        <a href="/" className="btn btn-primary styleButton">Ritorna alla Home</a>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
} export default NotFound