import React from "react";
import { Component } from "react";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import changeCapital from '../assets/images/VirtualCFO/loghiCarousel/1.png'
import pef from '../assets/images/VirtualCFO/loghiCarousel/2.png'
import confartigianato from '../assets/images/VirtualCFO/loghiCarousel/3.png'
import cut from '../assets/images/VirtualCFO/loghiCarousel/4.png'
import lasia from '../assets/images/VirtualCFO/loghiCarousel/5.png'
import bertolotti from '../assets/images/VirtualCFO/loghiCarousel/6.png'
import rewind from '../assets/images/VirtualCFO/loghiCarousel/7.png'
import cioncolini from '../assets/images/VirtualCFO/loghiCarousel/8.png'
import bluteck from '../assets/images/VirtualCFO/loghiCarousel/9.png'
import cmc from '../assets/images/VirtualCFO/loghiCarousel/10.png'
import amen from '../assets/images/VirtualCFO/loghiCarousel/11.png'
import { Container } from "react-bootstrap";

class CarouselPMI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                {
                    srcimg: changeCapital,
                },
                {
                    srcimg: pef
                },
                {
                    srcimg: confartigianato
                },
                {
                    srcimg: cut
                },
                {
                    srcimg: lasia
                },
                {
                    srcimg: bertolotti
                },
                {
                    srcimg: rewind
                },
                {
                    srcimg: cioncolini
                },
                {
                    srcimg: bluteck
                },
                {
                    srcimg: cmc
                },
                {
                    srcimg: amen
                },
            ]
        }
    }
    render() {
        const responsive = {
            superLargeDesktop: {
                breakpoint: { max: 4000, min: 3000 },
                items: 3,
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3,
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 3,
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
            },
        };


        const CustomDot = ({ onMove, index, onClick, active }) => {
            return (
                <li
                    className={'mx-1'}
                    onClick={() => onClick()}
                    style={{ marginTop: '-100px' }}
                >
                    {
                        active ? <>
                            <i class="bi bi-circle-fill" style={{ fontSize: '5px' }}></i>
                        </> : <>
                            <i class="bi bi-circle" style={{ fontSize: '5px' }}></i>
                        </>
                    }
                </li>
            );
        };

        const checkDevice = () => {
            if (/Android|iPhone/i.test(navigator.userAgent)) {
                return 1
            } else {
                return 3
            }
        }

        return (
            <>
                <Container>
                    <Carousel slidesToSlide={checkDevice()} arrows={false} autoPlay='true' autoPlaySpeed={2000} infinite='true' responsive={responsive} showDots={true} customDot={<CustomDot />} >
                        {
                            this.state.data.map(data => (
                                <img src={data.srcimg} style={{ maxHeight: '351px', marginRight: 'auto', marginLeft: 'auto' }} alt="" className="img-fluid d-flex justify-content-center" />
                            ))
                        }
                    </Carousel>
                </Container>
            </>
        )
    }
} export default CarouselPMI