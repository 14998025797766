import React from "react";

export default function BackgroundComponent({ imageBackground, children, radius, className, backgroundSize = 'cover', backgroundColor  }) {
    return (
        <>
            <div className={className} style={!backgroundColor ? { backgroundImage: `url(${imageBackground})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: backgroundSize, borderRadius: radius } : {background: `${backgroundColor}`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: backgroundSize, borderRadius: radius}}>
                {children}
            </div>
        </>
    )
}