import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarChart({ anno3, anno2, anno1, year3, year2, year1, colorStart, colorEnd }){

  const options = {
    responsive: true,
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false // <-- this option disables tooltips
      }
    }
  }

  const labels = [anno3, anno2, anno1]
  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: '',
        data: [parseInt(year3), parseInt(year2), parseInt(year1)],
        borderColor: 'transparent',
        backgroundColor: (context) => {
          const chart = context.chart
          const { ctx, chartArea } = chart
          if(!chartArea) {
            return null
          }
          if(context.datasetIndex === 0) {
            return getGradient(chart, colorStart, colorEnd)
          } else {
            return 'black'
          }
        },
        borderRadius: 10
      },
    ]
  }

  return(
    <Bar options={options} data={data}  style={{ width: '250px', height: '80.5px' }} className="mt-5 mt-md-3 " />
  )
        
} export default BarChart

function getGradient(chart, colorStart, colorEnd) {
  const { ctx, chartArea: {top, bottom, left, right} } = chart
  const gradientElement = ctx.createLinearGradient(left, 0, right, 0)
  gradientElement.addColorStop(0, colorStart)
  gradientElement.addColorStop(1, colorEnd)

  return gradientElement
}