import React, { useState }  from "react";
import { Container, Image } from "react-bootstrap";
import BackgroundComponent from "./BackgroundComponent";
import imageBackground from './../assets/images/Academy/backgroundAcademyBolle.png'
import SubTitleComponent from "./SubTitleComponent";
import imgSrc from './../assets/images/Academy/character.png'
import ButtonComponent from "./ButtonComponent";
import HubspotForm from "./HubspotForm";

export default function BoxAcademy() {

    const [show, setShow] = useState(false)

    return (
        <>
            <BackgroundComponent className={''} backgroundSize="cover" imageBackground={imageBackground}>
                <div className="">
                        <Container>
                            <div className="d-flex justify-content-center">
                                <div className="boxAcademy d-flex justify-content-center align-items-center" style={{ borderRadius: '20px', background: 'linear-gradient(151.81deg, rgba(255, 255, 255, 0.4) -2.68%, rgba(255, 255, 255, 0.1) 102.33%)', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.07)', backdropFilter: 'blur(11px)' }}>
                                    <div className="d-block" style={{textAlign: 'justify'}}>
                                        <SubTitleComponent text={'Oggi ci sono ormai evidenze impattanti che il decision-making guidato dai dati (e con un po’ di sana buona intuizione umana) può fornire <strong>significativi miglioramenti</strong> delle prestazioni aziendali. <strong>Ma in quale aree e in che modo?</strong>'} />
                                        <label>Per scoprirlo abbiamo creato un <strong>percorso</strong> di <strong>formazione</strong> con l’intento di educare tutte quelle aziende e persone curiose sulla cultura dei dati e l’Intelligenza artificiale. Avvicinando così algoritmi e persone per generare sempre più valore.</label>
                                        <div className="d-flex justify-content-center mt-4">
                                            <ButtonComponent handleClick={() => { setShow(true) }} text={'Scopri i nostri corsi'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mt-5 mb-5">
                                <Image src={imgSrc} className='img-fluid dimensioneImmagineAcademy' />
                            </div>
                        </Container>
                    {/* <div className="d-block d-xl-none">
                        <Row className="d-flex justify-content-center align-items-center">
                            <Col className="d-flex align-items-center" style={{ borderRadius: '10px' }}>
                                <div className="d-block" style={{ borderRadius: '10px' }}>
                                    <div className=" py-4 px-3" style={{ borderRadius: '10px', background: 'linear-gradient(151.81deg, rgba(255, 255, 255, 0.4) -2.68%, rgba(255, 255, 255, 0.1) 102.33%)', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.07)', backdropFilter: 'blur(11px)' }}>
                                        <SubTitleComponent className={'text-center'} text={'Oggi ci sono ormai evidenze impattanti che il decision-making guidato dai dati, e con un po’ di sana buona intuizione umana può fornire <strong>significativi miglioramenti</strong> delle prestazioni aziendali. <strong>Ma in quale aree e in che modo?</strong>'} />
                                        <SubTitleComponent className={'text-center'} text={'Per scoprirlo abbiamo creato un <strong>percorso</strong> di <strong>formazione</strong> con l’intento di educare tutte quelle aziende e persone curiose sulla cultura dei dati e l’Intelligenza Artificiale. Avvicinando così algoritmi e persone per generare sempre più valore.'} />
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <div style={{ border: '5px solid #2269F4', borderTop: 'none' }} className="w-100 py-5 mb-4 d-flex justify-content-center">
                                            <ButtonComponent text={'Scopri i nostri corsi'} />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xl='5' className="d-flex justify-content-center ms-4">
                                <Image src={imgSrc} className='img-fluid' />
                            </Col>
                        </Row>
                    </div> */}
                </div>
            </BackgroundComponent>
            <HubspotForm show={show} setShow={setShow} formId={'fda38850-857a-4910-ae80-85fa7e708f55'} />
        </>
    )
}