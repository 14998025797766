import React, { useState } from "react";
import { Helmet } from "react-helmet";
import NavbarComponent from "../components/NavbarComponent";
import HeaderComponent from "../components/HeaderComponent";
import backgroundHeader from './../assets/images/backgroundIndustry.png'
import TextImageComponent from './../components/TextImageComponent'
import dataManifatturiero from './../assets/data/TextImage/textImageIndustryManifatturiero.json'
import AnimatedNumberComponent from "../components/AnimatedNumberComponent";
import dataNumberManifatturiero from './../assets/data/AnimatedNumber/numberIndustryManifatturiero.json'
import backgroundNumberManifatturiero from './../assets/images/backgroundCR.png'
import dataRetail from './../assets/data/TextImage/textImageIndustryRetail.json'
import dataNumberRetail from './../assets/data/AnimatedNumber/numberIndustryRetail.json'
import backgroundNumberRetail from './../assets/images/backgroundNumberRetail.png'
import dataFood from './../assets/data/TextImage/textImageIndustryFood.json'
import backgroundNumberFood from './../assets/images/backgroundResults1.png'
import dataNumberFood from './../assets/data/AnimatedNumber/numberIndustryFood.json'
import TextButtonComponent from "../components/TextButtonComponent";
import Footer from "../components/Footer";
import HubspotForm from "../components/HubspotForm";

export default function Industry() {

    const [show, setShow] = useState(false)

    return (
        <>
            <Helmet>
                <title>Industry</title>
                <meta property="og:url" content="https://www.pmiagile.com/industry" />
                <meta
                    name="description"
                    content="In PMI Agile abbiamo sviluppato soluzioni di personalizzazione che permettono di studiare i processi aziendali e suggerire le migliori decisioni alle diverse necessità attraverso l’intelligenza artificiale"
                />
            </Helmet>
            <NavbarComponent />
            <HeaderComponent className={'justify-content-xl-start'} height="2" align="start" whiteLine={true} colorLine={'#06152c'} sizeLine={'287px'} backgroundImage={backgroundHeader} textSub={'Tutte le realtà aziendali sono <strong>diverse</strong> nelle loro caratteristiche e quindi anche nei benefici<br/><br/>In PMI Agile abbiamo sviluppato <strong>soluzioni di personalizzazione</strong> che permettono di studiare i processi aziendali e suggerire le <strong>migliori decisioni</strong> alle diverse necessità'} textTitle={'<strong>I nostri progetti su misura</strong>'} />
            <TextImageComponent container={true} path={require('./../assets/images/Industry/manifatturiero.png')} data={dataManifatturiero} />
            <AnimatedNumberComponent backgroundColor={`linear-gradient(0deg, rgba(255, 255, 255, 0.54), rgba(255, 255, 255, 0.54)), url(${backgroundNumberManifatturiero})`} data={dataNumberManifatturiero} />
            <TextImageComponent container={true} reverse={true} path={require('./../assets/images/Industry/retail.png')} data={dataRetail} />
            <AnimatedNumberComponent backgroundColor={`linear-gradient(0deg, rgba(255, 255, 255, 0.54), rgba(255, 255, 255, 0.54)), url(${backgroundNumberRetail})`} data={dataNumberRetail} />
            <TextImageComponent container={true} path={require('./../assets/images/Industry/food.png')} data={dataFood} />
            <AnimatedNumberComponent backgroundColor={`linear-gradient(0deg, rgba(255, 255, 255, 0.54), rgba(255, 255, 255, 0.54)), url(${backgroundNumberFood})`} data={dataNumberFood} />
            <TextButtonComponent className={'mt-5 mb-4 px-2'} onClickButton={() => { setShow(true) }} colorBackgroundButton={'linear-gradient(94.76deg, #2269F4 0%, #00EBE9 96.27%)'} colorBorderButton={'transparent'} textButton={'Contattaci'} align={'center'} text={'<strong>Scopri come un progetto di Business Intelligence personalizzato può migliorare il tuo modo di lavorare</strong>'} />
            <HubspotForm show={show} setShow={setShow} formId='fd0474b0-53d6-42c0-85f5-ef70aca98ef7' />
            <Footer />
        </>
    )
}