import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import BackgroundComponent from "./BackgroundComponent";
import ButtonComponent from './ButtonComponent'
import SubTitleComponent from "./SubTitleComponent";
import TitleComponent from "./TitleComponent";

export default function TextImageComponent({ backgroundImage, data, clickButton, path, reverse, container, backgroundColor, margin }) {

    const col = data.map(p => {
        return (
            <>
                {
                    !reverse ?
                        <>
                            <Col xl='6' className="mb-5 mb-xl-0">
                                <div className={`d-flex justify-content-center justify-content-${p.align} align-items-center`}>
                                    <div style={{ width: '600px' }} className={`d-block ${margin}`}>
                                        <div className={`d-flex justify-content-center justify-content-${p.align} mb-4`}>
                                            <TitleComponent className={`text-${p.align} text-center pt-0 pt-xl-3 pb-3 pb-xl-3`} style={{fontSize: '32px'}} text={p.titolo} />
                                        </div>
                                        <SubTitleComponent text={p.body} className={`text-${p.align} text-center subTitleComponent px-2`} />
                                        {
                                            p.checkButton ?
                                                <>
                                                    <div className={`d-flex justify-content-center justify-content-${p.align} mb-4 mb-xl-0`}>
                                                        <ButtonComponent text={p.textButton} handleClick={clickButton} typeBootstrapButton={p.variationButton} />
                                                    </div>
                                                </> :
                                                null
                                        }

                                    </div>
                                </div>
                            </Col>
                            <Col xl='6'>
                                <Image className="img-fluid" src={path} />
                            </Col>
                        </> :
                        <>
                            <Col xl='6' className="mb-5 mb-xl-0">
                                <Image className="img-fluid" src={path} />
                            </Col>
                            <Col xl='6'>
                                <Container>
                                    <div className={`d-flex justify-content-center justify-content-${p.align} align-items-center`}>
                                        <div style={{ width: '450px' }} className="d-block">
                                            <div className={`d-flex justify-content-center justify-content-${p.align}`}>
                                                <TitleComponent className={`text-${p.align} text-center pt-0 pt-xl-3 pb-0 pb-xl-3 titleComponent`} text={p.titolo} />
                                            </div>
                                            <SubTitleComponent text={p.body} className={`text-${p.align} text-center px-0 px-xl-0 subTitleComponent`} />
                                            {
                                                p.checkButton ?
                                                    <>
                                                        <div className={`d-flex justify-content-center justify-content-${p.align} mb-4 mb-xl-0`}>
                                                            <ButtonComponent text={p.textButton} handleClick={clickButton} typeBootstrapButton={p.variationButton} />
                                                        </div>
                                                    </> :
                                                    null
                                            }

                                        </div>
                                    </div>
                                </Container>
                            </Col>
                        </>
                }
            </>
        )
    })

    return (
        <>
            {
                !container ?
                    <>
                        <BackgroundComponent backgroundColor={backgroundColor} className={'py-4 py-xl-5'} imageBackground={backgroundImage}>
                            <Row className="d-flex justify-content-center align-items-center flex-row-reverse flex-xl-row">
                                {col}
                            </Row>
                        </BackgroundComponent>
                    </> : container === true ?
                        <>
                            <Container className={`${window.location.pathname !== '/enterprise' ? 'mt-5' : 'mt-0'}`}>
                                <BackgroundComponent backgroundColor={backgroundColor} className={'py-1 py-xl-5 mt-0'} imageBackground={backgroundImage}>
                                    <Row className="d-flex justify-content-center align-items-center flex-row flex-xl-row-reverse mt-5">
                                        {col}
                                    </Row>
                                </BackgroundComponent>
                            </Container>
                        </> :
                        <>
                            <BackgroundComponent backgroundColor={backgroundColor} className={'py-4 py-xl-5'} imageBackground={backgroundImage}>
                                <Row className="d-flex justify-content-center align-items-center flex-row flex-xl-row-reverse py-5">
                                    {col}
                                </Row>
                            </BackgroundComponent>
                        </>
            }
        </>
    )
}