import React from "react";
import { Container, Image } from "react-bootstrap";
import BackgroundComponent from "./BackgroundComponent";
import SubTitleComponent from "./SubTitleComponent";

export default function TextImageResults({ backgroundImage, text, imageSrc }) {
    return (
        <>
            <BackgroundComponent className={'pt-3'} imageBackground={backgroundImage}>
                <Container className="py-3">
                    <div className="d-flex justify-content-center px-5">
                        <SubTitleComponent className={'text-center subTitleComponent'} text={text} />
                    </div>
                    <div className="d-flex justify-content-center">
                        <Image src={imageSrc} className='img-fluid' />
                    </div>
                </Container>
            </BackgroundComponent>
        </>
    )
}