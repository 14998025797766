import React from "react";
import { useEffect } from "react";
import Footer from "./Footer";
import { AlberaturaMercato } from "./general/mercato";
import Header from './NavbarComponent'
import pmi from '../assets/logos/logo-pmi.png'

function MercatoAlberatura() {

    useEffect(() => {
        if(localStorage.getItem('partitaIVA') !== null){
            localStorage.removeItem('partitaIVA')
        }
    })

    return(
        <>
            <Header link='/' logo={pmi} />
            <AlberaturaMercato />
            <Footer />
        </>
    )
} export default MercatoAlberatura