import React from "react";
import pmi from '../assets/logos/logo-pmi.png'
import Header from "../components/NavbarComponent";
import Footer from "../components/Footer";
import { useEffect } from "react";
import { Helmet } from 'react-helmet'
import HeaderComponent from "../components/HeaderComponent";
import imageBackgroundHeader from '../assets/images/backgroundAzienda.png';
import AnimatedNumberComponent from "../components/AnimatedNumberComponent";
import dataAziendaNumber from '../assets/data/AnimatedNumber/numberAzienda.json'
import dataTextImage from '../assets/data/TextImage/textImageAzienda.json'
import dataTeam from '../assets/data/Team/team.json'
import TextImageComponent from "../components/TextImageComponent";
import BoxSloganComponent from "../components/BoxSloganComponent";
import backgroundSloganAzienda from '../assets/images/backgroundSloganAzienda.png'
import TeamComponent from "../components/TeamComponent";
import CertificateComponent from "../components/CertificateComponent";
import backgroundCertificate from '../assets/images/backgroundCertificate.png'
import certificato from '../assets/images/certificato.png'
import BoxTitleSubTitle from './../components/BoxTitleSubTitle'


function Azienda() {
    useEffect(() => {
        if (localStorage.getItem('partitaIVA') !== null) {
            localStorage.removeItem('partitaIVA')
        }
    })
    return (
        <>
            <Helmet>
                <title>Azienda</title>
                <meta property="og:url" content="https://www.pmiagile.com/azienda" />
                <meta
                    name="description"
                    content="Progettiamo e realizziamo soluzioni innovative per supportare la riorganizzazione di tutti i processi aziendali e professionali, grazie all'intelligenza artificiale"
                />
            </Helmet>
            <Header link='/' logo={pmi} />
            <HeaderComponent classNameSubTitle={'mx-0 mx-sm-3 mx-md-0'} whiteLine={true} colorLine='#06152c' height="2" sizeLine={'287px'} backgroundImage={imageBackgroundHeader} textTitle='<strong>Chi siamo</strong>' textSub={"Siamo un'azienda italiana specializzata in Business Intelligence e Intelligenza Artificiale. Progettiamo e realizziamo soluzioni innovative per supportare la riorganizzazione di tutti i processi aziendali e professionali."} />
            <AnimatedNumberComponent data={dataAziendaNumber} />
            <BoxTitleSubTitle textTitle={''} classNameSubTitle='mx-0 mx-sm-3' checkButton={false} textSubTitle="Il nostro approccio mette l'accento sul capitale umano, avvicinando dati, algoritmi e persone per generare sempre più valore. Crediamo che l'Intelligenza Artificiale debba essere al servizio delle persone, per aiutare a prendere decisioni migliori e più informate.<br/><br/>Con la nostra esperienza e competenza, siamo in grado di offrire soluzioni personalizzate e all'avanguardia per ogni tipo di azienda e professionista. Siamo qui per aiutare i nostri clienti a raggiungere i loro obiettivi e migliorare il loro business.<br/><br/><br/>" />
            <TextImageComponent margin={'mx-0 mx-sm-4 mx-xl-4'} data={dataTextImage} clickButton={() => window.location.href = 'https://www.officinaagile.com'} path={require(`../assets/images/Azienda/textImage.jpg`)} />
            <BoxSloganComponent align='center' backgroundImage={backgroundSloganAzienda} titolo={'<strong>Unicità</strong>'} text='La differenza tra “far squadra” ed “essere una squadra” è la coesione e la fiducia. <br/>Entusiasmo, agilità e competenza son i tre principi cardine che guidano ogni giorno il nostro lavoro per il raggiungimento di obiettivi ambiziosi. Una squadra unita e meravigliosa dove ognuno di noi è riconosciuto come parte attiva di questo progetto condiviso.' />
            <TeamComponent data={dataTeam} />
            <BoxSloganComponent align='center' titolo={'<strong>I punti di forza</strong>'} text='Costruiamo rapporti di lunga data con i nostri clienti, in modo da fornire soluzioni semplici e integrate. Conosciamo le esigenze specifiche di ognuno di loro con l’obiettivo di rendere il cliente pienamente soddisfatto dell’uso delle nostre soluzioni digitali.' />
            <CertificateComponent text={'<strong>Certificazioni e rapporti istituzionali</strong>'} align='center' backgroundImage={backgroundCertificate} imgSrc={certificato}  />
            <Footer />
        </>
    );
}
export default Azienda