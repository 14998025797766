import React, { useEffect, useState } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import './index.css'
import './assets/sass/main.css';
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import Azienda from "./pages/Azienda";
import Piani from "./pages/Piani";
import AnalisiBilancio from "./pages/AnalisiBilancio";
import AnalisiBenchmark from "./pages/AnalisiBenchmark";
import AnalisiCR from "./pages/AnalisiCR";
import { Mercato } from "./components/general/mercato";
import MercatoAlberatura from "./components/MercatoAlberatura";
import Results from "./pages/Results";
import TagManager from "react-gtm-module";
import ContenutiPage from "./pages/ContenutiPage";
import Academy from "./pages/Academy";
import Industry from "./pages/Industry";
import Enterprise from "./pages/Enterprise";
import Faq from "./pages/Faq";

function App() {

  const [isLoading, setIsLoading] = useState(true)
  const loader = document.getElementById('loading')
  if(loader){
    setTimeout(() => {
      loader.style.display = 'none'
      setIsLoading(false)
    }, 1000)
  }

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-TK674KV' })
  }, [])

  return (

    !isLoading && (
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/azienda" element={<Azienda />} />
          <Route exact path="/piani" element={<Piani />} />
          <Route exact path="/analisi-bilancio" element={<AnalisiBilancio />} />
          <Route exact path="/analisi-benchmark" element={<AnalisiBenchmark />} />
          <Route exact path="/analisi-cr" element={<AnalisiCR />} />
          <Route exact path="/enterprise" element={<Enterprise />} />
          <Route exact path="/mercato" element={<Mercato />} />
          <Route exact path="/mercato-alberatura" element={<MercatoAlberatura />} />
          <Route exact path="/search/:piva" element={<Results />} />
          <Route exact path="/contenuti" element={<ContenutiPage />} />
          <Route exact path="/academy" element={<Academy />} />
          <Route exact path="/industry" element={<Industry />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    )
        
  );
}

export default App;
