import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SubTitleComponent from "./SubTitleComponent";
import Card from './Card'

export default function BoxComeFunzionaEnterprise({ data, text, marginBottom = 'marginBottomCosaFunziona' }) {

    const col = data.map(p => {
        return (
            <>
                <Col xl='4' className="d-flex justify-content-center mb-4">
                    <Card className='px-4 d-flex justify-content-center pt-3 pb-3' style={parseInt(p.id) % 2 !== 1 ? { background: '#EFF3FF', border: '1px solid #B1B1B1', borderRadius: '10px', width: '237px', marginRight: '-600px', marginLeft: '-600px' } : { backgroundColor: '#EFF3FF', border: '1px solid #B1B1B1', borderRadius: '10px', width: '237px' }} titolo={p.titolo} icona={p.icona} text={p.text} />
                </Col>
            </>
        )
    })

    return (
        <>
            <Container className={`${marginBottom}`}>
                <div className="py-5 mb-5 boxComeFunzionaEnterprise">
                    <SubTitleComponent className={'text-center pt-2 pb-5 py-5 px-1'} style={{ fontSize: '34px', color: '#000' }} align='center' text={text} />
                    <div className="d-flex justify-content-center">
                        <Row className="w-100 d-flex justify-content-center">
                            {col}
                        </Row>
                    </div>
                </div>
            </Container>
        </>
    )
}

// , height: '300px'