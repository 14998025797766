import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import BackgroundComponent from "./BackgroundComponent";

export default function AnimatedNumberComponent({ colSpacing = '3', data, backgroundImage, titolo, width = '128px', height = '128px', backgroundColor, fontSizeTitolo = 'fs-4', fontSizeSubTitolo = 'fs-7', container = true }) {

    const col = data.map(p => {
        return (
            <>
                <Col sm='12' xl={`${colSpacing}`} className="my-3 my-xl-0 px-3 px-sm-5 px-xl-2">
                        {
                            p.imgSrc !== '' ?
                                <>
                                    <div className="d-flex justify-content-center align-items-center mt-3">
                                        <div style={{ border: '1px solid #ededed', borderRadius: '100px', width: width, height: height, backgroundColor: '#fff' }} className="d-flex justify-content-center align-items-center mb-4">
                                            <Image src={require('../assets/images/AnimatedNumber/' + p.imgSrc)} alt={p.titolo} className='img-fluid' />
                                        </div>
                                    </div>
                                </> : null
                        }
                        {p.titolo !== '' ? <p className={`text-center ${fontSizeTitolo}`} dangerouslySetInnerHTML={{ __html: p.titolo }} /> : null}
                        <p className={`text-center ${fontSizeSubTitolo}`} dangerouslySetInnerHTML={{ __html: p.body }} />
                </Col>
            </>
        )
    })

    return (
        <>
            {
                container ?
                    <>
                        <Container>
                            <BackgroundComponent className={`py-4 py-xl-4 mt-5 px-0 px-md-5 mb-5`} radius='15px' backgroundColor={backgroundColor} imageBackground={backgroundImage}>
                                <div className="d-flex justify-content-center">
                                    {titolo}
                                </div>
                                <Row className="d-flex justify-content-center">
                                    {col}
                                </Row>
                            </BackgroundComponent>
                        </Container>
                    </> :
                    <>
                        <BackgroundComponent className={`py-4 py-xl-4 px-5`} radius='15px' backgroundColor={backgroundColor} imageBackground={backgroundImage}>
                            <div className="d-flex justify-content-center">
                                {titolo}
                            </div>
                            <Row className="d-flex justify-content-center">
                                {col}
                            </Row>
                        </BackgroundComponent>
                    </>
            }
        </>
    )
}