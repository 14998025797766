import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import BackgroundComponent from "./BackgroundComponent";
import ButtonComponent from './ButtonComponent'
import SubTitleComponent from "./SubTitleComponent";
import TitleComponent from "./TitleComponent";

export default function TextImageComponentSmall({ backgroundImage, data, clickButton, radius, reverse = true }) {

    const col = data.map(p => {
        return (
            <>
                {
                    !reverse ?
                        <>
                            <Col xl='4' className="textImageBox d-flex justify-content-center">
                                <Image className="img-fluid" src={require('../assets/images/Piani/' + p.imgSrc)} />
                            </Col>
                            <Col xl='8' className="d-flex justify-content-center align-items-center mb-5">
                                <div className="d-flex justify-content-center">
                                    <div>
                                        <div className={`d-flex justify-content-center justify-content-${p.align}`}>
                                            <TitleComponent className={`text-${p.align} text-center pt-3 px-0 pe-xl-5 titleComponent`} text={p.titolo} align={p.align} />
                                        </div>
                                        <div className={`d-flex justify-content-center justify-content-${p.align} pe-xl-5 pt-0 my-3 my-xl-0 pt-xl-4`}>
                                            <SubTitleComponent className={'pe-xl-5 text-center text-xl-start'} text={p.body} align={p.align} />
                                        </div>
                                        <div className={`d-flex justify-content-center justify-content-${p.align} mb-xl-0 pt-0`}>
                                            <ButtonComponent text={p.textButton} handleClick={clickButton} typeBootstrapButton={p.variationButton} />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </> :
                        <>
                            <div className="d-block d-xl-none">
                                <Col xl='4' className="textImageBoxReverse d-flex justify-content-center pt-xxl-0 mt-xxl-0">
                                    <Image className="dimensioniImmagine" src={require('../assets/images/Piani/' + p.imgSrc)} />
                                </Col>
                                <Col xl='7' className="d-flex justify-content-center align-items-center mb-5">
                                    <div className="ms-0 ms-lg-5">
                                        <div className={`d-flex justify-content-center justify-content-${p.align}`}>
                                            <TitleComponent className={`text-${p.align} text-center px-3 px-xl-0 titleComponent`} text={p.titolo} align={p.align} />
                                        </div>
                                        <div className={`d-flex justify-content-center justify-content-${p.align} pt-0 my-3 my-xl-0 pt-xl-3`}>
                                            <SubTitleComponent className={`text-${p.align} text-center px-4`} text={p.body} align={p.align} />
                                        </div>
                                        <div className={`d-flex justify-content-center justify-content-${p.align} mb-xl-0`}>
                                            <ButtonComponent text={p.textButton} handleClick={clickButton} className='px-4' typeBootstrapButton={p.variationButton} />
                                        </div>
                                    </div>
                                </Col>
                            </div>
                            <div className="d-none d-xl-flex">
                                <Col xl='7' className="d-flex justify-content-center align-items-center mb-xl-4 mb-xxl-5">
                                    <div className="ms-0 ms-sm-5">
                                        <div className={`d-flex justify-content-center justify-content-${p.align}`}>
                                            <TitleComponent className={`text-${p.align} text-center px-0 px-xl-3 px-xxl-5 titleComponent`} text={p.titolo} align={p.align} />
                                        </div>
                                        <div className={`d-flex justify-content-center justify-content-${p.align} pt-0 my-3 my-xl-0 pt-xl-3`}>
                                            <SubTitleComponent className={`text-${p.align} text-center px-0 px-xl-3 px-xxl-5`} text={p.body} align={p.align} />
                                        </div>
                                        <div className={`d-flex justify-content-center justify-content-${p.align} px-0 px-xl-3 px-xxl-5 mb-4 mb-xl-0 pt-3`}>
                                            <ButtonComponent text={p.textButton} handleClick={clickButton} typeBootstrapButton={p.variationButton} />
                                        </div>
                                    </div>
                                </Col>
                                <Col xl='4' className="textImageBoxReverse d-flex justify-content-center pt-xxl-0 mt-xxl-0">
                                    <Image style={window.location.pathname === '/analisi-bilancio' ? {marginLeft: '16rem'} : {}} className="dimensioniImmagine" src={require('../assets/images/Piani/' + p.imgSrc)} />
                                </Col>
                            </div>
                        </>
                }
            </>
        )
    })

    return (
        <>
            <Container className="mb-5 pb-5">
                <BackgroundComponent imageBackground={backgroundImage} className={`py-4 py-xl-2 py-xxl-5 margineTop px-0 px-md-2 px-xxl-5`} radius={'40px'}>
                    <Row className="textImageBoxRow d-flex justify-content-center align-items-center">
                        {col}
                    </Row>
                </BackgroundComponent>
            </Container>
        </>
    )
}