import React from "react";
import { Row, Col } from "react-bootstrap";
import BackgroundComponent from "./BackgroundComponent";
import TitleComponent from "./TitleComponent";
import SubTitleComponent from "./SubTitleComponent";
import ButtonComponent from "./ButtonComponent";

export default function BoxAnalisi({ backgroundColor = '#EFF3FF', reverse, textTitle, textSubTitle, checkButton, textButton, typeBootstrapButton, onClick, className, backgroundImage, radius, image1, text1, image2, text2, image3, text3, text4, image4 }) {

    // return (
    //     <>
    //         {
    //             !reverse ?
    //                 <>
    //                     <Container className="py-5">
    //                         <div className="d-flex justify-content-center">
    //                             <Row className="boxAnalisiContainer" style={{ border: '3px solid #2269F4' }}>
    //                                 <Col sm='12' xxl='6'>
    //                                     <BackgroundComponent className={className} backgroundColor={backgroundColor} radius={radius}>
    //                                         <div className="d-flex justify-content-center">
    //                                             <TitleComponent text={textTitle} className={`text-center`} style={{ fontSize: '32px' }} />
    //                                         </div>
    //                                         <div className="d-flex justify-content-center mt-2">
    //                                             <SubTitleComponent text={textSubTitle} className={`text-center subTitleComponent`} />
    //                                         </div>
    //                                         {
    //                                             checkButton ?
    //                                                 <>
    //                                                     <div className="d-flex justify-content-center mt-3"><ButtonComponent text={textButton} typeBootstrapButton={typeBootstrapButton} handleClick={onClick} /></div>
    //                                                 </> :
    //                                                 null
    //                                         }
    //                                     </BackgroundComponent>
    //                                 </Col>
    //                                 <Col sm='12' xxl='6' className={`d-flex justify-content-center align-items-center ${text4 === '' ? 'boxAnalisiText' : 'boxAnalisiText4'}`} style={{ marginTop: '-150px' }}>
    //                                     <div className="d-block">
    //                                         <div className={`d-flex flex-row align-items-center ${text4 === '' ? 'mb-4' : 'mb-2'}`}>
    //                                             <div className="p-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: text4 === '' ? '#2269F4' : 'transparent', width: '45px', height: '45px', borderRadius: '50%' }}>
    //                                                 <img src={image1} className='img-fluid' alt="Analisi di Bialncio" />
    //                                             </div>
    //                                             <div className="p-2 w-100">
    //                                                 <label style={{ fontSize: '20px', color: '#565656' }} dangerouslySetInnerHTML={{ __html: text1 }} />
    //                                             </div>
    //                                         </div>
    //                                         <div className={`d-flex flex-row align-items-center ${text4 === '' ? 'mb-4' : 'mb-2'}`}>
    //                                             <div className="p-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: text4 === '' ? '#2269F4' : 'transparent', width: '45px', height: '45px', borderRadius: '50%' }}>
    //                                                 <img src={image2} className='img-fluid' alt="Analisi di Bialncio" />
    //                                             </div>
    //                                             <div className="p-2">
    //                                                 <label style={{ fontSize: '20px', color: '#565656' }} dangerouslySetInnerHTML={{ __html: text2 }} />
    //                                             </div>
    //                                         </div>
    //                                         <div className="d-flex flex-row align-items-center">
    //                                             <div className="p-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: text4 === '' ? '#2269F4' : 'transparent', width: '45px', height: '45px', borderRadius: '50%' }}>
    //                                                 <img src={image3} className='img-fluid' alt="Analisi di Bialncio" />
    //                                             </div>
    //                                             <div className="p-2">
    //                                                 <label style={{ fontSize: '20px', color: '#565656' }} dangerouslySetInnerHTML={{ __html: text3 }} />
    //                                             </div>
    //                                         </div>
    //                                         {
    //                                             text4 !== '' ?
    //                                                 <>
    //                                                     <div className={`d-flex flex-row align-items-center ${text4 === '' ? 'mt-4' : 'mt-2'}`}>
    //                                                         <div className="p-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: text4 === '' ? '#2269F4' : 'transparent', width: '45px', height: '45px', borderRadius: '50%' }}>
    //                                                             <img src={image4} className='img-fluid' alt="Analisi di Bialncio" />
    //                                                         </div>
    //                                                         <div className="p-2">
    //                                                             <label style={{ fontSize: '20px', color: '#565656' }} dangerouslySetInnerHTML={{ __html: text4 }} />
    //                                                         </div>
    //                                                     </div>
    //                                                 </> : null
    //                                         }
    //                                     </div>
    //                                 </Col>
    //                             </Row>
    //                         </div>
    //                     </Container>
    //                 </> :
    //                 <>
    //                     <Container className="py-5 marginTopMobile">
    //                         <div className="d-flex justify-content-center">
    //                             <Row className="boxAnalisiContainerReverse" style={{ border: '3px solid #2269F4' }}>
    //                                 <Col sm='12' xxl='6' className={`d-flex justify-content-center align-items-center ${text4 === '' ? 'boxAnalisiTextReverse' : 'boxAnalisiText4Reverse'}`} style={{ marginTop: '-150px' }}>
    //                                     <div className="d-block">
    //                                         <div className={`d-flex flex-row align-items-center ${text4 === '' ? 'mb-4' : 'mb-2'}`}>
    //                                             <div className="p-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: text4 === '' ? '#2269F4' : 'transparent', width: '45px', height: '45px', borderRadius: '50%' }}>
    //                                                 <img src={image1} className='img-fluid' alt="Analisi di Bialncio" />
    //                                             </div>
    //                                             <div className="p-2 w-100">
    //                                                 <label style={{ fontSize: '20px', color: '#565656' }} dangerouslySetInnerHTML={{ __html: text1 }} />
    //                                             </div>
    //                                         </div>
    //                                         <div className={`d-flex flex-row align-items-center ${text4 === '' ? 'mb-4' : 'mb-2'}`}>
    //                                             <div className="p-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: text4 === '' ? '#2269F4' : 'transparent', width: '45px', height: '45px', borderRadius: '50%' }}>
    //                                                 <img src={image2} className='img-fluid' alt="Analisi di Bialncio" />
    //                                             </div>
    //                                             <div className="p-2">
    //                                                 <label style={{ fontSize: '20px', color: '#565656' }} dangerouslySetInnerHTML={{ __html: text2 }} />
    //                                             </div>
    //                                         </div>
    //                                         <div className="d-flex flex-row align-items-center">
    //                                             <div className="p-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: text4 === '' ? '#2269F4' : 'transparent', width: '45px', height: '45px', borderRadius: '50%' }}>
    //                                                 <img src={image3} className='img-fluid' alt="Analisi di Bialncio" />
    //                                             </div>
    //                                             <div className="p-2">
    //                                                 <label style={{ fontSize: '20px', color: '#565656' }} dangerouslySetInnerHTML={{ __html: text3 }} />
    //                                             </div>
    //                                         </div>
    //                                         {
    //                                             text4 !== '' ?
    //                                                 <>
    //                                                     <div className={`d-flex flex-row align-items-center ${text4 === '' ? 'mt-4' : 'mt-2'}`}>
    //                                                         <div className="p-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: text4 === '' ? '#2269F4' : 'transparent', width: '45px', height: '45px', borderRadius: '50%' }}>
    //                                                             <img src={image4} className='img-fluid' alt="Analisi di Bialncio" />
    //                                                         </div>
    //                                                         <div className="p-2">
    //                                                             <label style={{ fontSize: '20px', color: '#565656' }} dangerouslySetInnerHTML={{ __html: text4 }} />
    //                                                         </div>
    //                                                     </div>
    //                                                 </> : null
    //                                         }
    //                                     </div>
    //                                 </Col>
    //                                 <Col sm='12' xxl='6'>
    //                                     <BackgroundComponent className={className} backgroundColor={backgroundColor} radius={radius}>
    //                                         <div className="d-flex justify-content-center">
    //                                             <TitleComponent text={textTitle} className={`text-center`} style={{ fontSize: '32px' }} />
    //                                         </div>
    //                                         <div className="d-flex justify-content-center mt-2">
    //                                             <SubTitleComponent text={textSubTitle} className={`text-center subTitleComponent`} />
    //                                         </div>
    //                                         {
    //                                             checkButton ?
    //                                                 <>
    //                                                     <div className="d-flex justify-content-center mt-3"><ButtonComponent text={textButton} typeBootstrapButton={typeBootstrapButton} handleClick={onClick} /></div>
    //                                                 </> :
    //                                                 null
    //                                         }
    //                                     </BackgroundComponent>
    //                                 </Col>
    //                             </Row>
    //                         </div>
    //                     </Container>
    //                 </>
    //         }
    //     </>
    // )

    return (
        <>
            {
                !reverse ?
                    <>
                        <div className="d-none d-xl-flex flex-xl-row align-items-xl-center justify-content-center">
                            <div
                                className={`${text4 === '' ? 'boxAnalisi' : 'boxAnalisi4'}`}
                            >
                                <BackgroundComponent className={'h-100 d-flex justify-content-center align-items-center'} backgroundColor={backgroundColor} radius={radius}>
                                    <div className="d-block">
                                        <div className="d-flex justify-content-center">
                                            <TitleComponent text={textTitle} className={`text-center`} style={{ fontSize: '32px', fontWeight: '600' }} />
                                        </div>
                                        <div className="d-flex justify-content-center mt-3 px-5">
                                            <SubTitleComponent text={textSubTitle} className={`text-center`} style={{ fontSize: '20px' }} />
                                        </div>
                                        {
                                            checkButton ?
                                                <>
                                                    <div className="d-flex justify-content-center mt-3"><ButtonComponent text={textButton} typeBootstrapButton={typeBootstrapButton} handleClick={onClick} /></div>
                                                </> :
                                                null
                                        }
                                    </div>
                                </BackgroundComponent>
                            </div>
                            <div
                                className={`${text4 === '' ? 'boxAnalisiText' : 'boxAnalisiText4'} my-5`}
                            >
                                <Row className="w-100 h-100">
                                    <Col xl='4'></Col>
                                    <Col xl='8' className="d-flex justify-content-center align-items-center">
                                        <div className="d-block ms-5">
                                            <div className={`d-flex flex-row align-items-center ${text4 !== '' ? 'py-1' : 'py-4'}`}>
                                                <div className="p-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: text4 === '' ? '#2269F4' : 'transparent', width: '45px', height: '45px', borderRadius: '50%' }}>
                                                    <img src={image1} className='img-fluid' alt="Analisi di Bialncio" />
                                                </div>
                                                <div className="ps-3">
                                                    <label style={{ fontSize: '20px', color: '#565656' }} dangerouslySetInnerHTML={{ __html: text1 }} />
                                                </div>
                                            </div>
                                            <div className={`d-flex flex-row align-items-center ${text4 !== '' ? 'py-1' : 'py-4'}`}>
                                                <div className="p-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: text4 === '' ? '#2269F4' : 'transparent', width: '45px', height: '45px', borderRadius: '50%' }}>
                                                    <img src={image2} className='img-fluid' alt="Analisi di Bialncio" />
                                                </div>
                                                <div className="ps-3">
                                                    <label style={{ fontSize: '20px', color: '#565656' }} dangerouslySetInnerHTML={{ __html: text2 }} />
                                                </div>
                                            </div>
                                            <div className={`d-flex flex-row align-items-center ${text4 !== '' ? 'py-1' : 'py-4'}`}>
                                                <div className="p-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: text4 === '' ? '#2269F4' : 'transparent', width: '45px', height: '45px', borderRadius: '50%' }}>
                                                    <img src={image3} className='img-fluid' alt="Analisi di Bialncio" />
                                                </div>
                                                <div className="ps-3">
                                                    <label style={{ fontSize: '20px', color: '#565656' }} dangerouslySetInnerHTML={{ __html: text3 }} />
                                                </div>
                                            </div>
                                            {
                                                text4 !== '' ?
                                                    <>
                                                        <div className={`d-flex flex-row align-items-center ${text4 === '' ? 'py-1' : 'mt-2'}`}>
                                                            <div className="p-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: text4 === '' ? '#2269F4' : 'transparent', width: '45px', height: '45px', borderRadius: '50%' }}>
                                                                <img src={image4} className='img-fluid' alt="Analisi di Bialncio" />
                                                            </div>
                                                            <div className="p-2">
                                                                <label style={{ fontSize: '20px', color: '#565656' }} dangerouslySetInnerHTML={{ __html: text4 }} />
                                                            </div>
                                                        </div>
                                                    </> : null
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="d-flex d-xl-none align-items-center justify-content-center">
                            <div className="d-block">
                                <div className="d-flex justify-content-center">
                                    <div
                                        className={`${text4 === '' ? 'boxAnalisi' : 'boxAnalisi4'}`}
                                    >
                                        <BackgroundComponent className={'h-100 d-flex justify-content-center align-items-center'} backgroundColor={backgroundColor} radius={radius}>
                                            <div className="d-block">
                                                <div className="d-flex justify-content-center">
                                                    <TitleComponent text={textTitle} className={`text-center`} style={{ fontSize: '32px', fontWeight: '600' }} />
                                                </div>
                                                {
                                                    textSubTitle !== '' ?
                                                        <>
                                                            <div className="d-flex justify-content-center mt-3 px-5">
                                                                <SubTitleComponent text={textSubTitle} className={`text-center`} style={{ fontSize: '20px' }} />
                                                            </div>
                                                        </> : null
                                                }
                                                {
                                                    checkButton ?
                                                        <>
                                                            <div className="d-flex justify-content-center mt-3"><ButtonComponent text={textButton} typeBootstrapButton={typeBootstrapButton} handleClick={onClick} /></div>
                                                        </> :
                                                        null
                                                }
                                            </div>
                                        </BackgroundComponent>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div
                                        className={`${text4 === '' ? 'boxAnalisiText my-5' : 'boxAnalisiText4 mt-5'} `}
                                    >
                                        <Row className="w-100 h-100">
                                            <Col xl='4'></Col>
                                            <Col xl='8' className="d-flex justify-content-center align-items-center">
                                                <div className="d-block ms-lg-5">
                                                    <div className={`d-flex flex-row align-items-center ${text4 !== '' ? 'py-1' : 'py-4'}`}>
                                                        <div className="p-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: text4 === '' ? '#2269F4' : 'transparent', width: '45px', height: '45px', borderRadius: '50%' }}>
                                                            <img src={image1} className='img-fluid' alt="Analisi di Bialncio" />
                                                        </div>
                                                        <div className="ps-3">
                                                            <label style={{ fontSize: '20px', color: '#565656' }} dangerouslySetInnerHTML={{ __html: text1 }} />
                                                        </div>
                                                    </div>
                                                    <div className={`d-flex flex-row align-items-center ${text4 !== '' ? 'py-1' : 'py-4'}`}>
                                                        <div className="p-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: text4 === '' ? '#2269F4' : 'transparent', width: '45px', height: '45px', borderRadius: '50%' }}>
                                                            <img src={image2} className='img-fluid' alt="Analisi di Bialncio" />
                                                        </div>
                                                        <div className="ps-3">
                                                            <label style={{ fontSize: '20px', color: '#565656' }} dangerouslySetInnerHTML={{ __html: text2 }} />
                                                        </div>
                                                    </div>
                                                    <div className={`d-flex flex-row align-items-center ${text4 !== '' ? 'py-1' : 'py-4'}`}>
                                                        <div className="p-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: text4 === '' ? '#2269F4' : 'transparent', width: '45px', height: '45px', borderRadius: '50%' }}>
                                                            <img src={image3} className='img-fluid' alt="Analisi di Bialncio" />
                                                        </div>
                                                        <div className="ps-3">
                                                            <label style={{ fontSize: '20px', color: '#565656' }} dangerouslySetInnerHTML={{ __html: text3 }} />
                                                        </div>
                                                    </div>
                                                    {
                                                        text4 !== '' ?
                                                            <>
                                                                <div className={`d-flex flex-row align-items-center ${text4 === '' ? 'py-1' : 'mt-2'}`}>
                                                                    <div className="p-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: text4 === '' ? '#2269F4' : 'transparent', width: '45px', height: '45px', borderRadius: '50%' }}>
                                                                        <img src={image4} className='img-fluid' alt="Analisi di Bialncio" />
                                                                    </div>
                                                                    <div className="p-2">
                                                                        <label style={{ fontSize: '20px', color: '#565656' }} dangerouslySetInnerHTML={{ __html: text4 }} />
                                                                    </div>
                                                                </div>
                                                            </> : null
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> :
                    <>
                        <div className="d-none d-xl-flex flex-xl-row align-items-xl-center justify-content-center">
                            <div
                                className="boxAnalisiTextReverse my-5"
                            >
                                <Row className="w-100 h-100">
                                    <Col xl='8' className="d-flex justify-content-center align-items-center">
                                        <div className="d-block me-5">
                                            <div className="d-flex flex-row align-items-center py-4">
                                                <div className="p-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: text4 === '' ? '#2269F4' : 'transparent', width: '45px', height: '45px', borderRadius: '50%' }}>
                                                    <img src={image1} className='img-fluid' alt="Analisi di Bialncio" />
                                                </div>
                                                <div className="ps-3">
                                                    <label style={{ fontSize: '20px', color: '#565656' }} dangerouslySetInnerHTML={{ __html: text1 }} />
                                                </div>
                                            </div>
                                            <div className="d-flex flex-row align-items-center py-4">
                                                <div className="p-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: text4 === '' ? '#2269F4' : 'transparent', width: '45px', height: '45px', borderRadius: '50%' }}>
                                                    <img src={image2} className='img-fluid' alt="Analisi di Bialncio" />
                                                </div>
                                                <div className="ps-3">
                                                    <label style={{ fontSize: '20px', color: '#565656' }} dangerouslySetInnerHTML={{ __html: text2 }} />
                                                </div>
                                            </div>
                                            <div className="d-flex flex-row align-items-center py-4">
                                                <div className="p-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: text4 === '' ? '#2269F4' : 'transparent', width: '45px', height: '45px', borderRadius: '50%' }}>
                                                    <img src={image3} className='img-fluid' alt="Analisi di Bialncio" />
                                                </div>
                                                <div className="ps-3">
                                                    <label style={{ fontSize: '20px', color: '#565656' }} dangerouslySetInnerHTML={{ __html: text3 }} />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl='4'></Col>
                                </Row>
                            </div>
                            <div
                                className="boxAnalisiReverse"
                            >
                                <BackgroundComponent className={'h-100 d-flex align-items-center'} backgroundColor={backgroundColor} radius={radius}>
                                    <div className="d-block">
                                        <div className="d-flex justify-content-center px-0 px-xl-3">
                                            <TitleComponent text={textTitle} className={`text-center`} style={{ fontSize: '32px', fontWeight: '600' }} />
                                        </div>
                                        <div className="d-flex justify-content-center mt-3 px-5">
                                            <SubTitleComponent text={textSubTitle} className={`text-center`} style={{ fontSize: '20px' }} />
                                        </div>
                                        {
                                            checkButton ?
                                                <>
                                                    <div className="d-flex justify-content-center mt-3"><ButtonComponent text={textButton} typeBootstrapButton={typeBootstrapButton} handleClick={onClick} /></div>
                                                </> :
                                                null
                                        }
                                    </div>
                                </BackgroundComponent>
                            </div>
                        </div>
                        <div className="d-flex d-xl-none align-items-center justify-content-center">
                            <div className="d-block">
                                <div className="d-flex justify-content-center">
                                    <div
                                        className="boxAnalisiReverse my-5"
                                    >
                                        <BackgroundComponent className={'h-100 d-flex align-items-center'} backgroundColor={backgroundColor} radius={radius}>
                                            <div className="d-block">
                                                <div className="d-flex justify-content-center px-5 px-xl-0">
                                                    <TitleComponent text={textTitle} className={`text-center`} style={{ fontSize: '32px', fontWeight: '600' }} />
                                                </div>
                                                <div className="d-flex justify-content-center mt-3 px-3">
                                                    <SubTitleComponent text={textSubTitle} className={`text-center`} style={{ fontSize: '20px' }} />
                                                </div>
                                                {
                                                    checkButton ?
                                                        <>
                                                            <div className="d-flex justify-content-center mt-3"><ButtonComponent text={textButton} typeBootstrapButton={typeBootstrapButton} handleClick={onClick} /></div>
                                                        </> :
                                                        null
                                                }
                                            </div>
                                        </BackgroundComponent>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div
                                        className="boxAnalisiTextReverse my-5"
                                    >
                                        <Row className="w-100 h-100">
                                            <Col xl='8' className="d-flex justify-content-center align-items-center">
                                                <div className="d-block">
                                                    <div className="d-flex flex-row align-items-center py-4">
                                                        <div className="p-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: text4 === '' ? '#2269F4' : 'transparent', width: '45px', height: '45px', borderRadius: '50%' }}>
                                                            <img src={image1} className='img-fluid' alt="Analisi di Bialncio" />
                                                        </div>
                                                        <div className="ps-3">
                                                            <label style={{ fontSize: '20px', color: '#565656' }} dangerouslySetInnerHTML={{ __html: text1 }} />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-row align-items-center py-4">
                                                        <div className="p-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: text4 === '' ? '#2269F4' : 'transparent', width: '45px', height: '45px', borderRadius: '50%' }}>
                                                            <img src={image2} className='img-fluid' alt="Analisi di Bialncio" />
                                                        </div>
                                                        <div className="ps-3">
                                                            <label style={{ fontSize: '20px', color: '#565656' }} dangerouslySetInnerHTML={{ __html: text2 }} />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-row align-items-center py-4">
                                                        <div className="p-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: text4 === '' ? '#2269F4' : 'transparent', width: '45px', height: '45px', borderRadius: '50%' }}>
                                                            <img src={image3} className='img-fluid' alt="Analisi di Bialncio" />
                                                        </div>
                                                        <div className="ps-3">
                                                            <label style={{ fontSize: '20px', color: '#565656' }} dangerouslySetInnerHTML={{ __html: text3 }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl='4'></Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}