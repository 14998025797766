import React from "react";
import { Container } from "react-bootstrap";
import BackgroundComponent from "./BackgroundComponent";
import TitleComponent from "./TitleComponent";

export default function BoxSloganComponent({ backgroundImage, titolo, text, align }) {
    return (
        <>
            <Container className="mt-5">
                <BackgroundComponent className={'px-0 px-sm-5 w-100'} imageBackground={backgroundImage} radius='20px 20px 0px 0px' linerGradient={'0deg, #FFFFFF 14.49%, rgba(255, 255, 255, 0) 100%'}>
                    <div className={`px-0 px-xl-5 ${window.location.pathname === '/azienda' ? 'py-5' : 'py-1'} mx-3 mx-sm-2 mx-xl-1`}>
                        <div className="d-flex justify-content-center">
                            <TitleComponent className={`text-${align} pt-1 px-0 px-xl-0 titleComponent mt-1`} text={titolo} />
                        </div>
                        <p className={`text-${align} mt-3`} style={{lineHeight: '1.5rem'}} dangerouslySetInnerHTML={{ __html: text }} />
                    </div>
                </BackgroundComponent>
            </Container>
        </>
    )
}