import React, { useEffect, useState } from "react";
import BackgroundComponent from "../components/BackgroundComponent";
import imageBackground from '../assets/images/backgroundSearch.png'
import WhiteLineComponent from "../components/WhiteLineComponent";
import { useNavigate } from "react-router-dom";
import config from './../.config.json'
import { LoadingResults } from "./general/results";

function Search(props) {

    const [piva, setPiva] = useState('')
    const [name, setName] = useState('')
    const [isLoaded, setIsLoaded] = useState(false)
    const [item, setItem] = useState([])
    const navigate = useNavigate()

    const blurSearch = e => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            document.getElementById('inputPIVA').placeholder = "Inserisci la Partita IVA"
        } else {
            document.getElementById('inputPIVA').placeholder = "Inserisci la Partita IVA"
        }
    }

    const loadPlaceHolder = e => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            document.getElementById('inputPIVA').placeholder = "Inserisci la Partita IVA"
        } else {
            document.getElementById('inputPIVA').placeholder = "Inserisci la Partita IVA"
        }
    }

    const getName = async () => {
        setIsLoaded(true)
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': config.RESULTS,
            }
        }
        await fetch("https://6kcj3trvik.execute-api.eu-south-1.amazonaws.com/v1/company_lake?piva=" + piva, options).then(res => res.json())
        .then((result) => {
            setIsLoaded(false)
            setItem(result)
        })
    }

    if (isLoaded) {
        return <div className="colorBackground d-flex justify-content-center" style={{height: '100vh'}}><div className="d-flex justify-content-center align-items-center spinner"><LoadingResults /></div></div>
    } else {
        if(item.length !== 0 && item.result !== 'Bilancio non presente') {
            navigate('/search/' + item.result.url, {
                state: {
                    checkCompanyLake: true,
                    item: item
                }
            })
        } else {

            if(item.result === 'Bilancio non presente') {
                navigate('/', {
                    state: {
                        popup: true
                    }
                })
            }

            return (
                <>
                    <BackgroundComponent imageBackground={imageBackground}>
                        <div onLoad={loadPlaceHolder} className='d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
                            <div className="container py-5">
                                <div className="d-flex justify-content-center align-items-center py-5">
                                    <div className="d-block">
                                        <h1 style={{ fontSize: '48px' }} className="text-center mb-4 mt-5 pt-5 pt-md-0 mt-md-0">Aggrega e analizza <br />oltre <strong>5 milioni</strong> di aziende</h1>
                                        <div className="mt-5">
                                            <div class="input-group mb-3">
                                                <span class="input-group-text px-4" id="basic-addon1"><strong>IT</strong></span>
                                                <input onChange={(event) => { setPiva(event.target.value) }} type="search" onFocus={() => { document.getElementById('inputPIVA').placeholder = '' }} onBlur={blurSearch} id="inputPIVA" className="form-control text-center py-3 robotoNormal fontSearch" placeholder="Inserisci la Partita IVA" required='true' />
                                            </div>
                                            <div className="d-flex justify-content-center mt-3">
                                                <input style={{ fontSize: '20px', fontWeight: '700' }} onClick={() => { getName() }} type="submit" value="Analizza" className="styleButton btn btn-primary buttonRadius px-4" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p style={{ fontSize: '20px' }} className="text-center mb-5 px-2 px-xl-0">La soluzione perfetta per una <strong>valutazione completa dell’azienda</strong> </p>
                                <div className="mb-5 pb-5 mb-sm-4 pb-sm-4">
                                    <WhiteLineComponent className={'mb-5 mb-sm-4'} size={'326px'} color='#fff' />
                                </div>
                            </div>
                        </div>
                    </BackgroundComponent>
                </>
            );
        }
    }
}
export default Search