import React, { useState } from "react";
import { CloseButton, Container, OverlayTrigger, Popover, PopoverBody, PopoverHeader } from "react-bootstrap";
import TitleComponent from "./TitleComponent";
import WhiteLineComponent from "./WhiteLineComponent";
import ButtonComponent from "./ButtonComponent";
import dataPiani from './../assets/data/Faq/faqPiani.json'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InfoIconOutlined from '@mui/icons-material/InfoOutlined';

export default function BoxConfrontaPiani() {

    const [open, setOpen] = useState([false, false, false, false, false, false, false, false])
    const [openMobileFree, setOpenMobileFree] = useState([false, false, false, false, false, false, false, false])
    const [openMobileStarter, setOpenMobileStarter] = useState([false, false, false, false, false, false, false, false])
    const [openMobilePro, setOpenMobilePro] = useState([false, false, false, false, false, false, false, false])

    const changeState = (index, state, tipo) => {
        let arrayTemp = [false, false, false, false, false, false, false, false]

        arrayTemp.splice(index, 1, state)
        switch(tipo) {
            case 'Desktop': 
                setOpen(arrayTemp)
                setOpenMobileFree([false, false, false, false, false, false, false, false])
                setOpenMobilePro([false, false, false, false, false, false, false, false])
                setOpenMobileFree([false, false, false, false, false, false, false, false])
                break;
            case 'MobileFree':
                setOpenMobileFree(arrayTemp)
                setOpenMobileFree([false, false, false, false, false, false, false, false])
                setOpenMobilePro([false, false, false, false, false, false, false, false])
                setOpen([false, false, false, false, false, false, false, false])
                break
            case 'MobileStarter':
                setOpenMobileStarter(arrayTemp)
                setOpenMobilePro([false, false, false, false, false, false, false, false])
                setOpenMobileFree([false, false, false, false, false, false, false, false])
                setOpen([false, false, false, false, false, false, false, false])
                break;
            case 'MobilePro':
                setOpenMobilePro(arrayTemp)
                setOpenMobileStarter([false, false, false, false, false, false, false, false])
                setOpenMobileFree([false, false, false, false, false, false, false, false])
                setOpen([false, false, false, false, false, false, false, false])
                break;
            default: 
                break;
        }
    }

    const tableData = dataPiani.map((p, index) => {
        return (
            <>
                <TableRow>
                    <TableCell className={`${(index + 1) === dataPiani.length ? 'removeBorder tableCellDashed' : 'tableCellDashed'}`}>
                        {p.titolo}
                        <OverlayTrigger
                            trigger={'click'}
                            key={'right'}
                            placement="right"
                            show={open[index]}
                            overlay={(props) => {
                                return (
                                    <Popover {...props} >
                                        <PopoverHeader>
                                            <div className="d-flex flex-row align-items-center">
                                                <div>
                                                    {p.titolo}
                                                </div>
                                                <div className="ms-auto">
                                                    <CloseButton onClick={() => changeState(index, !open[index], 'Desktop')} style={{fontSize: '14px'}} />
                                                </div>
                                            </div>
                                        </PopoverHeader>
                                        <PopoverBody dangerouslySetInnerHTML={{__html : p.body}} />
                                    </Popover>
                                )
                            }}
                        >
                            <IconButton
                                aria-label="expand row"
                                size="small"
                            >
                                <InfoIconOutlined onClick={() => changeState(index, !open[index], 'Desktop')} style={{color: '#87CEFA', fontSize: 'small'}} />
                            </IconButton>
                        </OverlayTrigger>
                    </TableCell>
                    <TableCell className={`${(index + 1) === dataPiani.length ? 'removeBorder tableCellDashed' : 'tableCellDashed'}`} style={{background: `${(index + 1) === dataPiani.length ? 'linear-gradient(180deg, rgba(94, 220, 248, 0.2) 0%, rgba(94, 220, 248, 0.2) 25%, rgba(217, 217, 217, 0) 100%)' : 'rgba(94, 220, 248, 0.2)'}`, width: '200px'}}>
                        <div className="d-flex justify-content-center bg-transparent align-items-center">
                            <label style={{ fontSize: '18px' }} className="text-center"><i style={{ fontSize: '20px', color: '#12BCA8' }} class={`${p.servizi.free.split(' ')[1] !== 'Illimitato' ? 'bi bi-check2-circle' : 'bi bi-bi bi-infinity' }`}></i><br /><label dangerouslySetInnerHTML={{ __html: p.servizi.free }} /></label>
                        </div>
                    </TableCell>
                    <TableCell style={{width: '25px'}} className={`${(index + 1) === dataPiani.length ? 'removeBorder removePadding2' : ' removePadding2 tableCellDashed'}`} />
                    <TableCell className={`${(index + 1) === dataPiani.length ? 'removeBorder tableCellDashed' : 'tableCellDashed'}`} style={{ background: `${(index + 1) === dataPiani.length ? 'linear-gradient(180deg, rgba(57, 121, 246, 0.2) 0%, rgba(57, 121, 246, 0.2) 25%, rgba(217, 217, 217, 0) 100%)' : 'rgba(57, 121, 246, 0.2)'}`, width: '200px' }}>
                        <div className="w-100 d-flex bg-transparent justify-content-center">
                            <label style={{ fontSize: '18px' }} className='text-center'><i style={{ fontSize: '20px', color: '#3979F6' }} class={`${p.servizi.starter.split(' ')[1] !== 'Illimitato' ? 'bi bi-check2-circle' : 'bi bi-bi bi-infinity' }`}></i><br /><label dangerouslySetInnerHTML={{__html : p.servizi.starter}} /></label>
                        </div>
                    </TableCell>
                    <TableCell style={{width: '25px'}} className={`${(index + 1) === dataPiani.length ? 'removeBorder removePadding2' : ' removePadding2 tableCellDashed'}`} />
                    <TableCell className={`${(index + 1) === dataPiani.length ? 'removeBorder tableCellDashed' : 'tableCellDashed'}`} style={{ background: `${(index + 1) === dataPiani.length ? 'linear-gradient(180deg, rgba(32, 86, 192, 0.2) 0%, rgba(32, 86, 192, 0.2) 25%, rgba(217, 217, 217, 0) 100%)' : 'rgba(32, 86, 192, 0.2)'}`, width: '200px' }}>
                        <div className="w-100 d-flex bg-transparent justify-content-center">
                            <label style={{ fontSize: '18px' }} className='text-center'><i style={{ fontSize: '20px', color: '#3979F6' }} class="bi bi-bi bi-infinity"></i><br /><label dangerouslySetInnerHTML={{__html : p.servizi.pro}} /></label>
                        </div>
                    </TableCell>
                </TableRow>
            </>
        )
    })

    const responsiveInfoPianiFree = dataPiani.map((p, index) => {
        return (
            <div className="pe-4 ps-4">
                <div className="d-flex flex-row align-items-center" style={(index + 1) === dataPiani.length ? {} : { borderBottom: '1px dashed #6A6A6A' }}>
                    <div className="d-flex flex-row">
                        <div>
                            <label style={{ fontSize: '18px' }}>{p.titolo}</label>
                        </div>
                        <div>
                        <OverlayTrigger
                            trigger={'click'}
                            key={'right'}
                            placement="bottom"
                            show={openMobileFree[index]}
                            overlay={(props) => {
                                return (
                                    <Popover {...props} >
                                        <PopoverHeader>
                                            <div className="d-flex flex-row align-items-center">
                                                <div>
                                                    {p.titolo}
                                                </div>
                                                <div className="ms-auto">
                                                    <CloseButton onClick={() => changeState(index, !openMobileFree[index], 'MobileFree')} style={{fontSize: '12px'}} />
                                                </div>
                                            </div>
                                        </PopoverHeader>
                                        <PopoverBody dangerouslySetInnerHTML={{__html : p.body}} />
                                    </Popover>
                                )
                            }}
                        >
                            <IconButton
                                aria-label="expand row"
                                size="small"
                            >
                                <InfoIconOutlined onClick={() => changeState(index, !openMobileFree[index], 'MobileFree')} style={{color: '#87CEFA', fontSize: 'small'}} />
                            </IconButton>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="ms-auto py-2">
                        <div className=" d-flex align-items-center">
                            <label style={{ fontSize: '18px' }} className="text-end"><i style={{ fontSize: '20px', color: '#12BCA8' }} class={`${p.servizi.free.split(' ')[1] !== 'Illimitato' ? 'bi bi-check2-circle' : 'bi bi-bi bi-infinity' }`}></i><br /><label dangerouslySetInnerHTML={{ __html: p.servizi.free }} /></label>
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    const responsiveInfoPianiStarter = dataPiani.map((p, index) => {
        return (
            <div className="pe-4 ps-4">
                <div className="d-flex flex-row align-items-center" style={(index + 1) === dataPiani.length ? {} : { borderBottom: '1px dashed #6A6A6A' }}>
                <div className="d-flex flex-row">
                        <div>
                            <label style={{ fontSize: '18px' }}>{p.titolo}</label>
                        </div>
                        <div>
                        <OverlayTrigger
                            trigger={'click'}
                            key={'right'}
                            placement="bottom"
                            show={openMobileStarter[index]}
                            overlay={(props) => {
                                return (
                                    <Popover {...props} >
                                        <PopoverHeader>
                                            <div className="d-flex flex-row align-items-center">
                                                <div>
                                                    {p.titolo}
                                                </div>
                                                <div className="ms-auto">
                                                    <CloseButton onClick={() => changeState(index, !openMobileStarter[index], 'MobileStarter')} style={{fontSize: '14px'}} />
                                                </div>
                                            </div>
                                        </PopoverHeader>
                                        <PopoverBody dangerouslySetInnerHTML={{__html : p.body}} />
                                    </Popover>
                                )
                            }}
                        >
                            <IconButton
                                aria-label="expand row"
                                size="small"
                            >
                                <InfoIconOutlined onClick={() => changeState(index, !openMobileStarter[index], 'MobileStarter')} style={{color: '#87CEFA', fontSize: 'small'}} />
                            </IconButton>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="ms-auto py-2">
                        <div className=" d-flex align-items-center">
                            <label style={{ fontSize: '18px' }} className="text-end"><i style={{ fontSize: '20px', color: '#3979F6' }} class={`${p.servizi.starter.split(' ')[1] !== 'Illimitato' ? 'bi bi-check2-circle' : 'bi bi-bi bi-infinity' }`}></i><br /><label dangerouslySetInnerHTML={{ __html: p.servizi.starter }} /></label>
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    const responsiveInfoPianiPro = dataPiani.map((p, index) => {
        return (
            <div className="pe-4 ps-4">
                <div className="d-flex flex-row align-items-center" style={(index + 1) === dataPiani.length ? {} : { borderBottom: '1px dashed #6A6A6A' }}>
                <div className="d-flex flex-row">
                        <div>
                            <label style={{ fontSize: '18px' }}>{p.titolo}</label>
                        </div>
                        <div>
                        <OverlayTrigger
                            trigger={'click'}
                            key={'right'}
                            placement="bottom"
                            show={openMobilePro[index]}
                            overlay={(props) => {
                                return (
                                    <Popover {...props} >
                                        <PopoverHeader>
                                            <div className="d-flex flex-row align-items-center">
                                                <div>
                                                    {p.titolo}
                                                </div>
                                                <div className="ms-auto">
                                                    <CloseButton onClick={() => changeState(index, !openMobilePro[index], 'MobilePro')} style={{fontSize: '14px'}} />
                                                </div>
                                            </div>
                                        </PopoverHeader>
                                        <PopoverBody dangerouslySetInnerHTML={{__html : p.body}} />
                                    </Popover>
                                )
                            }}
                        >
                            <IconButton
                                aria-label="expand row"
                                size="small"
                            >
                                <InfoIconOutlined onClick={() => changeState(index, !openMobilePro[index], 'MobilePro')} style={{color: '#87CEFA', fontSize: 'small'}} />
                            </IconButton>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="ms-auto py-2">
                        <div className=" d-flex align-items-center">
                            <label style={{ fontSize: '18px' }} className="text-end"><i style={{ fontSize: '20px', color: '#3979F6' }} class={`${p.servizi.pro.split(' ')[1] !== 'Illimitato' ? 'bi bi-check2-circle' : 'bi bi-bi bi-infinity' }`}></i><br /><label dangerouslySetInnerHTML={{ __html: p.servizi.pro }} /></label>
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    return (
        <>
            <Container className="py-5 mb-5 mt-1">
                <TableContainer className="px-5 py-5 d-none d-xxl-block tableContainer" component={Paper}>
                    <Table>
                        <TableHead>
                            <TableCell className="tableHeader">
                                <TitleComponent style={{ lineHeight: '50px', padding: '16px 32px 16px 32px' }} className={'titleComponent'} text={'<strong>Confronta</strong> <br/>i piani'} />
                                <WhiteLineComponent style={{padding: '16px 32px 16px 32px'}} className={'justify-content-xl-start mt-4 mb-5'} color={'#2269F4'} size={'157px'} height='4' />
                            </TableCell>
                            <TableCell className="tableHeader" style={{ background: 'linear-gradient(to bottom, #fff 0%, #fff 39%, rgba(94, 220, 248, 0.2) 52%, rgba(94, 220, 248, 0.2) 100%)', borderTopRightRadius: '31.5px', borderTopLeftRadius: '31.5px' }}>
                                <label className="w-100 py-2 px-5 text-center" style={{ border: '3px solid #12BCA8', color: '#12BCA8', borderRadius: '31.5px', fontSize: '24px', backgroundColor: 'white' }}><strong>Free</strong></label>
                            </TableCell>
                            <TableCell className="removeBorder" />
                            <TableCell className="tableHeader" style={{ background: 'linear-gradient(to bottom, #fff 0%, #fff 39%, rgba(57, 121, 246, 0.2) 52%, rgba(57, 121, 246, 0.2) 100%)', borderTopRightRadius: '31.5px', borderTopLeftRadius: '31.5px' }}>
                                <label className="px-5 py-2 bg-white w-100 text-center" style={{ border: '3px solid #3979F6', color: '#3979F6', borderRadius: '31.5px', fontSize: '24px' }}><strong>Starter</strong></label>
                            </TableCell>
                            <TableCell className="removeBorder" />
                            <TableCell className="tableHeader" style={{ background: 'linear-gradient(to bottom, #fff 0%, #fff 39%, rgba(32, 86, 192, 0.2) 52%, rgba(32, 86, 192, 0.2) 100%)', borderTopRightRadius: '31.5px', borderTopLeftRadius: '31.5px' }}>
                                <label className="px-5 py-2 bg-white w-100 text-center" style={{ border: '3px solid #3979F6', color: '#3979F6', borderRadius: '31.5px', fontSize: '24px' }}><strong>Pro</strong></label>
                            </TableCell>
                        </TableHead>
                        <TableBody>
                            {tableData}
                            <TableRow>
                                <TableCell className={`removeBorder removePadding`} />
                                <TableCell className={`removeBorder removePadding`} style={{width: '200px'}}>
                                    <div className="w-100">
                                        <ButtonComponent handleClick={() => { window.location.href = 'https://data.pmiagile.com/' }} className={'w-100 py-2 mt-4 fs-5 stylePianiFree'} text={'<strong>Registrati</strong>'} />
                                    </div>
                                </TableCell>
                                <TableCell className={`removeBorder removePadding2 `} />
                                <TableCell className={`removeBorder removePadding`} style={{width: '200px' }}>
                                    <div className="w-100">
                                        <ButtonComponent handleClick={() => { window.location.href = 'https://data.pmiagile.com/' }} className={'w-100 py-2 mt-4 fs-5 roboto stylePianiStarter'} text={'9,99€ / mese'} />
                                    </div>
                                </TableCell>
                                <TableCell className={`removeBorder removePadding2 `} />
                                <TableCell className={`removeBorder removePadding`} style={{width: '200px' }}>
                                    <div className="w-100">
                                        <ButtonComponent handleClick={() => { window.location.href = 'https://data.pmiagile.com/' }} className={'w-100 py-2 mt-4 fs-5 roboto stylePianiPro'} text={'29,99€ / mese'} />
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="d-block d-xxl-none">
                    <div className="d-flex justify-content-center">
                        <TitleComponent style={{ lineHeight: '50px' }} className={'titleComponent text-center'} text={'<strong>Confronta</strong> <br/>i piani'} />
                    </div>
                    <div className="d-flex justify-content-center">
                        <WhiteLineComponent className={'justify-content-center mt-4 mb-5'} color={'#2269F4'} size={'157px'} height='4' />
                    </div>
                    <div style={{ background: 'linear-gradient(180deg, rgba(18, 188, 168, 0.2) 0%, rgba(217, 217, 217, 0) 100%)' }} className="mt-4 mb-4 w-100">
                        <div className="d-flex justify-content-center">
                            <label className="px-5 py-2 text-center" style={{ border: '3px solid #12BCA8', color: '#12BCA8', borderRadius: '31.5px', fontSize: '24px', backgroundColor: 'white', position: 'relative', bottom: '25px' }}><strong>Free</strong></label>
                        </div>
                        {responsiveInfoPianiFree}
                        <div className="d-flex justify-content-center">
                            <ButtonComponent handleClick={() => { window.location.href = 'https:data.pmiagile.com/' }} className={'px-5 py-2 mt-3 mb-4 fs-5'} colorText={'#12BCA8'} text={'<strong>Registrati</strong>'} colorBackgroundButton='white' colorBorderButton={'#12BCA8'} />
                        </div>
                    </div>
                    <div style={{ background: 'linear-gradient(180deg, rgba(57, 121, 246, 0.2) 0%, rgba(217, 217, 217, 0) 100%)' }} className="mt-5">
                        <div className="d-flex justify-content-center">
                            <label className="px-5 py-2 text-center" style={{ border: '3px solid #3979F6', color: '#3979F6', borderRadius: '31.5px', fontSize: '24px', backgroundColor: 'white', position: 'relative', bottom: '25px' }}><strong>Starter</strong></label>
                        </div>
                        {responsiveInfoPianiStarter}
                        <div className="d-flex justify-content-center">
                            <ButtonComponent handleClick={() => { window.location.href = 'https:data.pmiagile.com/' }} className={'px-5 py-2 mt-3 fs-5 roboto'} colorText={'#3979F6'} text={'29,99€ / mese'} colorBackgroundButton='white' colorBorderButton={'#3979F6'} />
                        </div>
                    </div>
                    <div style={{ background: 'linear-gradient(180deg, rgba(32, 86, 192, 0.2) 0%, rgba(217, 217, 217, 0) 100%)' }} className="mt-5">
                        <div className="d-flex justify-content-center">
                            <label className="px-5 py-2 text-center" style={{ border: '3px solid #2056C0', color: '#2056C0', borderRadius: '31.5px', fontSize: '24px', backgroundColor: 'white', position: 'relative', bottom: '25px' }}><strong>Pro</strong></label>
                        </div>
                        {responsiveInfoPianiPro}
                        <div className="d-flex justify-content-center">
                            <ButtonComponent handleClick={() => { window.location.href = 'https:data.pmiagile.com/' }} className={'px-5 py-2 mt-3 fs-5 roboto'} colorText={'#2056C0'} text={'29,99€ / mese'} colorBackgroundButton='white' colorBorderButton={'#3979F6'} />
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}