import React from "react";
import Footer from "../components/Footer";
import Header from "../components/NavbarComponent";
import logoPMI from '../assets/logos/logo-pmi.png'
import { useEffect } from "react";
import { Helmet } from 'react-helmet'
import backgroundCR from '../assets/images/backgroundCR.png'
import RischiZero from "../components/RischiZero";
import RivolgiamoCR from "../components/RivolgiamoCR";
import TextVideoComponent from '../components/TextVideoComponent'
import BoxAnalisiCR from "../components/BoxAnalisiCR";
import AnimatedNumberComponent from "../components/AnimatedNumberComponent";
import backgroundAnimated from '../assets/images/backgroundAnimatedCR.png'
import dataCRNumber from '../assets/data/AnimatedNumber/numberCR.json'
import SubTitleComponent from "../components/SubTitleComponent";
import FaqComponent from "../components/FaqComponent";
import faq from '../assets/data/Faq/faqHome.json'
import HeaderComponentImage from "../components/HeaderComponentImage";
import imgHeaderComponent from '../assets/images/CR/mockupHeader.png'
import imgHeaderComponentMobile from '../assets/images/CR/mockupHeaderMobile.png'

function AnalisiCR(props){
    useEffect(() => {
        if(localStorage.getItem('partitaIVA') !== null){
            localStorage.removeItem('partitaIVA')
        }
    })
        return(
            <>
                <Helmet>
                    <title>Analisi Centrale Rischi</title>
                    <meta property="og:url" content="https://www.pmiagile.com/analisi-cr" />
                    <meta
                    name="description"
                    content="Grazie al nostro software Analisi Centrale Rischi potrai caricare il file PDF della Centrale Rischi di Banca d’Italia ed ottenere grafici chiari e di facili comprensione"
                    />
                </Helmet>
                <Header link='/' logo={logoPMI} />
                <HeaderComponentImage direction="" backgroundImage={backgroundCR} imgSrc={imgHeaderComponent} imgSrcMobile={imgHeaderComponentMobile} variation={'primary'} textTitolo={'<strong>Analisi <br/>Centrale Rischi</strong>'} textSubTitolo='Il tool di analisi essenziale per avere consapevolezza della <strong>propria storia creditizia</strong> e per la <strong>gestione della finanza</strong>' textButton={'<strong>Prova gratuitamente</strong>'} onClick={() => {window.location.href = 'https://data.pmiagile.com'}} />
                <RischiZero titolo={'<strong>Rischi zero <br/>con PMI Agile!</strong>'} subTitolo='Grazie al nostro software Analisi CR le aziende avranno ben chiare le azioni da intraprendere <strong>per migliorare i propri risultati</strong>' />
                <RivolgiamoCR textTitolo={'<strong>A chi ci rivolgiamo</strong>'} />
                <TextVideoComponent classNameTitle="titleComponentVideoCR" className={'backgroundVideoCR'} classNameContainer={'spacingContainerCR'} radius={'20px'} labelTutorial={<label style={{color: '#7021AE'}} className="mt-3 mt-xxl-5">Guarda questo tutorial</label>} backgroundColor={'#EFF3FF'} container={true} path={'https://pmiagile-sito-assets.s3.eu-south-1.amazonaws.com/video_4.mp4'} align='start' reverse={true} titolo='<strong>Ottieni tutte le risposte in pochi secondi</strong>' />
                <BoxAnalisiCR />
                <AnimatedNumberComponent width="75px" height="75px" titolo={<SubTitleComponent style={{fontSize:'32px'}} className={'text-center my-4'} text={'<strong>I vantaggi</strong>'} />} data={dataCRNumber} backgroundImage={backgroundAnimated} />
                <FaqComponent data={faq} />
                {/* <Video video="https://pmiagile-sito-assets.s3.eu-south-1.amazonaws.com/video_4.mp4" title="Scopri l'Analisi Centrale Rischi di PMI Agile" title2='ed ottieni in pochi secondi tutte le risposte utili per la tua azienda' />
                <ResponseSecond />
                <UploadCR />
                <FaqBannerCR /> */}
                <Footer />
            </>
        )
} export default AnalisiCR