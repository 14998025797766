import React from "react";
import pmi from '../assets/logos/logo_new.png'
import certificato from '../assets/images/certificato.png'
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import BackgroundComponent from "./BackgroundComponent";
import backgroundFooter from '../assets/images/backgroundFooter.png'
import FaqComponent from './FaqComponent'
import dataFooterMobile from '../assets/data/Faq/footer.json'

function Footer() {
    return (
        <>
            <BackgroundComponent imageBackground={backgroundFooter}>

                <MDBFooter className='sectionFooter footer text-center text-lg-start text-muted'>
                    <section className='p-4 border-bottom d-none d-xl-block'>
                        <MDBContainer className='text-center text-md-start mt-5'>
                            <MDBRow className='mt-3 d-flex justify-content-center'>
                                <MDBCol xl='2' className='mb-4 d-xl-block d-md-flex justify-content-sm-center align-items-center'>
                                    <MDBRow className="d-flex justify-content-center">
                                        <MDBCol sm='12' className="d-flex justify-content-center">
                                            <a href="/"><img src={pmi} alt="" style={{ maxWidth: '145px' }} className="logo-footerz img-fluid" /></a>
                                        </MDBCol>
                                        <MDBCol sm='12' className="d-flex  justify-content-center justify-content-xl-start">
                                            <a href='https://pmiagile-sito-assets.s3.eu-south-1.amazonaws.com/certificateVHR.pdf' target='_blank' rel="noreferrer" ><img src={certificato} style={{ minHeight: '45px', maxHeight: '50px' }} alt="" className="logo-footerz img-fluid mt-5 mt-md-0 mt-xl-5 mb-3" /></a>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                                <MDBCol xl='10' className='mb-4'>
                                    <MDBRow className="d-flex justify-content-end">
                                        <MDBCol xl='3' className="mb-5 mb-md-0 ps-5">
                                            <p className="text-dark"><strong>navigazione</strong></p>
                                            <p>
                                                <a href='/' className='text-reset text-decoration-none'>
                                                    home
                                                </a>
                                            </p>
                                            <p>
                                                <a href='/azienda' className='text-reset text-decoration-none'>
                                                    azienda
                                                </a>
                                            </p>
                                            <p>
                                                <a href='/piani' className='text-reset text-decoration-none'>
                                                    piani
                                                </a>
                                            </p>
                                            <p>
                                                <a href='/enterprise' className='text-reset text-decoration-none'>
                                                    enterprise
                                                </a>
                                            </p>
                                            {/* <p style={{ marginTop: '10px' }} className="footer-p mt-6">
                                                <a href="https://it.linkedin.com/company/pmi-agile" target='_blank' rel='noreferrer' ><i class="bi bi-linkedin" style={{ color: '#2269F4', paddingRight: '10px' }}></i></a>
                                                <a href="https://ms-my.facebook.com/people/Pmi-Agile/100083614260097/" target='_blank' rel='noreferrer' ><i class="bi bi-facebook" style={{ color: '#2269F4', paddingRight: '10px' }}></i></a>
                                                <a href="https://www.instagram.com/pmi_agile/?hl=it" target='_blank' rel='noreferrer' ><i class="bi bi-instagram" style={{ color: '#2269F4', paddingRight: '10px' }}></i></a>
                                                <a href="https://www.tiktok.com/login?redirect_url=https%3A%2F%2Fwww.tiktok.com%2F%40pmi_agile&lang=en&enter_method=mandatory" target='_blank' rel='noreferrer' ><i class="bi bi-tiktok" style={{ color: '#2269F4', paddingRight: '10px' }}></i></a>
                                                <i class="bi bi-youtube" style={{ color: '#2269F4', paddingRight: '10px' }}></i>
                                            </p> */}
                                            <p>
                                                <a href='/' className='text-reset text-decoration-none'>
                                                    contenuti
                                                </a>
                                            </p>

                                        </MDBCol>
                                        <MDBCol xl='2' className="mb-5 mb-md-0">
                                            <p className="text-dark"><strong>supporto</strong></p>
                                            <p>
                                                <a href='/academy' className='text-reset text-decoration-none'>
                                                    academy
                                                </a>
                                            </p>
                                            <p>
                                                <a href='/faq' className='text-reset text-decoration-none'>
                                                    faq
                                                </a>
                                            </p>
                                        </MDBCol>
                                        <MDBCol xl='4' className="mb-5 mb-md-0 ps-5">
                                            <p className="text-dark"><strong>dove trovarci</strong></p>
                                            <p className="footer-p" style={{fontSize: '12px'}}>
                                                <i class="bi bi-geo-alt" style={{ color: '#2269F4', paddingRight: '10px' }}></i>
                                                <a href="https://www.google.it/maps/place/Viale+Andrea+Doria,+7,+20124+Milano+MI/@45.4854109,9.2058765,17z/data=!3m1!4b1!4m5!3m4!1s0x4786c6db5a6d1e81:0xa14dddd5940d3b31!8m2!3d45.4854072!4d9.2080652" target='_blank' rel="noreferrer" style={{ textDecoration: 'none', fontSize: '12px' }} className='text-reset'>
                                                    viale andrea doria 7, milano
                                                </a>
                                            </p>
                                            <p className="footer-p" style={{fontSize: '12px'}}>
                                                <i class="bi bi-geo-alt" style={{ color: '#2269F4', paddingRight: '10px' }}></i>
                                                <a href="https://www.google.it/maps/place/Via+Isonzo,+70,+52100+Arezzo+AR/@43.457767,11.8760702,17z/data=!3m1!4b1!4m5!3m4!1s0x132bed5d56daa8f5:0xe8f802675af108fc!8m2!3d43.4577631!4d11.8782589" target='_blank' rel="noreferrer" style={{ textDecoration: 'none', fontSize: '12px' }} className='text-reset'>
                                                    via isonzo 70, arezzo
                                                </a>
                                            </p>
                                            <p className="text-dark"><strong>contatti</strong></p>
                                            <p className="footer-p" style={{fontSize: '12px'}}>
                                                <i class="bi bi-envelope" style={{ color: '#2269F4', paddingRight: '10px' }}></i>
                                                <a href="mailto: info@pmiagile.com" style={{ textDecoration: 'none' }}>info@pmiagile.com</a>
                                            </p>
                                            <p className="footer-p" style={{fontSize: '12px'}}>
                                                <i class="bi bi-envelope" style={{ color: '#2269F4', paddingRight: '10px' }}></i>
                                                <label>PEC: </label> <a href="mailto: pmiagil@legalmail.it" style={{ textDecoration: 'none' }}>pmiagile@legalmail.it</a>
                                            </p>
                                        </MDBCol>
                                        <MDBCol xl='3' className="mb-5 mb-md-0 ps-3">
                                            <p className="text-dark"><strong>seguici su</strong></p>
                                            <p style={{ marginTop: '10px' }} className="footer-p mt-6">
                                                <a href="https://it.linkedin.com/company/pmi-agile" target='_blank' rel='noreferrer' ><i class="bi bi-linkedin" style={{ color: '#2269F4', paddingRight: '10px' }}></i></a>
                                                <a href="https://ms-my.facebook.com/people/Pmi-Agile/100083614260097/" target='_blank' rel='noreferrer' ><i class="bi bi-facebook" style={{ color: '#2269F4', paddingRight: '10px' }}></i></a>
                                                <a href="https://www.instagram.com/pmi_agile/?hl=it" target='_blank' rel='noreferrer' ><i class="bi bi-instagram" style={{ color: '#2269F4', paddingRight: '10px' }}></i></a>
                                                <a href="https://www.tiktok.com/login?redirect_url=https%3A%2F%2Fwww.tiktok.com%2F%40pmi_agile&lang=en&enter_method=mandatory" target='_blank' rel='noreferrer' ><i class="bi bi-tiktok" style={{ color: '#2269F4', paddingRight: '10px' }}></i></a>
                                                <a className="px-1" href="https://www.youtube.com/@pmiagile3973" target='_blank' rel='noreferrer'><i class="bi bi-youtube" style={{ color: '#2269F4' }}></i></a>
                                            </p>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </section>
                    <section className='px-2 border-bottom d-block d-xl-none'>
                        <MDBContainer className='text-start pt-3'>
                            <MDBRow className='mt-3 d-flex justify-content-center'>
                                <MDBCol sm="12" xl='2' className='mb-4 d-flex justify-content-center align-items-center'>
                                    <a href="/"><img src={pmi} alt="" style={{ maxWidth: '145px' }} className="logo-footerz img-fluid" /></a>
                                </MDBCol>
                                <MDBCol sm="12" xl='10' className='mb-4'>
                                    <FaqComponent footer={false} imageBackground="" data={dataFooterMobile} checkTitle='' border="footerFaq" />
                                </MDBCol>
                                <MDBCol sm={'12'}>
                                    <p className="text-center"><strong>seguici su</strong></p>
                                    <p style={{ marginTop: '10px' }} className="footer-p mt-6 text-center d-flex justify-content-center">
                                        <a className="px-1" href="https://it.linkedin.com/company/pmi-agile" target='_blank' rel='noreferrer' ><i class="bi bi-linkedin" style={{ color: '#2269F4' }}></i></a>
                                        <a className="px-1" href="https://ms-my.facebook.com/people/Pmi-Agile/100083614260097/" target='_blank' rel='noreferrer' ><i class="bi bi-facebook" style={{ color: '#2269F4' }}></i></a>
                                        <a className="px-1" href="https://www.instagram.com/pmi_agile/?hl=it" target='_blank' rel='noreferrer' ><i class="bi bi-instagram" style={{ color: '#2269F4' }}></i></a>
                                        <a className="px-1" href="https://www.tiktok.com/login?redirect_url=https%3A%2F%2Fwww.tiktok.com%2F%40pmi_agile&lang=en&enter_method=mandatory" target='_blank' rel='noreferrer' ><i class="bi bi-tiktok" style={{ color: '#2269F4' }}></i></a>
                                        <a className="px-1" href="https://www.youtube.com/@pmiagile3973" target='_blank' rel='noreferrer'><i class="bi bi-youtube" style={{ color: '#2269F4' }}></i></a>
                                    </p>
                                </MDBCol>
                                <MDBCol className='d-flex justify-content-center' sm="12" xl='10'>
                                    <a href='https://pmiagile-sito-assets.s3.eu-south-1.amazonaws.com/certificateVHR.pdf' target='_blank' rel="noreferrer" ><img src={certificato} style={{ minHeight: '45px', maxHeight: '50px' }} alt="" className="logo-footerz img-fluid mt-2 mb-3" /></a>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </section>
                    <div className='text-center py-4 px-2'>
                        <a href="https://www.pmiagile.com" className="text-muted" >PMI Agile</a> <label> - Partita IVA / CF: 12122690964 | </label> <a className="text-muted" href="https://www.iubenda.com/privacy-policy/20180571" rel="noreferrer" target="_blank">Privacy Policy</a> - <a  className="text-muted" href="https://www.iubenda.com/privacy-policy/20180571/cookie-policy" rel="noreferrer" target="_blank">Cookie Policy</a> - <a  className="text-muted" href="https://pmiagile-sito-assets.s3.eu-south-1.amazonaws.com/T%26C+PMI+Agile.pdf" rel="noreferrer" target="_blank">Terms & Conditions</a> <label > - © 2022 PMI Agile - All rights reserved</label>
                    </div>
                </MDBFooter>
            </BackgroundComponent>
        </>
    );
}
export default Footer