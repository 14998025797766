import React from "react";
import Header from "../components/NavbarComponent";
import Footer from "../components/Footer";
import { useEffect } from "react";
import { Helmet } from 'react-helmet'
import HeaderComponentImage from '../components/HeaderComponentImage'
import backgroundHeader from '../assets/images/backgroundHeaderBenchmark.png'
import mockupHeader from '../assets/images/benchmark/mockupHeader.png'
import AnimatedNumberComponent from '../components/AnimatedNumberComponent'
import dataNumber from '../assets/data/AnimatedNumber/numberBenchmark.json'
import BoxConfrontaBenchmark from "../components/BoxConfrontaBenchmark";
import BoxVideoBenchmark from "../components/BoxVideoBenchmark";
import FaqComponent from '../components/FaqComponent'
import dataFaq from '../assets/data/Faq/faqHome.json'

function AnalisiBenchmark(props) {
    useEffect(() =>{
        if(localStorage.getItem('partitaIVA') !== null){
            localStorage.removeItem('partitaIVA')
        }
    })
        return(
            <>
                <Helmet>
                    <title>Analisi Benchmark</title>
                    <meta property="og:url" content="https://www.pmiagile.com/analisi-benchmark" />
                    <meta
                    name="description"
                    content="Confronta i dati di bilancio di qualsiasi azienda, ottieni un’analisi approfondita dei dati economico-finanziari e patrimoniali dei tuoi concorrenti."
                    />
                </Helmet>
                <Header />
                <HeaderComponentImage imgSrc={mockupHeader} imgSrcMobile={mockupHeader} textButton={'<strong>Prova gratuitamente</strong>'} onClick={() => {window.location.href = 'https://data.pmiagile.com/'}} backgroundImage={backgroundHeader} textTitolo={'<strong>Analisi Benchmark</strong>'} textSubTitolo='Lo strumento perfetto per confrontare<br/> <strong>oltre 5 milioni di aziende</strong> e posizionare il tuo business' />
                <AnimatedNumberComponent container={false} data={dataNumber} fontSizeTitolo='generalTitle' backgroundColor={'#F1F1F1'} />
                <BoxConfrontaBenchmark />
                <BoxVideoBenchmark />
                <FaqComponent data={dataFaq} />
                <Footer />
            </>
        )
} export default AnalisiBenchmark