import React from "react";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

function StampaChartPIVA(props) {
  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
    const labels = [
        "Totale Immobilizzazioni", 
        'Totale Valore Produzione',
        'Totale Debiti',
        'PFN Ebitda',
        'Totale Fondi Rischi',
        'Attivo Circolante',
        'Ratei Riscontri',
        'Ebitda',
        'PFN',
        'Utile',
        'Debiti Breve',
        'Debiti Lungo',
        'Dipendenti',
        'Ebitda/Vendite',
        'Ebitda Fatturato',
        'Fatturato',
        'Oneri Finanziari Ebitda',
        'PN',
        'Totale PN Passivo',
        'TFR',
    ];
    const data = {
    labels,
    datasets: [
      {
        label: 'Media Settore',
        data: [
            parseFloat(props.totale),
            parseFloat(props.tot_val_produzione),
            parseFloat(props.totale_debiti),
            parseFloat(props.pfn_ebitda),
            parseFloat(props.totale_fondi_rischi),
            parseFloat(props.attivo_circolante),
            parseFloat(props.ratei_risconti),
            parseFloat(props.ebitda),
            parseFloat(props.pfn),
            parseFloat(props.utile),
            parseFloat(props.debiti_breve),
            parseFloat(props.debiti_lungo),
            parseFloat(props.dipendenti),
            parseFloat(props.ev),
            parseFloat(props.ebitda_fatturato),
            parseFloat(props.fatturato),
            parseFloat(props.oneri_fin_ebitda),
            parseFloat(props.pn),
            parseFloat(props.pn_tot_passivo),
            parseFloat(props.tfr)
        ],
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Partita IVA',
        data: [
            parseFloat(props.totaleIVA),
            parseFloat(props.tot_val_produzioneIVA),
            parseFloat(props.totale_debitiIVA),
            parseFloat(props.pfn_ebitdaIVA),
            parseFloat(props.totale_fondi_rischiIVA),
            parseFloat(props.attivo_circolanteIVA),
            parseFloat(props.ratei_riscontiIVA),
            parseFloat(props.ebitdaIVA),
            parseFloat(props.pfnIVA),
            parseFloat(props.utileIVA),
            parseFloat(props.debiti_breveIVA),
            parseFloat(props.debiti_lungoIVA),
            parseFloat(props.dipendentiIVA),
            parseFloat(props.evIVA),
            parseFloat(props.ebitda_fatturatoIVA),
            parseFloat(props.fatturatoIVA),
            parseFloat(props.oneri_fin_ebitdaIVA),
            parseFloat(props.pnIVA),
            parseFloat(props.pn_tot_passivoIVA),
            parseFloat(props.tfrIVA)
        ],
        backgroundColor: 'rgba(126, 127, 93, 0.5)',
      },
  ]
  };

  // config 
  const config = {
    type: 'bar',
    data,
    options: {
      scales: {
        y: {
          beginAtZero: true
        }
      }
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <div className="box-bar mt-5 mb-5">
          <div className="container mb-5 ">
          <Bar options={config} data={data} />
          </div>
        </div>
      </div>
    </>
  )
}
export default StampaChartPIVA