import React from "react";

function Card({ icona, titolo, text, style, className, classNameSubTitolo, styleTitolo = {fontSize: '20px'}, styleSubTitolo = {fontSize: '16px'}, classNameTitolo, backgroundIcona = 'bg-white', border = '1px solid #C5C5C5', width = '74px', height = '74px' }) {
    return (
        <>
            <div style={style} className={className}>
                <div>
                    <div className="d-flex justify-content-center pb-2">
                        <div className={`${backgroundIcona} d-flex justify-content-center align-items-center`} style={window.location.pathname !== '/search' ? { borderRadius: '50px', border: border, width: width, height: height } : {}}>
                            <img src={require('../assets/images/Card/' + icona)} alt={icona} />
                        </div>
                    </div>
                    <div className={`d-flex justify-content-center ${window.location.pathname !== '/search' ? '' : ''}`}>
                        <label className={`text-center ${classNameTitolo}`} style={styleTitolo} dangerouslySetInnerHTML={{ __html: titolo }} />
                    </div>
                    <div className={`d-flex justify-content-center ${window.location.pathname !== '/search' ? '' : ''}`}>
                        <label className={`text-center ${classNameSubTitolo}`} style={styleSubTitolo} dangerouslySetInnerHTML={{ __html: text }} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Card