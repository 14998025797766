import React from "react";
import TitleComponent from './TitleComponent'
import SubTitleComponent from './SubTitleComponent'
import BackgroundComponent from "./BackgroundComponent";
import { Container } from "react-bootstrap";
import ButtonComponent from "./ButtonComponent";

export default function HeaderComponent({checkButton, classNameButton, textButton, onClick, titleStyle = 'titleComponent', classNameSubTitle, textTitle, textSub, backgroundImage, colorText, backgroundColor = 'linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)', align = 'center' }) {
    return (
        <>
            <BackgroundComponent className={`pt-4 pt-xl-5`} backgroundSize={'cover'} backgroundColor={`${backgroundColor}, url(${backgroundImage})`} imageBackground={backgroundImage}>
                <Container>
                    <div className="px-0 pt-5 pb-0 pb-xl-5 my-5">
                        <div className={`d-flex justify-content-${align}`}>
                            <TitleComponent className={`text-${align} pt-3 px-0 px-xl-0 ${titleStyle}`} text={textTitle} align='start' style={{color: colorText}} />
                        </div>
                        {
                            window.location.pathname !== '/contenuti' ? <div className={`d-flex justify-content-${align} mt-4 mb-4`}>
                            <SubTitleComponent text={textSub}  className={`text-${align} ${classNameSubTitle} pt-2 pb-xl-3 pb-3 py-5 subTitleComponent ${window.location.pathname !== '/azienda' && window.location.pathname !== '/enterprise' ? 'generalSubTitle' : 'generalSubTitleFull'}`} colorText={colorText} />
                        </div> : null
                        }
                        {
                            checkButton ? 
                            <>
                                <div className="d-flex justify-content-center"><ButtonComponent text={textButton} className={classNameButton} handleClick={onClick} /></div>
                            </>
                            : null
                        }
                    </div>
                </Container>
            </BackgroundComponent>
        </>
    )
}