import React from "react";
import BackgroundComponent from "./BackgroundComponent";
import { Row, Col, Image } from "react-bootstrap";
import TitleComponent from "./TitleComponent";
import SubTitleComponent from "./SubTitleComponent";
import ButtonComponent from "./ButtonComponent";

export default function HeaderComponentImage({ textTitolo, textSubTitolo, onClick, textButton, variation, imgSrc, direction = 'flex-row', backgroundImage, imgSrcMobile }) {
    return (
        <>
            <BackgroundComponent className={'py-5 py-xl-5 w-100'} imageBackground={backgroundImage}>
                    <Row className={`px-0 px-lg-3 py-5 d-flex d-xl-none`}>
                        <Col xl='7' className="d-flex justify-content-center">
                            <div className="headerWidth d-flex justify-content-center px-2">
                                <div className="d-block">
                                    <div className="d-flex justify-content-center justify-content-sm-start mb-3">
                                        <TitleComponent text={textTitolo} className='text-center text-sm-start titleComponent' />
                                    </div>
                                    <SubTitleComponent text={textSubTitolo} className='text-center px-1 text-sm-start subTitleComponent mb-4' />
                                    <div className="d-flex justify-content-center justify-content-sm-start mb-4">
                                        <ButtonComponent handleClick={onClick} text={textButton} className={''} typeBootstrapButton={variation} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl='5' className="d-flex justify-content-center">
                            <Image src={imgSrcMobile} alt={textTitolo.replaceAll(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '')} style={{width: '80%'}} className='px-2' />
                        </Col>
                    </Row>
                    <Row className={`d-xl-flex ${direction} align-items-center py-5 d-none`}>
                        <Col xl='6' className="d-flex justify-content-xl-center justify-content-start">
                            <div className="headerWidth mb-4">
                                <div className="d-flex justify-content-start mb-3">
                                    <TitleComponent text={textTitolo} className='text-start titleComponent' />
                                </div>
                                <SubTitleComponent text={textSubTitolo} className='text-start subTitleComponent mb-4' />
                                <ButtonComponent handleClick={onClick} text={textButton} typeBootstrapButton={variation} />
                            </div>
                        </Col>
                        <Col xl='5' className="d-flex justify-content-center ms-auto pe-5 me-5">
                            <Image src={imgSrc} alt={textTitolo.replaceAll(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '')} className='img-fluid px-3 px-xxl-0' />
                        </Col>
                    </Row>
            </BackgroundComponent>
        </>
    )
}