import React from "react";
import { Col, Row } from "react-bootstrap";
import BackgroundComponent from "./BackgroundComponent";
import ButtonComponent from "./ButtonComponent";
import imageBackground from '../assets/images/backgroundVelocita.png'
import tickCircle from '../assets/images/Home/tickcircle.png'
import SubTitleComponent from "./SubTitleComponent";
import TitleComponent from "./TitleComponent";

function BetterBusiness() {
    return (
        <>
            <BackgroundComponent className={'py-4 py-xl-5'} imageBackground={imageBackground}>
                <div className="sectionAB">
                    <div className="container">
                        <div className="pb-0 pb-xl-5 px-2 px-xl-0">
                            <div className="d-flex justify-content-center">
                                <TitleComponent className={`text-center pt-3 px-0 px-xl-0 titleComponent`} text={'<strong>Semplice, veloce e conveniente</strong>'} />
                            </div>
                            <SubTitleComponent  className='text-center pt-2 pb-xl-3 pb-0 px-0 px-xl-0 subTitleComponent' style={{fontSize: '22px'}} text={'La nostra missione è quella di organizzare i tuoi dati ed <br/>esporli in analisi facili da capire ed ancor più facili e veloci da fare.'} />
                        </div>
                        <div className="row d-flex align-items-center pb-3 px-2 px-xl-0">
                            <div className="bg-white col-xl-5 mb-3 mb-xl-0 boxBetterBusiness">
                                <div className="card py-4">
                                    <div className="card-body d-flex align-items-center justify-content-center">
                                        <div className="d-block">
                                            <SubTitleComponent  className='text-center pt-2 pb-xl-3 pb-0 px-0 px-xl-0' style={{fontSize: '22px'}} text={'Un potente alleato per migliorare il tuo business'} />
                                            <div className="d-flex justify-content-center">
                                                <ButtonComponent className={'fontButton'} text={'Attiva una prova gratuita'} colorBackgroundButton={'#2269f5'} colorBorderButton={'#2269f5'} typeBootstrapButton={'primary'} handleClick={() => window.location.href = 'https://data.pmiagile.com/'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-7 bg-white mt-2 mt-xl-0" style={{ borderRadius: '20px' }}>
                                <div className="py-4 card">
                                    <div className="card-body ms-0 ms-lg-4 mt-4 mt-xl-2">
                                        <div className="d-flex flex-row mx-0 mx-xl-5 mt-2">
                                            <div className="me-3"><img src={tickCircle} alt='tickcircle' /></div><p style={{ fontSize: '1rem' }} className="  text-secondary">Analisi completa dei risultati economici dei tuoi concorrenti</p>
                                        </div>
                                        <div className="d-flex flex-row mx-0 mx-xl-5">
                                            <div className="me-3"><img src={tickCircle} alt='tickcircle' /></div><p style={{ fontSize: '1rem' }} className="  text-secondary">Panoramica della salute finanziaria delle aziende</p>
                                        </div>
                                        <div className="d-flex flex-row mx-0 mx-xl-5">
                                            <div className="me-3"><img src={tickCircle} alt='tickcircle' /></div><p style={{ fontSize: '1rem' }} className="  text-secondary">Acquisisci informazioni per orientare le tue scelte di investimento</p>
                                        </div>
                                        <div className="d-flex flex-row mx-0 mx-xl-5">
                                            <div className="me-3"><img src={tickCircle} alt='tickcircle' /></div><p style={{ fontSize: '1rem' }} className="  text-secondary">Risparmio di tempo e facilità d'uso</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Row className="py-5 d-flex justify-content-center align-items-center">
                            <Col md='4' className="d-block d-xl-none">
                                <p className="text-center" style={{ fontSize: '56px' }}>Scopri <strong><br />PMI Agile</strong></p>
                            </Col>
                            <Col md='8' className="d-flex justify-content-center align-items-center">
                                <video className='img-fluid' alt="videoImg" autoPlay controls loop muted style={{ width: '640px' }} >
                                    <source src="https://pmiagile-sito-assets.s3.eu-south-1.amazonaws.com/video_1.mp4" />
                                </video>
                            </Col>
                            <Col md='4' className="d-none d-xl-block">
                                <div className="d-flex justify-content-center align-items-center">
                                    <p style={{ fontSize: '56px' }}>Scopri <strong><br />PMI Agile</strong></p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </BackgroundComponent>
        </>
    )
} export default BetterBusiness