import React from "react";
import { Container } from "react-bootstrap";
import BackgroundComponent from "./BackgroundComponent";
import ButtonComponent from "./ButtonComponent";
import SubTitleComponent from "./SubTitleComponent";
import TitleComponent from "./TitleComponent";

export default function BoxTitleSubTitle({ textTitle, classNameTitle, styleTitle, textSubTitle, classNameSubTitle, styleSubTitle, className, checkButton, textButton, typeBootstrapButton, colorBackgroundButton, colorBorderButton, onClick, backgroundImage, radius, classNameContainerTitle = 'justify-content-center' }) {
    return (
        <>
            <BackgroundComponent className={'mb-3 pb-3 mb-xl-0 pb-xl-0'} imageBackground={backgroundImage} radius={radius}>
                <Container className={className}>
                    <div className={`d-flex ${classNameContainerTitle} mb-3`}>
                        <TitleComponent text={textTitle} className={`text-center titleComponent ${classNameTitle}`} />
                    </div>
                    <div className="d-flex justify-content-center">
                        <SubTitleComponent text={textSubTitle} className={`text-center ${window.location.pathname !== '/analisi-benchmark' ? 'subTitleComponent' : 'fontSizeDesc'} ${classNameSubTitle}`} />
                    </div>
                    {
                        checkButton ?
                            <>
                                <div className="d-flex"><ButtonComponent text={textButton} typeBootstrapButton={typeBootstrapButton} colorBackgroundButton={colorBackgroundButton} colorBorderButton={colorBackgroundButton} handleClick={onClick} /></div>
                            </> :
                            null
                    }
                </Container>
            </BackgroundComponent>
        </>
    )
}