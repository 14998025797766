import config from './../../../.config.json'
import React, { Component } from "react";
import LoadingResults from "../results/LoadingResults";
import StampaMercato from './View/StampaMercato';
import StampaMercatoBar from './View/StampaMercatoBar';
import StampaChart from './View/StampaChart';
class Mercato extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            item: [],
        }
    }

    componentDidMount() {
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': config.RESULTS,
            }
        }
        fetch("https://6kcj3trvik.execute-api.eu-south-1.amazonaws.com/v1/get_ateco_avg?ateco_cat=" + getCatAteco() + "&ateco_no=" + getAtecoNum() + "&ateco_range=" + getAtecoRange(), options)
            .then(res => res.json())
            .then((result) => {
                this.setState({
                    isLoaded: true,
                    item: result
                });
            },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    })
                })
    }


    render() {
        const items = this.state.item
        const error = this.state.error
        const isLoaded = this.state.isLoaded
        
        if(error) {
            return <div>Error: {error.message}</div>
        } else if (!isLoaded) {
            return <div className="colorBackground"><div className="d-flex justify-content-center align-items-center spinner"><LoadingResults /></div></div>
        } else {
            if (items.message === 'Missing required request parameters: [ateco_range]') {
                if(window.location.search !== ''){
                    return (
                        <>
                            <div class="position-absolute top-0 end-0">
                                <div class="alert alert-danger alert-dismissible fade show mt-3 me-3" role="alert">
                                    Range ateco non impostato!!!
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                            </div>
                        </>
                    );
                }
            } else {
                return stampa(items)
            }
        }
    }
} export default Mercato

function stampa(data) {
    return (
        <>

            <div className="colorBackground" >
                <div className="container pt-5">
                    <button type="button" class="btn btn-outline-primary" onClick={() => {
                        document.getElementsByName('bar')[0].classList.add('d-none')
                        document.getElementsByName('cards')[0].classList.add('d-block')
                        document.getElementsByName('bar')[0].classList.remove('d-block')
                        document.getElementsByName('cards')[0].classList.remove('d-none')
                        document.getElementsByName('chart')[0].classList.add('d-none')
                        document.getElementsByName('chart')[0].classList.remove('d-block')
                    }}>Card view</button>  
                    <button type="button" class="btn btn-outline-primary" onClick={() => {
                        document.getElementsByName('bar')[0].classList.remove('d-none')
                        document.getElementsByName('cards')[0].classList.remove('d-block')
                        document.getElementsByName('bar')[0].classList.add('d-block')
                        document.getElementsByName('cards')[0].classList.add('d-none')
                        document.getElementsByName('chart')[0].classList.add('d-none')
                        document.getElementsByName('chart')[0].classList.remove('d-block')
                    }}>Bar view</button>
                    <button type="button" class="btn btn-outline-primary" onClick={() => {
                        document.getElementsByName('bar')[0].classList.remove('d-block')
                        document.getElementsByName('cards')[0].classList.remove('d-block')
                        document.getElementsByName('bar')[0].classList.add('d-none')
                        document.getElementsByName('cards')[0].classList.add('d-none')
                        document.getElementsByName('chart')[0].classList.add('d-block')
                        document.getElementsByName('chart')[0].classList.remove('d-none')
                    }}>Chart view</button>
                </div>
                <h1 className='text-center mt-5'>Analisi di Mercato</h1>
                <div name='bar' className='d-none'>
                    <StampaMercatoBar
                    totale={data.result.final_avg.totale_immobilizzazioni !== undefined ? addDot(data.result.final_avg.totale_immobilizzazioni) : "- - -"}
                    tot_val_produzione={data.result.final_avg.tot_val_produzione !== undefined ? addDot(data.result.final_avg.tot_val_produzione) : "- - -"}
                    totale_debiti={data.result.final_avg.totale_debiti !== undefined ? addDot(data.result.final_avg.totale_debiti) : "- - -"}
                    pfn_ebitda={data.result.final_avg.pfn_ebitda !== undefined ? addDot(data.result.final_avg.pfn_ebitda) : "- - -"}
                    totale_fondi_rischi={data.result.final_avg.totale_fondi_rischi !== undefined ? addDot(data.result.final_avg.totale_fondi_rischi) : "- - -"}
                    attivo_circolante={data.result.final_avg.attivo_circolante !== undefined ? addDot(data.result.final_avg.attivo_circolante) : "- - -"}
                    ratei_risconti={data.result.final_avg.ratei_risconti !== undefined ? addDot(data.result.final_avg.ratei_risconti) : "- - -"}
                    ebitda={data.result.final_avg.ebitda !== undefined ? addDot(data.result.final_avg.ebitda) : "- - -"}
                    pfn={data.result.final_avg.pfn !== undefined ? addDot(data.result.final_avg.pfn) : "- - -"}
                    utile={data.result.final_avg.utile !== undefined ? addDot(data.result.final_avg.utile) : "- - -"}
                    debiti_breve={data.result.final_avg.debiti_breve !== undefined ? addDot(data.result.final_avg.debiti_breve) : "- - -"}
                    debiti_lungo={data.result.final_avg.debiti_lungo !== undefined ? addDot(data.result.final_avg.debiti_lungo) : "- - -"}
                    dipendenti={data.result.final_avg.dipendenti !== undefined ? addDot(data.result.final_avg.dipendenti) : "- - -"}
                    ev={data.result.final_avg["ebitda/vendite"] !== undefined ? addDot(data.result.final_avg["ebitda/vendite"]) : "- - -"}
                    ebitda_fatturato={data.result.final_avg.ebitda_fatturato !== undefined ? addDot(data.result.final_avg.ebitda_fatturato) : "- - -"}
                    fatturato={data.result.final_avg.fatturato !== undefined ? addDot(data.result.final_avg.fatturato) : "- - -"}
                    oneri_fin_ebitda={data.result.final_avg.oneri_fin_ebitda !== undefined ? addDot(data.result.final_avg.oneri_fin_ebitda) : "- - -"}
                    pn={data.result.final_avg.pn !== undefined ? addDot(data.result.final_avg.pn) : "- - -"}
                    pn_tot_passivo={data.result.final_avg.pn_tot_passivo !== undefined ? addDot(data.result.final_avg.pn_tot_passivo) : "- - -"}
                    tfr={data.result.final_avg.tfr !== undefined ? addDot(data.result.final_avg.tfr) : "- - -"}
                    />
                </div>
                <div name='cards'>
                    <StampaMercato totale={data.result.final_avg.totale_immobilizzazioni !== undefined ? addDot(data.result.final_avg.totale_immobilizzazioni) : "- - -"}
                        tot_val_produzione={data.result.final_avg.tot_val_produzione !== undefined ? addDot(data.result.final_avg.tot_val_produzione) : "- - -"}
                        totale_debiti={data.result.final_avg.totale_debiti !== undefined ? addDot(data.result.final_avg.totale_debiti) : "- - -"}
                        pfn_ebitda={data.result.final_avg.pfn_ebitda !== undefined ? addDot(data.result.final_avg.pfn_ebitda) : "- - -"}
                        totale_fondi_rischi={data.result.final_avg.totale_fondi_rischi !== undefined ? addDot(data.result.final_avg.totale_fondi_rischi) : "- - -"}
                        attivo_circolante={data.result.final_avg.attivo_circolante !== undefined ? addDot(data.result.final_avg.attivo_circolante) : "- - -"}
                        ratei_risconti={data.result.final_avg.ratei_risconti !== undefined ? addDot(data.result.final_avg.ratei_risconti) : "- - -"}
                        ebitda={data.result.final_avg.ebitda !== undefined ? addDot(data.result.final_avg.ebitda) : "- - -"}
                        pfn={data.result.final_avg.pfn !== undefined ? addDot(data.result.final_avg.pfn) : "- - -"}
                        utile={data.result.final_avg.utile !== undefined ? addDot(data.result.final_avg.utile) : "- - -"}
                        debiti_breve={data.result.final_avg.debiti_breve !== undefined ? addDot(data.result.final_avg.debiti_breve) : "- - -"}
                        debiti_lungo={data.result.final_avg.debiti_lungo !== undefined ? addDot(data.result.final_avg.debiti_lungo) : "- - -"}
                        dipendenti={data.result.final_avg.dipendenti !== undefined ? addDot(data.result.final_avg.dipendenti) : "- - -"}
                        ev={data.result.final_avg["ebitda/vendite"] !== undefined ? addDot(data.result.final_avg["ebitda/vendite"]) : "- - -"}
                        ebitda_fatturato={data.result.final_avg.ebitda_fatturato !== undefined ? addDot(data.result.final_avg.ebitda_fatturato) : "- - -"}
                        fatturato={data.result.final_avg.fatturato !== undefined ? addDot(data.result.final_avg.fatturato) : "- - -"}
                        oneri_fin_ebitda={data.result.final_avg.oneri_fin_ebitda !== undefined ? addDot(data.result.final_avg.oneri_fin_ebitda) : "- - -"}
                        pn={data.result.final_avg.pn !== undefined ? addDot(data.result.final_avg.pn) : "- - -"}
                        pn_tot_passivo={data.result.final_avg.pn_tot_passivo !== undefined ? addDot(data.result.final_avg.pn_tot_passivo) : "- - -"}
                        tfr={data.result.final_avg.tfr !== undefined ? addDot(data.result.final_avg.tfr) : "- - -"}
                    />
                </div>
                <div name='chart' className='d-none'>
                    <StampaChart
                    totale={data.result.final_avg.totale_immobilizzazioni !== undefined ? data.result.final_avg.totale_immobilizzazioni : "0"}
                    tot_val_produzione={data.result.final_avg.tot_val_produzione !== undefined ? data.result.final_avg.tot_val_produzione : "0"}
                    totale_debiti={data.result.final_avg.totale_debiti !== undefined ? data.result.final_avg.totale_debiti : "0"}
                    pfn_ebitda={data.result.final_avg.pfn_ebitda !== undefined ? data.result.final_avg.pfn_ebitda : "0"}
                    totale_fondi_rischi={data.result.final_avg.totale_fondi_rischi !== undefined ? data.result.final_avg.totale_fondi_rischi : "0"}
                    attivo_circolante={data.result.final_avg.attivo_circolante !== undefined ? data.result.final_avg.attivo_circolante: "0"}
                    ratei_risconti={data.result.final_avg.ratei_risconti !== undefined ? data.result.final_avg.ratei_risconti : "0"}
                    ebitda={data.result.final_avg.ebitda !== undefined ? data.result.final_avg.ebitda : "0"}
                    pfn={data.result.final_avg.pfn !== undefined ? data.result.final_avg.pfn : "0"}
                    utile={data.result.final_avg.utile !== undefined ? data.result.final_avg.utile : "0"}
                    debiti_breve={data.result.final_avg.debiti_breve !== undefined ? data.result.final_avg.debiti_breve : "0"}
                    debiti_lungo={data.result.final_avg.debiti_lungo !== undefined ? data.result.final_avg.debiti_lungo : "0"}
                    dipendenti={data.result.final_avg.dipendenti !== undefined ? data.result.final_avg.dipendenti: "0"}
                    ev={data.result.final_avg["ebitda/vendite"] !== undefined ? data.result.final_avg["ebitda/vendite"] : "0"}
                    ebitda_fatturato={data.result.final_avg.ebitda_fatturato !== undefined ? data.result.final_avg.ebitda_fatturato : "0"}
                    pn={data.result.final_avg.pn !== undefined ? data.result.final_avg.pn: "0"}
                    pn_tot_passivo={data.result.final_avg.pn_tot_passivo !== undefined ? data.result.final_avg.pn_tot_passivo : "0"}
                    tfr={data.result.final_avg.tfr !== undefined ? data.result.final_avg.tfr : "0"}/>
                </div>
            </div>
        </>
    );
}
function addDot(number) {
    return new Intl.NumberFormat('de-DE').format(parseFloat(number));
}
function getCatAteco() {
    let q = "";
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString)
    q = urlParams.get('ateco_cat');
    if (q == null) {
        return ""
    } else {
        return q;
    }
}

function getAtecoNum() {
    let q = "";
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString)
    q = urlParams.get('ateco_no');
    if (q == null) {
        return ""
    } else {
        return q;
    }
}

function getAtecoRange() {
    let q = "";
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString)
    q = urlParams.get('ateco_range');
    if (q == null) {
        return ""
    } else {
        return q;
    }
}