
import React, { useState } from "react";
import Footer from "../components/Footer";
import CarouselPMI from "../components/CarouselPMI";
import NavbarComponent from "../components/NavbarComponent";
import logoPMI from '../assets/logos/logo-pmi.png'
import { useEffect } from "react";
import { Helmet } from 'react-helmet'
import HeaderComponent from '../components/HeaderComponent'
import backgroundHeader from '../assets/images/backgroundHeaderEnterprise.png'
import TextImageComponent from '../components/TextImageComponent'
import dataTextEnterprise from '../assets/data/TextImage/textImageEnterprise.json'
import BoxSloganComponent from '../components/BoxSloganComponent'
import AnimatedNumberComponent from '../components/AnimatedNumberComponent'
import backgroundEnterpriseCol from '../assets/images/backgroundEnterpriseCol.png'
import dateEnterprise from '../assets/data/AnimatedNumber/numberEnterprise.json'
import backgroundPmiEnterprise from '../assets/images/backgroundPmiEnterprise.png'
import dataPmiEnterprise from '../assets/data/TextImage/textImagePmiEnterprise.json'
import BoxComeFunzionaEnterprise from "../components/BoxComeFunzionaEnterprise";
import dataBeneficiCard from '../assets/data/Card/Enterprise/dataBeneficiCard.json'
import backgroundCircular from '../assets/images/backgroundCircular.png'
import TextVideoComponent from "../components/TextVideoComponent";
import BoxCitationComponent from "../components/BoxCitationComponent";
import TextButtonComponent from "../components/TextButtonComponent";
import HubspotForm from "../components/HubspotForm";


function VirtualCFO(){

    const [show, setShow] = useState(false)

    useEffect(() => {
        if(localStorage.getItem('partitaIVA') !== null){
            localStorage.removeItem('partitaIVA')
        }
    })
        return(
            <>
                <Helmet>
                    <title>Enterprise</title>
                    <meta property="og:url" content="https://www.pmiagile.com/enterprise" />
                    <meta
                    name="description"
                    content="PMI Agile Enterprise permette di monitorare l'azienda a 360° in base alle proprie esigenze, sfruttando, l'enorme mole di dati da un'unica piattaforma, grazie ad algoritmi di Intelligenza Artificiale"
                    />
                </Helmet>
                <NavbarComponent link='/' logo={logoPMI}/>
                <HeaderComponent checkButton={false} textButton={'Demo Gratuita'} onClick={() => { setShow(true) }} classNameButton={'btn btn-primary'} titleStyle="titleComponentEnterprise" heightBackgroundComponent={'1023px'} textTitle={'<strong>Enterprise</strong> data solutions'} textSub={'Analizza l’oggi e <br/><strong>prevedi il domani</strong>'} backgroundImage={backgroundHeader} whiteLine={true} colorLine={'#06152c'} sizeLine={'287px'} />
                <TextImageComponent margin={'ms-0 ms-xl-4'} path={require(`../assets/images/VirtualCFO/graficaTextImage.png`)} data={dataTextEnterprise} reverse={false} container={true} />
                <BoxSloganComponent align={'center'} titolo={'<strong>Come funziona</strong>'} text='Attraverso una procedura di “step-up”, distinta in 5 diverse fasi, ti rilasciamo la piattaforma costruita sulle tue esigenze e tipi di dati.' />
                <AnimatedNumberComponent colSpacing="2" width="100px" height="100px" path={'../assets/images/VirtualCFO/'} backgroundImage={backgroundEnterpriseCol} data={dateEnterprise} />
                <TextImageComponent backgroundImage={backgroundPmiEnterprise} reverse={false} clickButton={() => { setShow(true) }} container={false} data={dataPmiEnterprise} path={require('../assets/images/VirtualCFO/pmiEnterprise.png')} />
                <BoxComeFunzionaEnterprise text={'<strong>I benefici per la tua azienda</strong>'} data={dataBeneficiCard} />
                <TextVideoComponent backgroundImage={backgroundCircular} titolo='<strong>Casi di successo</strong>' body={'Abbiamo supportato molte aziende nel loro percorso di crescita e sviluppo.<br/><br/>Scopri la storia!'} checkButton={false} align='end' path={'https://pmiagile-sito-assets.s3.eu-south-1.amazonaws.com/FAMILY_GOLD_1080p.mp4'} reverse={false} container={true} />
                <BoxCitationComponent titolo={`"Prima di PMI Agile estrapolavamo i dati dai fogli di Excel facendoci perdere molto tempo.  Attraverso la piattaforma di PMI Agile ho la possibilità di avere tutti i dati giornalieri  in tempo reale permettendomi di avere la garanzia dei dati estratti e di molto più tempo a disposizione da dedicare ad altre attività utili all'azienda."`} text='<strong>Elena Nanni Founder Family Gold</strong>' align={'center'} />
                <CarouselPMI />
                <TextButtonComponent className='my-1 py-1 px-2' text={'<strong>Scopri come un progetto di Business Intelligence può cambiare il tuo modo di lavorare</strong>'} textButton='Leggi le nostre case histories' colorBackgroundButton={'linear-gradient(94.76deg, #2269F4 0%, #00EBE9 96.27%)'} colorBorderButton='transparent' onClickButton={() => {window.location.href = '/industry'}} align='center' />
                <Footer />
                <HubspotForm setShow={setShow} show={show} formId={'1f027fcd-07f8-49fc-a043-510c45b70006'} />
            </>
        )
} export default VirtualCFO