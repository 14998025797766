import React from "react";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';

function Popup(props){
    const [show, setShow] = useState(true);
    return(
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <div className="d-block">
                        <Modal.Title className="text-center" id="example-custom-modal-styling-title">{props.title}</Modal.Title>
                        <p className="text-center mt-3" style={{color: 'red'}} >{props.subtitle}</p>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <img src={props.img} alt="" className="img-fluid" />
                </Modal.Body>
            </Modal>
        </>
    );
} export default Popup