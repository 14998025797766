import React, { useState } from "react";
import pmi from '../assets/logos/logo_new.png'
import pmiSmall from '../assets/logos/small_logo_new.png'
import { Container, Image, Nav, Navbar, NavDropdown, Offcanvas, Button } from "react-bootstrap";
import ScrollTrigger from "react-scroll-trigger";
import { useNavigate } from "react-router-dom";


function NavbarComponent() {

    const [show, setShow] = useState(false)
    const [showEnterprise, setShowEnterprise] = useState(false)
    const [showEnterpriseMobile, setShowEnterpriseMobile] = useState(false)
    const [showDynamics, setShowDynamics] = useState(false)
    const navigate = useNavigate()

    function onEnterViewPort() {
        window.addEventListener('scroll', function () {
            if (this.window.scrollY < 1) {
                document.getElementById('navbarFixed').classList.remove('bg-custom-white')
                document.getElementById('navbarFixed').classList.add('bg-custom-transparent')
            }
        })
    }
    function onExitViewPort() {
        window.addEventListener('scroll', function () {
            if (this.window.scrollY >= 1) {
                document.getElementById('navbarFixed').classList.add('bg-custom-white')
                document.getElementById('navbarFixed').classList.remove('bg-custom-transparent')
            }
        })
    }

    return (
        <>
            <ScrollTrigger onEnter={onEnterViewPort} onExit={onExitViewPort}>
                <div id="navbarFixed" className="fixed-top animated bg-custom">
                    <Container fluid>
                        <Navbar expand='xxl' className={`px-xxl-5 px-0`}>
                            <Navbar.Brand onClick={() => { navigate('/') }}><Image src={pmiSmall} style={{maxWidth: '184px'}} className={`img-fluid`} /><Image src={pmi} style={{maxWidth: '184px'}} className={`img-fluid d-none d-md-inline-flex mt-3 ms-3`} /></Navbar.Brand>
                            <Button className="btn btn-primary text-white ms-auto d-block d-xxl-none" style={{ padding: '5px 20px', fontSize: '1rem' }} onClick={() => { window.location.href = 'https://data.pmiagile.com/' }}>Accedi</Button>
                            <Navbar.Toggle style={{ border: 'none' }} aria-controls="dropdownHeader" data-bs-target={'#dropdownHeader'} />
                            <Navbar.Offcanvas id="dropdownHeader" placement='end' className="w-75 mt-2 px-5 py-5 px-lg-0 py-lg-0 firstMobileMenu d-flex justify-content-end">
                                <Offcanvas.Header closeButton className="ms-auto"></Offcanvas.Header>
                                <Offcanvas.Body as="div" className="firstMobileMenu d-flex justify-content-end ms-auto">
                                    <Nav className="ms-auto">
                                        {/* <Nav.Item as="li" className="d-flex justify-content-end px-3">
                                            <Nav.Link className="text-dark" style={{ fontSize: '1.25rem' }} onClick={() => { navigate('/azienda') }}>azienda</Nav.Link>
                                        </Nav.Item> */}
                                        <Nav.Item as="li" className="d-flex justify-content-end d-block d-xxl-none px-3">
                                            <Nav.Link onClick={() => { setShow(true) }} className="text-dark" style={{ fontSize: '1.25rem' }}>prodotti</Nav.Link>
                                        </Nav.Item>
                                        <Offcanvas show={show} onHide={() => { setShow(false) }} as="div" placement='end' className="w-75 px-5 py-5 px-lg-0 py-lg-0 secondMobileMenu">
                                            <Offcanvas.Header closeButton onClick={() => { setShow(false) }} className="ms-auto"></Offcanvas.Header>
                                            <Offcanvas.Body>
                                                <Nav className="ms-auto">
                                                    <Nav.Item as="li" className="d-flex justify-content-end">
                                                        <Nav.Link className="text-dark text-end" style={{ fontSize: '1rem' }} onClick={() => { navigate('/analisi-bilancio') }}>analisi azienda</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item as="li" className="d-flex justify-content-end">
                                                        <Nav.Link className="text-dark text-end" style={{ fontSize: '1rem' }} onClick={() => { navigate('/analisi-benchmark') }}>analisi benchmark</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item as="li" className="d-flex justify-content-end">
                                                        <Nav.Link className="text-dark text-end" style={{ fontSize: '1rem' }} onClick={() => { navigate('/analisi-cr') }}>analisi cr</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Offcanvas.Body>
                                        </Offcanvas>
                                        <NavDropdown show={showDynamics} onMouseEnter={() => { setShowDynamics(true) }} onMouseLeave={() => { setShowDynamics(false) }} className="d-none d-xxl-block px-3" style={{ fontSize: '1.25rem' }} title='prodotti'>
                                            <NavDropdown.Item onClick={() => { navigate('/analisi-bilancio') }}>analisi azienda</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => { navigate('/analisi-benchmark') }}>analisi benchmark</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => { navigate('/analisi-cr') }}>analisi cr</NavDropdown.Item>
                                        </NavDropdown>
                                        <Nav.Item as="li" className="d-flex justify-content-end px-3">
                                            <Nav.Link className="text-dark" style={{ fontSize: '1.25rem' }} onClick={() => { navigate('/piani') }}>piani</Nav.Link>
                                        </Nav.Item>
                                        {/* <NavDropdown show={showEnterprise} onMouseEnter={() => setShowEnterprise(true)} onMouseLeave={() => setShowEnterprise(false)} onClick={() => { window.location.href = 'https://www.metriks.ai' }} className="d-none d-xxl-block px-3" style={{ fontSize: '1.25rem' }} title='enterprise'>
                                            <NavDropdown.Item onClick={() => { navigate('/industry') }}>industry</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => { navigate('/academy') }}>academy</NavDropdown.Item>
                                        </NavDropdown> */}
                                        <Nav.Item as="li" className="d-flex justify-content-end px-3">
                                            <Nav.Link onClick={() => { window.location.href = 'https://www.metriks.ai' }} className="text-dark" style={{ fontSize: '1.25rem' }}>enterprise</Nav.Link>
                                        </Nav.Item>
                                        {/* <Offcanvas show={showEnterpriseMobile} onHide={() => { setShowEnterpriseMobile(false) }} as="div" placement='end' className="w-75 px-5 py-5 px-lg-0 py-lg-0 secondMobileMenu">
                                            <Offcanvas.Header closeButton onClick={() => { setShowEnterpriseMobile(false) }} className="ms-auto"></Offcanvas.Header>
                                            <Offcanvas.Body>
                                                <Nav className="ms-auto">
                                                    <Nav.Item as="li" className="d-flex justify-content-end">
                                                        <Nav.Link className="text-dark" style={{ fontSize: '1.25rem' }} onClick={() => { window.location.href = 'https://www.metriks.ai' }}>enterprise</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item as="li" className="d-flex justify-content-end">
                                                        <Nav.Link className="text-dark" style={{ fontSize: '1.25rem' }} onClick={() => { navigate('/industry') }}>industry</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item as="li" className="d-flex justify-content-end">
                                                        <Nav.Link className="text-dark" style={{ fontSize: '1.25rem' }} onClick={() => { navigate('/academy') }}>academy</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Offcanvas.Body>
                                        </Offcanvas> */}
                                        {/* <Nav.Item as="li" className="d-flex justify-content-end px-3">
                                            <Nav.Link className="text-dark" style={{ fontSize: '1.25rem' }} onClick={() => { navigate('/contenuti') }}>contenuti</Nav.Link>
                                        </Nav.Item> */}
                                        <Nav.Item as="li" className="ms-3 d-none d-xxl-block">
                                            <Nav.Link className="btn btn-primary text-white mt-1" style={{ padding: '5px 20px', fontSize: '1rem' }} href="https://data.pmiagile.com/">Accedi</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Navbar>
                    </Container>
                </div>
            </ScrollTrigger>
        </>
    )

}
export default NavbarComponent