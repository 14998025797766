import React from "react";
import Header from "../components/NavbarComponent";
import Search from "../components/Search";
import ProvaFreeBanner from "../components/ProvaFreeBanner";
import Footer from "../components/Footer";
import { useEffect } from "react";
import BetterBusiness from "../components/BetterBusiness";
import {Popup} from "../components/general";
import errorImage from '../assets/images/error-image/error-response.svg'
import { Helmet } from 'react-helmet'
import backgroundImageSearch from '../assets/images/backgroundSearch.png'
import dataAnalisi from '../assets/data/Home/cardAnalisi.json'
import FaqComponent from "../components/FaqComponent";
import faqHome from '../assets/data/Faq/faqHome.json'
import BoxCardHome from "../components/BoxCardHome";
import Newsletter from "../components/general/Newsletter";
import { useLocation } from "react-router-dom";

function Home(props){

    const { state } = useLocation()

    useEffect(() => {
        if(localStorage.getItem('partitaIVA') !== null){
            localStorage.removeItem('partitaIVA')
        }
    })

    if(state?.popup === undefined) {
        return(
            <>  
                <Helmet>
                    <title>Business Information per PMI</title>
                    <meta property="og:url" content="https://www.pmiagile.com/" />
                    <meta
                    name="description"
                    content="PMI Agile permette di analizzare i dati economico-finanziari e patrimoniali di milioni di aziende, grazie ad algortmi di intelligenza artificiale."
                    />
                </Helmet>
                <Header/>
                {/* <SmallSearchFixed /> */}
                <Search imageBackground={backgroundImageSearch} />
                <BoxCardHome data={dataAnalisi} />
                <BetterBusiness />
                <FaqComponent data={faqHome} />
                <ProvaFreeBanner/>
                {/*<BlogBanner backgroundBlog={backgroundBlog} />*/}
                <Newsletter />
                <Footer/>
            </>
        );
    } else {
        return(
            <>  
                <Helmet>
                    <title>Home - PMI Agile</title>
                    <meta property="og:url" content="https://www.pmiagile.com/" />
                    <meta
                    name="description"
                    content="La soluzione perfetta per una valutazione completa dell’azienda"
                    />
                </Helmet>
                <Header/>
                <Popup img={errorImage} title='Non è presente nessun bilancio pubblico per questa P.IVA' subtitle='Attenzione: qualora il Codice fiscale differisca dalla P.IVA, inserire il codice fiscale nella barra di ricerca ' />
                {/* <SmallSearchFixed /> */}
                <Search imageBackground={backgroundImageSearch} />
                <BoxCardHome data={dataAnalisi} />
                <BetterBusiness />
                <FaqComponent data={faqHome} />
                <ProvaFreeBanner/>
                {/*<BlogBanner backgroundBlog={backgroundBlog} />*/}
                <Newsletter />
                <Footer/>
            </>
        );
    }
}
export default Home