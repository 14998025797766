import React from "react";
import ButtonComponent from "./ButtonComponent";
import SubTitleComponent from "./SubTitleComponent";

export default function TextButtonComponent({ text, textButton, colorBackgroundButton, colorBorderButton, onClickButton, align, fontSize, className, typeBootstrapButton }) {
    return (
        <>
            <div className={`d-flex justify-content-center ${className}`}>
                <div className={className} style={{ maxWidth: '827px' }}>
                    <div className={`d-flex justify-content-${align}`} >
                        <SubTitleComponent className='text-center pt-2 pb-xl-3 pb-0 px-0 px-xl-0 subTitleComponent' style={{ fontSize: fontSize }} text={text} />
                    </div>
                    <div className={`d-flex justify-content-${align} pb-5 mt-4 mt-xl-0`}>
                        <ButtonComponent text={textButton} handleClick={onClickButton} colorBackgroundButton={colorBackgroundButton} colorBorderButton={colorBorderButton} typeBootstrapButton={typeBootstrapButton} />
                    </div>
                </div>
            </div>
        </>
    )
}