import React from "react";
import BackgroundComponent from "./BackgroundComponent";
import backgroundBolle from '../assets/images/backgroundBolle.png'
import backgroundBolleMobile from '../assets/images/backgroundBolleMobile.png'
import { Row, Col, Container } from "react-bootstrap";
import TitleComponent from "./TitleComponent";
import SubTitleComponent from "./SubTitleComponent";
import Card from './Card'

export default function RischiZero({ titolo, subTitolo }) {
    return (
        <>
            <div className="d-none d-xxl-block">
                <BackgroundComponent imageBackground={backgroundBolle}>
                    <Container>
                        <div className="w-100 d-flex justify-content-center py-5 containerRischiZero">
                            <Row className='d-flex'>
                                <Col sm='12' xxl='6' className="d-flex justify-content-center justify-content-xxl-start textRischiZero">
                                    <div className="d-block px-4">
                                        <TitleComponent text={titolo} className='titleComponent text-center text-xxl-start d-flex justify-content-center justify-content-xxl-start' />
                                        <SubTitleComponent text={subTitolo} className='subTitleComponent text-center text-xxl-start mt-4 pe-xxl-5 pe-0 px-0 px-xl-3 px-xxl-0' />
                                    </div>
                                </Col>
                                <Col sm='12' xxl='6' className="d-none d-xxl-block">
                                    <Card border="" backgroundIcona="bg-transparent" className={'d-flex justify-content-center align-items-center graficiCard'} classNameSubTitolo='my-2 px-4' classNameTitolo={'my-2'} style={{ width: '320px', height: '320px', borderRadius: '20px', background: 'linear-gradient(151.81deg, rgba(255, 255, 255, 0.4) -2.68%, rgba(255, 255, 255, 0.1) 102.33%)', boxShadow: '0px 4px 33px rgba(0, 0, 0, 0.2)' }} icona={'graph.png'} titolo={'<strong>Grafici</strong>'} text='In un istante tutti i dati presenti nella Centrale Rischi di Banca d’Italia saranno convertiti in grafici chiari e comprensibili' />
                                    <Card border="" backgroundIcona="bg-transparent" className={'d-flex justify-content-center align-items-center analisiCard'} classNameSubTitolo='my-2 px-4' classNameTitolo={'my-2'} style={{ width: '320px', height: '320px', borderRadius: '20px', background: 'linear-gradient(151.81deg, rgba(255, 255, 255, 0.4) -2.68%, rgba(255, 255, 255, 0.1) 102.33%)', boxShadow: '0px 4px 33px rgba(0, 0, 0, 0.2)' }} icona={'searchnormal1.png'} titolo={'<strong>Analisi</strong>'} text='Otterrai un’elaborazione semplice ed interattiva dei principali indicatori presenti nella Centrale Rischi. Conoscerai nel minimo dettaglio tutte le informazioni sulla tua posizione economica' />
                                    <Card border="" backgroundIcona="bg-transparent" className={'d-flex justify-content-center align-items-center alertCard'} classNameSubTitolo='my-2 px-4' classNameTitolo={'my-2'} style={{ width: '475px', height: '250px', borderRadius: '20px', background: 'linear-gradient(151.81deg, rgba(255, 255, 255, 0.4) -2.68%, rgba(255, 255, 255, 0.1) 102.33%)', boxShadow: '0px 4px 33px rgba(0, 0, 0, 0.2)' }} icona={'danger.png'} titolo={'<strong>Alert</strong>'} text='Sarà presente un sistema di alert su tutti gli indicatori ottenibili dall’analisi Centrale Rischi (eccessiva concentrazione su un istituto, presenza di sofferenza, presenza di insoluti, ecc.)' />
                                </Col>
                                <Col sm='12' xxl='6' className="d-block d-xxl-none px-5 mt-3">
                                    <Row>
                                        <Col xl={'4'}>
                                            <div className="d-flex justify-content-center">
                                                <Card border="" backgroundIcona="bg-transparent" className={'d-flex mt-3 mt-xl-0 mb-5 mb-xxl-0 justify-content-center align-items-center align-items-xl-top align-items-xxl-center graficiCard'} classNameSubTitolo='my-2 px-4' classNameTitolo={'my-2'} style={{ borderRadius: '20px', background: 'linear-gradient(151.81deg, rgba(255, 255, 255, 0.4) -2.68%, rgba(255, 255, 255, 0.1) 102.33%)', boxShadow: '0px 4px 33px rgba(0, 0, 0, 0.2)' }} icona={'graph.png'} titolo={'<strong>Grafici</strong>'} text='In un istante tutti i dati presenti nella Centrale Rischi di Banca d’Italia saranno convertiti in grafici chiari e comprensibili' />
                                            </div>
                                        </Col>
                                        <Col xl={'4'}>
                                            <div className="d-flex justify-content-center">
                                                <Card border="" backgroundIcona="bg-transparent" className={'d-flex mb-5 mb-xxl-0 justify-content-center align-items-center analisiCard'} classNameSubTitolo='my-2 px-4' classNameTitolo={'my-2'} style={{ borderRadius: '20px', background: 'linear-gradient(151.81deg, rgba(255, 255, 255, 0.4) -2.68%, rgba(255, 255, 255, 0.1) 102.33%)', boxShadow: '0px 4px 33px rgba(0, 0, 0, 0.2)' }} icona={'searchnormal1.png'} titolo={'<strong>Analisi</strong>'} text='Otterrai un’elaborazione semplice ed interattiva dei principali indicatori presenti nella Centrale Rischi. Conoscerai nel minimo dettaglio tutte le informazioni sulla tua posizione economica' />
                                            </div>
                                        </Col>
                                        <Col xl={'4'}>
                                            <div className="d-flex justify-content-center">
                                                <Card border="" backgroundIcona="bg-transparent" className={'d-flex justify-content-center align-items-center alertCard'} classNameSubTitolo='my-2 px-4' classNameTitolo={'my-2'} style={{ borderRadius: '20px', background: 'linear-gradient(151.81deg, rgba(255, 255, 255, 0.4) -2.68%, rgba(255, 255, 255, 0.1) 102.33%)', boxShadow: '0px 4px 33px rgba(0, 0, 0, 0.2)' }} icona={'danger.png'} titolo={'<strong>Alert</strong>'} text='Sarà presente un sistema di alert su tutti gli indicatori ottenibili dall’analisi Centrale Rischi (eccessiva concentrazione su un istituto, presenza di sofferenza, presenza di insoluti, ecc.)' />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </BackgroundComponent>
            </div>
            <div className="d-flex d-xxl-none">
                <BackgroundComponent imageBackground={backgroundBolleMobile}>
                    <div className="w-100 d-flex justify-content-center py-5 my-5 containerRischiZero">
                        <Row className='d-block'>
                            <Col sm='12' xxl='6' className="d-flex justify-content-center justify-content-xxl-start textRischiZero">
                                <div className="d-block px-4">
                                    <TitleComponent text={titolo} className='titleComponent text-center text-xxl-start d-flex justify-content-center justify-content-xxl-start' />
                                    <SubTitleComponent text={subTitolo} className='subTitleComponent text-center text-xxl-start mt-4 pe-xxl-5 pe-0 px-0 px-xl-3 px-xxl-0' />
                                </div>
                            </Col>
                            <Col sm='12' xxl='6' className="px-0 px-xl-5 px-xxl-0 mt-3">
                                <Row className="d-flex justify-content-center">
                                    <Col sm='12' xl={'4'}>
                                        <div className="d-flex justify-content-center">
                                            <Card border="" backgroundIcona="bg-transparent" className={'d-flex mt-3 mt-xl-0 mb-5 mb-xxl-0 justify-content-center align-items-center align-items-xl-top align-items-xxl-center graficiCard'} classNameSubTitolo='my-2 px-4' classNameTitolo={'my-2'} style={{ borderRadius: '20px', background: 'linear-gradient(151.81deg, rgba(255, 255, 255, 0.4) -2.68%, rgba(255, 255, 255, 0.1) 102.33%)', boxShadow: '0px 4px 33px rgba(0, 0, 0, 0.2)' }} icona={'graph.png'} titolo={'<strong>Grafici</strong>'} text='In un istante tutti i dati presenti nella Centrale Rischi di Banca d’Italia saranno convertiti in grafici chiari e comprensibili' />
                                        </div>
                                    </Col>
                                    <Col sm='12' xl={'4'}>
                                        <div className="d-flex justify-content-center">
                                            <Card border="" backgroundIcona="bg-transparent" className={'d-flex mb-5 mb-xxl-0 justify-content-center align-items-center analisiCard'} classNameSubTitolo='my-2 px-4' classNameTitolo={'my-2'} style={{ borderRadius: '20px', background: 'linear-gradient(151.81deg, rgba(255, 255, 255, 0.4) -2.68%, rgba(255, 255, 255, 0.1) 102.33%)', boxShadow: '0px 4px 33px rgba(0, 0, 0, 0.2)' }} icona={'searchnormal1.png'} titolo={'<strong>Analisi</strong>'} text='Otterrai un’elaborazione semplice ed interattiva dei principali indicatori presenti nella Centrale Rischi. Conoscerai nel minimo dettaglio tutte le informazioni sulla tua posizione economica' />
                                        </div>
                                    </Col>
                                    <Col sm='12' xl={'4'}>
                                        <div className="d-flex justify-content-center">
                                            <Card border="" backgroundIcona="bg-transparent" className={'d-flex justify-content-center align-items-center alertCard'} classNameSubTitolo='my-2 px-4' classNameTitolo={'my-2'} style={{ borderRadius: '20px', background: 'linear-gradient(151.81deg, rgba(255, 255, 255, 0.4) -2.68%, rgba(255, 255, 255, 0.1) 102.33%)', boxShadow: '0px 4px 33px rgba(0, 0, 0, 0.2)' }} icona={'danger.png'} titolo={'<strong>Alert</strong>'} text='Sarà presente un sistema di alert su tutti gli indicatori ottenibili dall’analisi Centrale Rischi (eccessiva concentrazione su un istituto, presenza di sofferenza, presenza di insoluti, ecc.)' />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </BackgroundComponent>
            </div>
        </>
    )
}