import React from "react";
import SubTitleComponent from "./SubTitleComponent";
import BackgroundComponent from './BackgroundComponent'
import {Image, Row, Col } from "react-bootstrap";
import bdoLogo from './../assets/images/Azienda/bdoLogo.svg'
import sostenibilitaLogo from './../assets/images/Azienda/sostenibilita.svg'
import politecnico from './../assets/images/Azienda/poli.png'
import uniSiena from './../assets/images/Azienda/unisiena.png'

export default function CertificateComponent({ text, align, backgroundImage, imgSrc }) {
    return (
        <>
            <BackgroundComponent className={'py-5'} imageBackground={backgroundImage}>
                <div className="mx-4">
                    <div className={`d-flex justify-content-${align}`}>
                        <SubTitleComponent text={text} className={`text-${align}`} style={{fontSize: '32px'}} />
                    </div>
                    <Row className="mt-5">
                        <Col sm='12' md='4' xl className={`d-flex my-4 my-md-3 justify-content-${align}`}>
                            <div className="d-block">
                                <div className="d-flex justify-content-center">
                                    <Image className="img-src" style={{ minHeight: '45px', maxHeight: '50px' }} src={imgSrc} alt={text} />
                                </div>
                                <p className="robotoNormal mt-3 text-center">Certificati ISO 9001 EA 37</p>
                            </div>
                        </Col>
                        <Col sm='12' md='4' xl className={`d-flex my-4 my-md-3 justify-content-${align}`}>
                            <div className="d-block">
                                <div className="d-flex justify-content-center">
                                    <Image className="img-src" style={{ minHeight: '45px', maxHeight: '50px' }} src={bdoLogo} alt={text} />
                                </div>
                                <p className="robotoNormal mt-3 text-center">Bilancio 2022 Certificato BDO</p>
                            </div>
                        </Col>
                        <Col sm='12' md='4' xl className={`d-flex my-4 my-md-3 justify-content-${align}`}>
                            <div className="d-block">
                                <div className="d-flex justify-content-center">
                                    <Image className="img-src" style={{ minHeight: '45px', maxHeight: '50px' }} src={sostenibilitaLogo} alt={text} />
                                </div>
                                <p className="robotoNormal mt-3 text-center">Bilancio di Sostenibilità</p>
                            </div>
                        </Col>
                        <Col sm='12' md='6' xl className={`d-flex my-4 my-md-3 justify-content-${align}`}>
                            <div className="d-block">
                                <div className="d-flex justify-content-center">
                                    <Image className="img-src" style={{ minHeight: '45px', maxHeight: '50px' }} src={politecnico} alt={text} />
                                </div>
                                <p className="robotoNormal mt-3 text-center">Partner osservatorio "Big Data & Analytics"</p>
                            </div>
                        </Col>
                        <Col sm='12' md='6' xl className={`d-flex my-4 my-md-3 justify-content-${align}`}>
                            <div className="d-block">
                                <div className="d-flex justify-content-center">
                                    <Image className="img-src" style={{ minHeight: '45px', maxHeight: '50px' }} src={uniSiena} alt={text} />
                                </div>
                                <p className="robotoNormal mt-3 text-center">Collaborazione per Assegni di Ricerca</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </BackgroundComponent>
        </>
    )
}