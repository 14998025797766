import React from "react";
import { Modal } from "react-bootstrap";
import Hubspot from 'react-hubspot-form';

export default function HubspotForm({ formId, show, setShow, isModal = true }) {

    const handleClose = () => { setShow(false) }

    return (
        <>
            {
                isModal ? 
                <Modal centered='true' size="xl" show={show} onHide={handleClose}>
                    <Modal.Header className="d-flex flex-row-reverse" >
                        <i onClick={handleClose} class="bi bi-x-circle" style={{color: 'red', fontSize: '30px'}}></i>
                    </Modal.Header>
                    <Modal.Body>
                        <Hubspot 
                            region= "eu1"
                            portalId= "25901531"
                            formId= {formId}
                        />
                    </Modal.Body>
                </Modal> : 
                <Hubspot 
                    region= "eu1"
                    portalId= "25901531"
                    formId= {formId}
                />
            }
        </>
    )
}