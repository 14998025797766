import React, { useState } from "react";
import Header from "../components/NavbarComponent";
import pmi from '../assets/logos/logo-pmi.png';
import Footer from "../components/Footer";
import { useEffect } from "react";
import { Helmet } from 'react-helmet'
import HeaderComponent from '../components/HeaderComponent'
import backgroundPianiHeader from '../assets/images/backgroundPianiHeader.png'
import dataCardPiani from '../assets/data/Piani/cardPiani.json'
import CardPiani from "../components/CardPiani";
import backgroundImageCorporate from '../assets/images/backgroundPianiCorporate.png'
import dataCorporate from '../assets/data/TextImage/textImagePiani.json'
import TextImageComponentSmall from "../components/TextImageComponentSmall";
import HubspotForm from "../components/HubspotForm";
import BoxConfrontaPiani from "../components/BoxConfrontaPiani";



function Piani() {

    const [show, setShow] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('partitaIVA') !== null) {
            localStorage.removeItem('partitaIVA')
        }
    })
    return (
        <>
            <Helmet>
                <title>Soluzioni</title>
                <meta property="og:url" content="https://www.pmiagile.com/piani" />
                <meta
                    name="description"
                    content="Ottieni accesso all’Analisi di Bilancio, Analisi Centrale Rischi e Confronto di Aziende. Registrati per provare gratuitamente PMI Agile"
                />
            </Helmet>
            <Header link='/' logo={pmi} />
            <HeaderComponent heightBackgroundComponent={'backgroundComponentPiani'} colorText={'#06152c'} backgroundImage={backgroundPianiHeader} textTitle={'Scegli il <strong>piano</strong> che fa <br/>al caso tuo'} />
            <CardPiani data={dataCardPiani} />
            <BoxConfrontaPiani />
            <TextImageComponentSmall reverse={false} backgroundImage={backgroundImageCorporate} data={dataCorporate} clickButton={() => { setShow(true) }} />
            <HubspotForm show={show} setShow={setShow} formId='fd0474b0-53d6-42c0-85f5-ef70aca98ef7' />
            <Footer />
        </>
    );
}
export default Piani