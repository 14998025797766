import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import TitleComponent from "./TitleComponent";
import WhiteLineComponent from "./WhiteLineComponent";
import ButtonComponent from './ButtonComponent'
import HubspotForm from "./HubspotForm";

export default function CardPiani({ data }) {

    const [show, setShow] = useState(false)
    const [showAnnuo, setShowAnnuo] = useState(false)

    const col = data.map(p => {
        return (
            <>
                <Col sm='6' className='pb-5 mt-5 mb-1 mb-xl-0 mx-0 mx-md-3 d-flex flex-column cardPiani'>
                    {
                        p.tititoloScontato === '' ? <>
                            <div className="bg-primary py-2 heightPrice" style={{borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px'}} ><p style={{fontSize: '11px'}} className="text-white text-center mb-auto">PREZZO SPECIALE PER LA DURATA DI APRILE E MAGGIO</p></div>
                        </> : <div className="bg-transparent py-2 heightPrice" style={{borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px'}} ><p style={{fontSize: '11px'}} className="text-white text-center mb-auto"></p></div>
                    }
                    <div>
                        <div className="d-flex justify-content-center mb-4">
                            <TitleComponent className={`text-${p.align} text-center pt-3 px-0 px-xl-0 titleComponent`} style={{color: p.colorText}} text={p.subTitolo} align={p.align} />
                        </div>
                        <div className="d-flex justify-content-center">
                            <WhiteLineComponent height="3" color={p.colorText} size='135px' />
                        </div>
                        {
                            p.titoloScontato ? <div className="d-flex justify-content-center mt-1">
                            <div style={{marginTop: '-5px'}}><div className="barrato"></div>
                            <label className="roboto" style={{color: '#AEAEAE', fontSize: '24px'}}>{showAnnuo ? p.titoloScontatoAnno : p.titoloScontato}</label></div>
                        </div> : <div className="d-flex justify-content-center mt-1">
                            <div style={{marginTop: '-5px'}}><div className="barratoTransparent"></div>
                            <label className="roboto" style={{color: 'transparent', fontSize: '24px'}}>a</label></div>
                        </div>
                        }
                        <div className="d-flex justify-content-center">
                            <TitleComponent className={`text-${p.align} text-center roboto px-0 px-xl-0 ${p.titolo !== 'Gratis' && p.titolo !== 'Personalizzato' ? 'robotoBlack' : 'robotoNormal'}`} style={{ fontSize: '36px' }} text={showAnnuo ? p.titoloAnno : p.titolo} />{p.titolo.replaceAll(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '') === 'Gratis' || p.titolo.replaceAll(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '') === 'Personalizzato' ? null : <label style={{fontSize:'16px'}} className="mt-auto text-muted mb-2">&nbsp;utente/{showAnnuo ? 'anno' : 'mese'}</label>}
                        </div>
                        <p className={`text-center px-4 ${p.titolo === 'Gratis' && p.titolo === 'Personalizzato' ? 'mb-4' : 'my-4'}`}>{p.body}</p>
                    </div>
                    <div className="d-flex justify-content-center mt-auto">
                        <ButtonComponent className={'text-white'} text={p.buttonText} colorText='#fff' handleClick={() => { 
                            if(p.checkHubspot) {
                                setShow(true)
                            } else {
                                window.location.href = p.link
                            }
                        }} typeBootstrapButton='primary' />
                    </div>
                </Col>
            </>
        )
    })

    return (
        <>
            <div className="mt-5">
                <div className="px-0 px-sm-4 d-flex justify-content-center">
                    <Row className='d-flex justify-content-center cardPianiRow'>
                        {col}
                        <Col className="pb-5 mt-5 mb-1 mb-xl-0 mx-0 mx-md-5 px-5 d-flex flex-column" sm='12'>
                            <Container>
                                <Form className="d-flex flex-row">
                                    <Form.Check type="switch" id="custom-switch" onChange={() => { setShowAnnuo(!showAnnuo) }} label=''  ></Form.Check>
                                    <label>Scegli il pagamento annuale</label>
                                    {/* <label style={{color: 'rgba(57, 121, 246, 1)', fontWeight: 'bold', fontSize: '20px'}}>risparmia fino al 17%</label> */}
                                </Form>
                            </Container>
                        </Col>
                    </Row>
                </div>
            </div>
            <HubspotForm show={show} setShow={setShow} formId={'ca9c4bd0-1e53-4512-8a99-ef51fb0fd4af'} />
        </>
    )
}