import React from "react";
import Popup from "../../Popup";
import Search from "../../../Search";
import errorImage from './../../../../assets/images/error-image/error-response.svg'

function FailureResults(props) {
    return(
        <>
            <Popup img={errorImage} title="Non è presente nessun bilancio pubblico per questa P.IVA" />
            <Search />
        </>
    )
} export default FailureResults