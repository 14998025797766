import React from "react";
import { Helmet } from "react-helmet";
import FaqComponent from "../components/FaqComponent";
import Footer from "../components/Footer";
import NavbarComponent from "../components/NavbarComponent";
import dataFaq from './../assets/data/Faq/faqComplete.json'

export default function Faq() {
    return (
        <>
            <Helmet>
                <title>Faq - PMI Agile</title>
                <meta property="og:url" content="https://www.pmiagile.com/faq" />
                <meta
                    name="description"
                    content="Scopri PMI Agile Enterprise la soluzione di Business Intelligence che semplifica la gestione e l'analisi dei dati aziendali"
                />
            </Helmet>
            <NavbarComponent />
            <FaqComponent data={dataFaq} />
            <Footer />
        </>
    )
}