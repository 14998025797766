import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function AreaChart({ anno3, anno2, anno1, year3, year2, year1, colorStart, colorMid, colorEnd }){

  const options = {
    responsive: true,
    scales: {
      x: {
        display: false
      },
      y: {
        display: false
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false // <-- this option disables tooltips
      }
    }
  }
  

  const labels = [anno3, anno2, anno1]
  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: '',
        data: [parseInt(year3), parseInt(year2), parseInt(year1)],
        borderColor: '#2269F4',
        backgroundColor: (context) => {
          const chart = context.chart
          const { ctx, chartArea } = chart
          if(!chartArea) {
            return null
          }
          if(context.datasetIndex === 0) {
            return getGradient(chart, colorStart, colorMid, colorEnd)
          } else {
            return 'black'
          }
        },
        tension: 0.4
      },
    ]
  }

  return(
    <Line className="mt-5 mt-md-3" style={{ width: '250px', height: '80.5px' }} options={options} data={data} />
  )
        
} export default AreaChart

function getGradient(chart, colorStart, colorMid, colorEnd) {
  const { ctx, chartArea: {top, bottom, left, right} } = chart
  const gradientElement = ctx.createLinearGradient(left, 0, right, 0)
  gradientElement.addColorStop(0, colorStart)
  gradientElement.addColorStop(0.5, colorMid)
  gradientElement.addColorStop(1, colorEnd)

  return gradientElement
}