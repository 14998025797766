import React from "react";
import { Container } from "react-bootstrap";
import BackgroundComponent from "./BackgroundComponent";
import SubTitleComponent from "./SubTitleComponent";

export default function BoxCitationComponent({ backgroundImage, titolo, text, align }) {
    return (
        <>
            <Container>
                <BackgroundComponent paddingX={'px-5'} imageBackground={backgroundImage} radius='rounded-top'>
                    <div className="px-0 px-xl-5 py-xl-1">
                        <div className="d-flex justify-content-center">
                            <SubTitleComponent className={`text-${align} pt-3 px-0 px-xl-0`} text={titolo} />
                        </div>
                        <p className={`text-${align} mt-2 mb-5`} dangerouslySetInnerHTML={{ __html: text }} />
                    </div>
                </BackgroundComponent>
            </Container>
        </>
    )
}