import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import TitleComponent from "./TitleComponent";
import SubTitleComponent from "./SubTitleComponent";
import Card from "./Card";

export default function BoxCardHome({ data, children }) {

    const col = data.map(p => {
        return (
            <>
                <Col xxl='3' sm='12' className={`mx-0 mx-xxl-4 my-4 d-none d-lg-flex my-xxl-0 justify-content-center ${p.center === 'true' ? '' : ''}`}>
                    <div className="mb-5 mb-xl-0 boxCardAnalisi">
                        <div onClick={() => changePage(p.link)} className={`px-3 pt-3 pb-3 ${p.center === 'false' ? 'cardAnalisi' : 'cardAnalisiCenter'}`}>
                            <Card titolo={p.titolo} text={p.text} icona={p.icona} />
                        </div>
                    </div>
                </Col>
                <Col xxl='3' sm='12' className={`mx-0 mx-xxl-4 my-2 d-block d-lg-none my-xxl-0 d-flex justify-content-center ${p.center === 'true' ? '' : ''}`}>
                    <div style={{border: '1px solid #b1b1b1', backgroundColor: '#eff3ff', borderRadius: '20px'}} onClick={() => changePage(p.link)} className={`px-3 pt-3 pb-3`}>
                        <Card titolo={p.titolo} text={p.text} icona={p.icona} />
                    </div>
                </Col>
            </>
        )
    })

    return (
        <>
            <Container className="mb-5 mb-xl-5">
                <div className="d-flex justify-content-center">
                    <TitleComponent className={`text-center pt-3 px-0 px-xl-0 titleComponent mt-3`} text={'Cosa <strong>facciamo</strong>'} />
                </div>
                <div className="d-flex justify-content-center my-2">
                    <SubTitleComponent align={'center'} className='text-center pt-2 pb-xl-3 pb-0 px-0 px-xl-0 subTitleComponent' text={'Siamo la soluzione per ogni tua esigenza di informazioni ed <br/>analisi economica, patrimoniale e finanziaria.'} />
                </div>
                <Row className="d-flex justify-content-center py-2 py-lg-0">
                    {col}
                </Row>
            </Container>
        </>
    )
}


function changePage(link) {
    window.location.href = link
}