import React from "react";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

function StampaChart(props) {
  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
    const labels = ["Mercato"];
    const data = {
    labels,
    datasets: [
      {
        label: 'Totale Immobilizzazioni',
        data: [parseFloat(props.totale)],
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Totale Valore Produzione',
        data: [parseFloat(props.tot_val_produzione)],
        backgroundColor: 'rgba(126, 127, 93, 0.5)',
      },
      {
        label: 'Totale Debiti',
        data: [parseFloat(props.totale_debiti)],
        backgroundColor: 'rgba(213, 213, 126, 0.5) ',
      },
      {
        label: 'PFN Ebitda',
        data: [parseFloat(props.pfn_ebitda)],
        backgroundColor: 'rgba(226, 155, 111, 0.5)',
      },
      {
        label: 'Totale Fondi Rischi',
        data: [parseFloat(props.totale_fondi_rischi)],
        backgroundColor: 'rgba(161, 194, 121, 0.5)',
      },
      {
        label: 'Attivo Circolante',
        data: [parseFloat(props.attivo_circolante)],
        backgroundColor: 'rgba(78, 48, 17, 0.5)',
      },
      {
        label: 'Ratei Riscontri',
        data: [parseFloat(props.ratei_risconti)],
        backgroundColor: 'rgba(203, 85, 228, 0.5)',
      },
      {
        label: 'Ebitda',
        data: [parseFloat(props.ebitda)],
        backgroundColor: 'rgba(121, 253, 67, 0.5)',
      },
      {
        label: 'PFN',
        data: [parseFloat(props.pfn)],
        backgroundColor: 'rgba(234, 80, 55, 0.5) ',
      },
      {
        label: 'Utile',
        data: [parseFloat(props.utile)],
        backgroundColor: 'rgba(19, 111, 133, 0.5)',
      },
      {
        label: 'Debiti Breve',
        data: [parseFloat(props.debiti_breve)],
        backgroundColor: 'rgba(55, 90, 237, 0.5)',
      },
      {
        label: 'Debiti Lungo',
        data: [parseFloat(props.debiti_lungo)],
        backgroundColor: 'rgba(90, 48, 110, 0.5)',
      },
      {
        label: 'Dipendenti',
        data: [parseFloat(props.dipendenti)],
        backgroundColor: 'rgba(5, 61, 54, 0.5) ',
      },
      {
        label: 'Ebitda/Vendite',
        data: [parseFloat(props.ev)],
        backgroundColor: 'rgba(174, 198, 213, 0.5)',
      },
      {
        label: 'Ebitda Fatturato',
        data: [parseFloat(props.ebitda_fatturato)],
        backgroundColor: 'rgba(246, 154, 25, 0.5) ',
      },
      {
        label: 'Fatturato',
        data: [parseFloat(props.fatturato)],
        backgroundColor: 'rgba(161, 31, 247, 0.5)',
      },
      {
        label: 'Oneri Finanziari Ebitda',
        data: [parseFloat(props.oneri_fin_ebitda)],
        backgroundColor: 'rgba(105, 255, 215, 0.5)',
      },
      {
        label: 'PN',
        data: [parseFloat(props.pn)],
        backgroundColor: 'rgba(92, 75, 216, 0.5)',
      },
      {
        label: 'Totale PN Passivo',
        data: [parseFloat(props.pn_tot_passivo)],
        backgroundColor: 'rgba(147, 60, 34, 0.5)',
      },
      {
        label: 'TFR',
        data: [parseFloat(props.tfr)],
        backgroundColor: 'rgba(103, 230, 247, 0.5)',
      },
  ]
  };

  // config 
  const config = {
    type: 'bar',
    data,
    options: {
      scales: {
        y: {
          beginAtZero: true
        }
      }
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <div className="box-bar mt-5 mb-5">
          <div className="container mb-5 ">
          <Bar options={config} data={data} />
          </div>
        </div>
      </div>
    </>
  )
}
export default StampaChart