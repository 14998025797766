import React from "react";

export default function VideoComponent({ path }) {
    return (
        <>
            <div className="d-flex justify-content-center" style={{ borderRadius: '25px', border: '1px solid #ededed' }}>
                <video id="video" className="img-fluid" style={{minWidth: '259.11px'}} controls>
                    <source src={path} />
                </video>
            </div>
        </>
    )
}