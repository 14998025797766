import React from "react";
import data from './dataAteco.json'
import macroLetter from './macroCategory.json'
import mercatoNumber from './macroNumber.json'
import Select from "react-select";
import { useState } from "react";
import config from './../../../.config.json'
import LoadingResults from "../results/LoadingResults";
import StampaChartPIVA from "./View/StampaChartPIVA";
import StampaChart from "./View/StampaChart";
import StampaMercato from "./View/StampaMercato";
import StampaMercatoBar from "./View/StampaMercatoBar";
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import Popup from "../Popup";
import errorImage from './../../../assets/images/error-image/error-response.svg'

function AlberaturaMercato() {
    const isDisabledPIVA = false
    const [isDisabledFirstCategory, setIsDisabledFirstCategory] = useState(true)
    const [isDisabledSecondCategory, setIsDisabledSecondCategory] = useState(true)
    const [isDisabledThirdCategory, setIsDisabledThirdCategory] = useState(true)
    const [isDisabledFourthCategory, setIsDisabledFourthCategory] = useState(true)
    const [isDisabledRange, setIsDisabledRange] = useState(true)
    const isDisabledMacroCategory = false
    const [filterFirstCategory, setFilterFirstCategory] = useState('')
    const [filterSecondCategory, setFilterSecondCategory] = useState('')
    const [filterThirdCategory, setFilterThirdCategory] = useState('')
    const [filterFourthCategory, setFilterFourthCategory] = useState('')
    const [range, setRange] = useState('')
    const [pIva, setPIva] = useState('')
    const [control, setControl] = useState(0)
    const [codiceAteco, setCodiceAteco] = useState('')
    const [checkSingleClick, setCheckSingleClick] = useState(false)
    const [checkPassword, setCheckPassword] = useState(false)
    const [password, setPassword] = useState('')


    const [itemsAVG, setItemsAVG] = useState([])
    const [errorAVG, setErrorAVG] = useState(null)
    const [isLoadedAVG, setIsLoadedAVG] = useState(false)
    const [itemsPIVA, setItemsPIVA] = useState([])
    const [errorPIVA, setErrorPIVA] = useState(null)
    const [isLoadedPIVA, setIsLoadedPIVA] = useState(false)


    const optionsCat = [
        { value: 'A', label: 'A: AGRICOLTURA, SILVICOLTURA E PESCA' },
        { value: 'B', label: 'B: ESTRAZIONE DI MINERALI DA CAVE E MINIERE' },
        { value: 'C', label: 'C: ATTIVITÀ MANIFATTURIERE' },
        { value: 'D', label: 'D: FORNITURA DI ENERGIA ELETTRICA, GAS, VAPORE E ARIA CONDIZIONATA' },
        { value: 'E', label: 'E: FORNITURA DI ACQUA; RETI FOGNARIE, ATTIVITÀ DI GESTIONE DEI RIFIUTI E RISANAMENTO' },
        { value: 'F', label: 'F: COSTRUZIONI' },
        { value: 'G', label: "G: COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI" },
        { value: 'H', label: 'H: TRASPORTO E MAGAZZINAGGIO' },
        { value: 'I', label: 'I: ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE' },
        { value: 'J', label: 'J: SERVIZI DI INFORMAZIONE E COMUNICAZIONE' },
        { value: 'K', label: 'K: ATTIVITÀ FINANZIARIE E ASSICURATIVE' },
        { value: 'L', label: 'L: ATTIVITÀ IMMOBILIARI' },
        { value: 'M', label: 'M: ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE' },
        { value: 'N', label: 'N: NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE' },
        { value: 'O', label: 'O: AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA' },
        { value: 'P', label: 'P: ISTRUZIONE' },
        { value: 'Q', label: 'Q: SANITÀ E ASSISTENZA SOCIALE' },
        { value: 'R', label: 'R: ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO' },
        { value: 'S', label: 'S: ALTRE ATTIVITÀ DI SERVIZI' },
        { value: 'T', label: 'T: ATTIVITÀ DI FAMIGLIE E CONVIVENZE COME DATORI DI LAVORO PER PERSONALE DOMESTICO; PRODUZIONE DI BENI E SERVIZI INDIFFERENZIATI PER USO PROPRIO DA PARTE DI FAMIGLIE E CONVIVENZE' },
        { value: 'U', label: 'U: ORGANIZZAZIONI ED ORGANISMI EXTRATERRITORIALI' },
      ]
      const optionsRange = [
        {value: 1, label: "1: da 0 a 3.000.000 di fatturato"},
        {value: 2, label: "2: da 3.000.000 a 10.000.000 di fatturato"},
        {value: 3, label: "3: da 10.000.000 a 30.000.000 di fatturato"},
        {value: 4, label: "4: da 30.000.000 di fatturato"},
      ]
      const optionsAPI = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': config.RESULTS,
        }
    }

      function handleChangeMacro(event) {

        if (!event) {
            event = {
              value: '',
            };
          }

        if(event.value === ''){
            setFilterFirstCategory(event.value)
            setIsDisabledFirstCategory(true)
            setIsDisabledRange(true)
            document.getElementById('spiegazione').innerText = 'Inserisci la Macro Categoria'
            document.getElementById('buttonOnlyPIVA').classList.remove('d-block')
            document.getElementById('buttonOnlyPIVA').classList.add('d-none')
            document.getElementById('spiegazioneRange').innerHTML = 'Devi inserire il range di fatturato'
            document.getElementById('codiceAtecoCalcolo').innerHTML = ''
            document.getElementById('rangeCalcolo').innerHTML = range
            document.getElementById('partitaIVACalcolo').innerHTML = pIva
        } else {
            setCodiceAteco(event.value)
            setIsDisabledFirstCategory(false)
            setFilterFirstCategory(event.value)
            setIsDisabledRange(false)
            document.getElementById('codiceAtecoCalcolo').innerHTML = event.value
            document.getElementById('rangeCalcolo').innerHTML = range
            document.getElementById('partitaIVACalcolo').innerHTML = pIva
            document.getElementById('spiegazione').innerText = 'Inserisci il Primo Livello del Codice Ateco'
            if(range !== ''){
                document.getElementById('buttonOnlyPIVA').classList.add('d-block')
                document.getElementById('buttonOnlyPIVA').classList.remove('d-none')
                document.getElementById('spiegazioneRange').innerHTML = 'Fai la Ricerca'
            }
        }
    }

    function handleChangeFirst(event) {

        if (!event) {
            event = {
              value: '',
            };
          }

        if(event.value === ''){
            setIsDisabledSecondCategory(true)
            document.getElementById('spiegazione').innerText = 'Inserisci il Primo Livello del Codice Ateco'
            document.getElementById('codiceAtecoCalcolo').innerHTML = codiceAteco
            document.getElementById('rangeCalcolo').innerHTML = range
            document.getElementById('partitaIVACalcolo').innerHTML = pIva
        } else {
            setCodiceAteco(event.value)
            setIsDisabledSecondCategory(false)
            document.getElementById('codiceAtecoCalcolo').innerHTML = event.value
            document.getElementById('rangeCalcolo').innerHTML = range
            document.getElementById('partitaIVACalcolo').innerHTML = pIva
            setFilterSecondCategory(event.value)
            document.getElementById('spiegazione').innerText = 'Inserisci il Secondo Livello del Codice Ateco'
        }
    }

    function handleChangeSecond(event) {

        if (!event) {
            event = {
              value: '',
            };
          }

        if(event.value === ''){
            setIsDisabledThirdCategory(true)
            document.getElementById('spiegazione').innerText = 'Inserisci il Secondo Livello del Codice Ateco'
            document.getElementById('codiceAtecoCalcolo').innerHTML = filterSecondCategory
            document.getElementById('rangeCalcolo').innerHTML = range
            document.getElementById('partitaIVACalcolo').innerHTML = pIva
        } else {
            setCodiceAteco(codiceAteco)
            setIsDisabledThirdCategory(false)
            document.getElementById('codiceAtecoCalcolo').innerHTML = filterSecondCategory + event.value
            document.getElementById('rangeCalcolo').innerHTML = range
            document.getElementById('partitaIVACalcolo').innerHTML = pIva
            document.getElementById('spiegazione').innerText = 'Inserisci il Terzo Livello del Codice Ateco'
            setFilterThirdCategory(event.value)
        }
    }

    function handleChangeThird(event) {

        if (!event) {
            event = {
              value: '',
            };
          }

        if(event.value === ''){
            setIsDisabledFourthCategory(true)
            document.getElementById('spiegazione').innerText = 'Inserisci il Terzo Livello del Codice Ateco'
            document.getElementById('codiceAtecoCalcolo').innerHTML = codiceAteco
            document.getElementById('rangeCalcolo').innerHTML = range
            document.getElementById('partitaIVACalcolo').innerHTML = pIva
        } else {
            setCodiceAteco(codiceAteco + event.value)
            document.getElementById('codiceAtecoCalcolo').innerHTML = filterSecondCategory + event.value
            document.getElementById('rangeCalcolo').innerHTML = range
            document.getElementById('partitaIVACalcolo').innerHTML = pIva
            setIsDisabledFourthCategory(false)
            setFilterFourthCategory(event.value)
            document.getElementById('spiegazione').innerText = 'Inserisci il Quarto Livello del Codice Ateco'
        }
    }


    function handleChangeFourth(event) {

        if (!event) {
            event = {
              value: '',
            };
          }

        if(event.value === ''){
            setIsDisabledFourthCategory(true)
            document.getElementById('codiceAtecoCalcolo').innerHTML = codiceAteco
            document.getElementById('rangeCalcolo').innerHTML = range
            document.getElementById('partitaIVACalcolo').innerHTML = pIva
        } else {
            setCodiceAteco(codiceAteco + event.value)
            document.getElementById('codiceAtecoCalcolo').innerHTML = codiceAteco + event.value
            document.getElementById('rangeCalcolo').innerHTML = range
            document.getElementById('partitaIVACalcolo').innerHTML = pIva
        }
    }

    function handleChangeRange(event) {

        if (!event) {
            event = {
              value: '',
            };
          }

        if(event.value !== ''){
            setRange(event.value)
            if(filterFirstCategory !== ''){
                document.getElementById('buttonOnlyPIVA').classList.add('d-block')
                document.getElementById('buttonOnlyPIVA').classList.remove('d-none')
                document.getElementById('spiegazioneRange').innerHTML = 'Fai la Ricerca'
                document.getElementById('codiceAtecoCalcolo').innerHTML = codiceAteco
                document.getElementById('rangeCalcolo').innerHTML = event.value
                document.getElementById('partitaIVACalcolo').innerHTML = pIva
            }
        } else {
            setRange(event.value)
            document.getElementById('buttonOnlyPIVA').classList.remove('d-block')
            document.getElementById('buttonOnlyPIVA').classList.add('d-none')
            document.getElementById('spiegazioneRange').innerHTML = 'Devi inserire il range di fatturato'
            document.getElementById('codiceAtecoCalcolo').innerHTML = codiceAteco
            document.getElementById('rangeCalcolo').innerHTML = event.value
            document.getElementById('partitaIVACalcolo').innerHTML = pIva
        }
    }

    function handleChangePIVA(event) {

        if (!event) {
            event = {
              value: '',
            };
          }

        if(event.target.value !== ''){
            setPIva(event.target.value)
            document.getElementById('codiceAtecoCalcolo').innerHTML = codiceAteco
            document.getElementById('rangeCalcolo').innerHTML = range
            document.getElementById('partitaIVACalcolo').innerHTML = event.target.value
            document.getElementById('buttonOnlyPIVA').classList.add('d-block')
            document.getElementById('buttonOnlyPIVA').classList.remove('d-none')
        } else {
            setPIva('')
            document.getElementById('codiceAtecoCalcolo').innerHTML = codiceAteco
            document.getElementById('rangeCalcolo').innerHTML = range
            document.getElementById('partitaIVACalcolo').innerHTML = ''
            document.getElementById('buttonOnlyPIVA').classList.add('d-none')
            document.getElementById('buttonOnlyPIVA').classList.remove('d-block')
        }
    }

    function handleSingleClick(event){
        if(checkSingleClick === false){
            setCheckSingleClick(true)
        }
        if(pIva !== '' && codiceAteco === '' && range === ''){
            setControl(1)
            fetch("https://6kcj3trvik.execute-api.eu-south-1.amazonaws.com/v1/get_company_data?piva=" + pIva, optionsAPI)
            .then(res => res.json())
            .then((result) => {
                setIsLoadedPIVA(true)
                setItemsPIVA(result)
                callAVG(result)
            },
            (error) => {
                setIsLoadedPIVA(true)
                setErrorPIVA(error)
            })
        } else if(pIva === '' && codiceAteco !== '' && range !== '') {
            setControl(2)
            if(codiceAteco.length === 1){
                fetch("https://6kcj3trvik.execute-api.eu-south-1.amazonaws.com/v1/get_ateco_avg?&ateco_cat=" + codiceAteco + "&ateco_range=" + range, optionsAPI)
                    .then(res => res.json())
                    .then((result) => {
                        setIsLoadedAVG(true)
                        setItemsAVG(result)
                    },
                    (errorAVG) => {
                        setErrorAVG(errorAVG)
                        setIsLoadedAVG(true)
                    })
            } else {
                fetch("https://6kcj3trvik.execute-api.eu-south-1.amazonaws.com/v1/get_ateco_avg?&ateco_no=" + codiceAteco + "&ateco_range=" + range, optionsAPI)
                .then(res => res.json())
                .then((result) => {
                    setIsLoadedAVG(true)
                    setItemsAVG(result)
                },
                (errorAVG) => {
                    setErrorAVG(errorAVG)
                    setIsLoadedAVG(true)
                })
            }
        }
        else if(pIva !== '' && codiceAteco !== '' && range !== ''){
                setControl(3)
                if(codiceAteco.length === 1){
                    fetch("https://6kcj3trvik.execute-api.eu-south-1.amazonaws.com/v1/get_ateco_avg?&ateco_cat=" + codiceAteco + "&ateco_range=" + range, optionsAPI)
                        .then(res => res.json())
                        .then((result) => {
                            setIsLoadedAVG(true)
                            setItemsAVG(result)
                        },
                        (errorAVG) => {
                            setErrorAVG(errorAVG)
                            setIsLoadedAVG(true)
                        })
                } else {
                    fetch("https://6kcj3trvik.execute-api.eu-south-1.amazonaws.com/v1/get_ateco_avg?&ateco_no=" + codiceAteco + "&ateco_range=" + range, optionsAPI)
                    .then(res => res.json())
                    .then((result) => {
                        setIsLoadedAVG(true)
                        setItemsAVG(result)
                    },
                    (errorAVG) => {
                        setErrorAVG(errorAVG)
                        setIsLoadedAVG(true)
                    })
                }
                fetch("https://6kcj3trvik.execute-api.eu-south-1.amazonaws.com/v1/get_company_data?piva=" + pIva, optionsAPI)
                    .then(res => res.json())
                    .then((result) => {
                        setIsLoadedPIVA(true)
                        setItemsPIVA(result)
                    },
                    (error) => {
                        setIsLoadedPIVA(true)
                        setErrorPIVA(error)
                })
            }
        }


    function handleClickClearAll(event){
        let selectValue = document.getElementsByClassName('css-qc6sy-singleValue')
        for(let i = 0; i < selectValue.length; i++){
            selectValue[i].innerHTML = 'Select...'
        }

        document.getElementById('textPIVA').value = ''
        setIsDisabledFirstCategory(true)
        setIsDisabledSecondCategory(true)
        setIsDisabledThirdCategory(true)
        setIsDisabledFourthCategory(true)
        setIsDisabledRange(true)
        setPIva('')
        setCodiceAteco('')
        setRange('')
    }
    

    function callAVG(itemsPIVA){
        if(itemsPIVA.message  !== 'There is no balances for this company' && itemsPIVA.message  !== 'There is no data for this Vat_Number'){
            for(let i = 0; i < mercatoNumber.records.length; i++){
                if(itemsPIVA.results.year1.Ricavi_vendite_e_prestazioni > mercatoNumber.records[i][1] && itemsPIVA.results.year1.Ricavi_vendite_e_prestazioni < mercatoNumber.records[i][2]){
                    fetch("https://6kcj3trvik.execute-api.eu-south-1.amazonaws.com/v1/get_ateco_avg?&ateco_no=" + itemsPIVA.results.company.ATECO7C + "&ateco_range=" + mercatoNumber.records[i][0], optionsAPI)
                    .then(res => res.json())
                    .then((result) => {
                        setIsLoadedAVG(true)
                        setItemsAVG(result)
                    },
                        (errorAVG) => {
                            setErrorAVG(errorAVG)
                            setIsLoadedAVG(true)
                        })
                } 
            }
        } else {
            setPIva('')
            setCheckSingleClick(true)
            setItemsPIVA([])
            setCodiceAteco('')
            setRange('')
            setControl(0)
        }
        
    }

    function handleChangePassword(event) {
        if (!event) {
            event = {
              value: '',
            };
          }

        
        if(event.target.value !== '') {
            setPassword(event.target.value)
        }
    }

    function handleSubmitPassword(event) {
        if(password === config.PASSWORD) {
            setCheckPassword(true)
        } else {
            document.getElementById('validate').innerHTML = 'La password inserita è sbagliata'
        }
    }
    
    return(
        <>
            {
                checkPassword === false ? <div className="container">
                    <div className="d-flex justify-content-center align-items-center">
                    <div class="input-group mb-3 me-4">
                        <input type="password" class="form-control" onChange={handleChangePassword} aria-label="Recipient's username" aria-describedby="button-addon2" required />
                        <div class="input-group-append">
                            <button class="btn btn-outline-primary" onClick={handleSubmitPassword} type="button" id="button-addon2">Button</button>
                        </div>
                        </div>
                </div>
                        <div className="d-flex justify-content-center">
                            <p id="validate" style={{color: 'red'}} ></p>
                        </div>
                </div> : checkSingleClick === false ?
                <>
                    <div className="section">
                        <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <h3>Codice Ateco</h3>
                            <Select id="prova" isClearable="true" className="mb-3 selectMercato" isDisabled={isDisabledMacroCategory} options={optionsCat} onChange={handleChangeMacro} />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledFirstCategory} onChange={handleChangeFirst} options={getFirstCategory(data, filterFirstCategory)} />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledSecondCategory} onChange={handleChangeSecond} options={getSecondCategory(data, filterSecondCategory)}  />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledThirdCategory} onChange={handleChangeThird}  options={getThirdCategory(data, filterThirdCategory, filterSecondCategory)}  />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledFourthCategory}  onChange={handleChangeFourth} options={getFourthCategory(data, filterSecondCategory, filterFourthCategory)}  />
                        </div>
                        <div className="col-md-3">
                            <h3>Range Fatturato</h3>
                            <Select name="ateco_range" isDisabled={isDisabledRange} className="mb-3" onChange={handleChangeRange} options={optionsRange} isClearable="true" />
                        </div>
                        <div className="col-md-3">
                            <h3>Partita IVA</h3>
                            <InputGroup className="mb-3">
                                <Form.Control onChange={handleChangePIVA} disabled={isDisabledPIVA} id='textPIVA' aria-label="Text input with checkbox" />
                            </InputGroup>
                        </div>
                        <div className="col-md-3">
                            <div className="card colorBackground">
                                <div className="card-body">
                                    <h3>Suggerimenti</h3>
                                    <div>
                                        <p className="text-warning" id='spiegazione'>Inserisci la Macro Categoria</p>
                                    </div>
                                    <div>
                                        <p className="text-danger" id="spiegazioneRange" >Devi inserire il range Fatturato</p>
                                    </div>
                                    <div>
                                        <h3>Calcolo</h3>
                                        <ul>
                                            <li><label>Codice Ateco: </label><span id='codiceAtecoCalcolo'></span></li>
                                            <li><label>Range Fatturato: </label><span id='rangeCalcolo'></span></li>
                                            <li><label>Partita IVA: </label><span id='partitaIVACalcolo'></span></li>
                                        </ul>
                                    </div>
                                    <button onChange={handleChangePIVA} onClick={handleSingleClick} id="buttonOnlyPIVA" className="boxRadius btn btn-outline-primary w-100 mt-3 d-none">Analizza</button>
                                    <button onClick={handleClickClearAll} className='boxRadius btn btn-outline-danger w-100 mt-3'><i class="bi bi-trash"></i></button>
                                </div> 
                            </div>
                        </div> 
                    </div>    
                </div>
                    </div>
                </> : control === 1 ? 
                <>
                    <div className="section">
                        <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <h3>Codice Ateco</h3>
                            <Select id="prova" isClearable="true" className="mb-3 selectMercato" isDisabled={isDisabledMacroCategory} options={optionsCat} onChange={handleChangeMacro} />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledFirstCategory} onChange={handleChangeFirst} options={getFirstCategory(data, filterFirstCategory)} />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledSecondCategory} onChange={handleChangeSecond} options={getSecondCategory(data, filterSecondCategory)}  />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledThirdCategory} onChange={handleChangeThird}  options={getThirdCategory(data, filterThirdCategory, filterSecondCategory)}  />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledFourthCategory}  onChange={handleChangeFourth} options={getFourthCategory(data, filterSecondCategory, filterFourthCategory)}  />
                        </div>
                        <div className="col-md-3">
                            <h3>Range Fatturato</h3>
                            <Select name="ateco_range" isDisabled={isDisabledRange} className="mb-3" onChange={handleChangeRange} options={optionsRange} isClearable="true" />
                        </div>
                        <div className="col-md-3">
                            <h3>Partita IVA</h3>
                            <InputGroup className="mb-3">
                                <Form.Control onChange={handleChangePIVA} disabled={isDisabledPIVA} id='textPIVA' aria-label="Text input with checkbox" />
                            </InputGroup>
                        </div>
                        <div className="col-md-3">
                            <div className="card colorBackground">
                                <div className="card-body">
                                    <h3>Suggerimenti</h3>
                                    <div>
                                        <p className="text-warning" id='spiegazione'>Inserisci la Macro Categoria</p>
                                    </div>
                                    <div>
                                        <p className="text-danger" id="spiegazioneRange" >Devi inserire il range Fatturato</p>
                                    </div>
                                    <div>
                                        <h3>Calcolo</h3>
                                        <ul>
                                            <li><label>Codice Ateco: </label><span id='codiceAtecoCalcolo'></span></li>
                                            <li><label>Range Fatturato: </label><span id='rangeCalcolo'></span></li>
                                            <li><label>Partita IVA: </label><span id='partitaIVACalcolo'></span></li>
                                        </ul>
                                    </div>
                                    <button onChange={handleChangePIVA} onClick={handleSingleClick} id="buttonOnlyPIVA" className="boxRadius btn btn-outline-primary w-100 mt-3 d-none">Analizza</button>
                                    <button onClick={handleClickClearAll} className='boxRadius btn btn-outline-danger w-100 mt-3'><i class="bi bi-trash"></i></button>
                                </div> 
                            </div>
                        </div> 
                    </div>    
                </div>
                        {checkOnlyIVA(errorAVG, errorPIVA, isLoadedAVG, isLoadedPIVA, itemsAVG, itemsPIVA)}
                    </div>
                </> : control === 2 ?
                <>
                    <div className="section">
                        <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <h3>Codice Ateco</h3>
                            <Select id="prova" isClearable="true" className="mb-3 selectMercato" isDisabled={isDisabledMacroCategory} options={optionsCat} onChange={handleChangeMacro} />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledFirstCategory} onChange={handleChangeFirst} options={getFirstCategory(data, filterFirstCategory)} />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledSecondCategory} onChange={handleChangeSecond} options={getSecondCategory(data, filterSecondCategory)}  />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledThirdCategory} onChange={handleChangeThird}  options={getThirdCategory(data, filterThirdCategory, filterSecondCategory)}  />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledFourthCategory}  onChange={handleChangeFourth} options={getFourthCategory(data, filterSecondCategory, filterFourthCategory)}  />
                        </div>
                        <div className="col-md-3">
                            <h3>Range Fatturato</h3>
                            <Select name="ateco_range" isDisabled={isDisabledRange} className="mb-3" onChange={handleChangeRange} options={optionsRange} isClearable="true" />
                        </div>
                        <div className="col-md-3">
                            <h3>Partita IVA</h3>
                            <InputGroup className="mb-3">
                                <Form.Control onChange={handleChangePIVA} disabled={isDisabledPIVA} id='textPIVA' aria-label="Text input with checkbox" />
                            </InputGroup>
                        </div>
                        <div className="col-md-3">
                            <div className="card colorBackground">
                                <div className="card-body">
                                    <h3>Suggerimenti</h3>
                                    <div>
                                        <p className="text-warning" id='spiegazione'>Inserisci la Macro Categoria</p>
                                    </div>
                                    <div>
                                        <p className="text-danger" id="spiegazioneRange" >Devi inserire il range Fatturato</p>
                                    </div>
                                    <div>
                                        <h3>Calcolo</h3>
                                        <ul>
                                            <li><label>Codice Ateco: </label><span id='codiceAtecoCalcolo'></span></li>
                                            <li><label>Range Fatturato: </label><span id='rangeCalcolo'></span></li>
                                            <li><label>Partita IVA: </label><span id='partitaIVACalcolo'></span></li>
                                        </ul>
                                    </div>
                                    <button onChange={handleChangePIVA} onClick={handleSingleClick} id="buttonOnlyPIVA" className="boxRadius btn btn-outline-primary w-100 mt-3 d-none">Analizza</button>
                                    <button onClick={handleClickClearAll} className='boxRadius btn btn-outline-danger w-100 mt-3'><i class="bi bi-trash"></i></button>
                                </div> 
                            </div>
                        </div> 
                    </div>    
                </div>
                        {checkCallAVG(errorAVG, itemsAVG, isLoadedAVG)}
                    </div>
                </> : control === 3 ? 
                itemsPIVA.message !== 'There is no balances for this company' && itemsPIVA.message !== 'There is no data for this Vat_Number' && itemsPIVA.message !== undefined ?
                <>
                    <div className="section">
                        <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <h3>Codice Ateco</h3>
                            <Select id="prova" isClearable="true" className="mb-3 selectMercato" isDisabled={isDisabledMacroCategory} options={optionsCat} onChange={handleChangeMacro} />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledFirstCategory} onChange={handleChangeFirst} options={getFirstCategory(data, filterFirstCategory)} />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledSecondCategory} onChange={handleChangeSecond} options={getSecondCategory(data, filterSecondCategory)}  />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledThirdCategory} onChange={handleChangeThird}  options={getThirdCategory(data, filterThirdCategory, filterSecondCategory)}  />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledFourthCategory}  onChange={handleChangeFourth} options={getFourthCategory(data, filterSecondCategory, filterFourthCategory)}  />
                        </div>
                        <div className="col-md-3">
                            <h3>Range Fatturato</h3>
                            <Select name="ateco_range" isDisabled={isDisabledRange} className="mb-3" onChange={handleChangeRange} options={optionsRange} isClearable="true" />
                        </div>
                        <div className="col-md-3">
                            <h3>Partita IVA</h3>
                            <InputGroup className="mb-3">
                                <Form.Control onChange={handleChangePIVA} disabled={isDisabledPIVA} id='textPIVA' aria-label="Text input with checkbox" />
                            </InputGroup>
                        </div>
                        <div className="col-md-3">
                            <div className="card colorBackground">
                                <div className="card-body">
                                    <h3>Suggerimenti</h3>
                                    <div>
                                        <p className="text-warning" id='spiegazione'>Inserisci la Macro Categoria</p>
                                    </div>
                                    <div>
                                        <p className="text-danger" id="spiegazioneRange" >Devi inserire il range Fatturato</p>
                                    </div>
                                    <div>
                                        <h3>Calcolo</h3>
                                        <ul>
                                            <li><label>Codice Ateco: </label><span id='codiceAtecoCalcolo'></span></li>
                                            <li><label>Range Fatturato: </label><span id='rangeCalcolo'></span></li>
                                            <li><label>Partita IVA: </label><span id='partitaIVACalcolo'></span></li>
                                        </ul>
                                    </div>
                                    <button onChange={handleChangePIVA} onClick={handleSingleClick} id="buttonOnlyPIVA" className="boxRadius btn btn-outline-primary w-100 mt-3 d-none">Analizza</button>
                                    <button onClick={handleClickClearAll} className='boxRadius btn btn-outline-danger w-100 mt-3'><i class="bi bi-trash"></i></button>
                                </div> 
                            </div>
                        </div> 
                    </div>    
                </div>
                        {checkCallIVA(errorPIVA, itemsPIVA, isLoadedPIVA, itemsAVG)}
                    </div>
                </>: <>
                    <div className="section">
                        <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <h3>Codice Ateco</h3>
                            <Select id="prova" isClearable="true" className="mb-3 selectMercato" isDisabled={isDisabledMacroCategory} options={optionsCat} onChange={handleChangeMacro} />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledFirstCategory} onChange={handleChangeFirst} options={getFirstCategory(data, filterFirstCategory)} />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledSecondCategory} onChange={handleChangeSecond} options={getSecondCategory(data, filterSecondCategory)}  />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledThirdCategory} onChange={handleChangeThird}  options={getThirdCategory(data, filterThirdCategory, filterSecondCategory)}  />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledFourthCategory}  onChange={handleChangeFourth} options={getFourthCategory(data, filterSecondCategory, filterFourthCategory)}  />
                        </div>
                        <div className="col-md-3">
                            <h3>Range Fatturato</h3>
                            <Select name="ateco_range" isDisabled={isDisabledRange} className="mb-3" onChange={handleChangeRange} options={optionsRange} isClearable="true" />
                        </div>
                        <div className="col-md-3">
                            <h3>Partita IVA</h3>
                            <InputGroup className="mb-3">
                                <Form.Control onChange={handleChangePIVA} disabled={isDisabledPIVA} id='textPIVA' aria-label="Text input with checkbox" />
                            </InputGroup>
                        </div>
                        <div className="col-md-3">
                            <div className="card colorBackground">
                                <div className="card-body">
                                    <h3>Suggerimenti</h3>
                                    <div>
                                        <p className="text-warning" id='spiegazione'>Inserisci la Macro Categoria</p>
                                    </div>
                                    <div>
                                        <p className="text-danger" id="spiegazioneRange" >Devi inserire il range Fatturato</p>
                                    </div>
                                    <div>
                                        <h3>Calcolo</h3>
                                        <ul>
                                            <li><label>Codice Ateco: </label><span id='codiceAtecoCalcolo'></span></li>
                                            <li><label>Range Fatturato: </label><span id='rangeCalcolo'></span></li>
                                            <li><label>Partita IVA: </label><span id='partitaIVACalcolo'></span></li>
                                        </ul>
                                    </div>
                                    <button onChange={handleChangePIVA} onClick={handleSingleClick} id="buttonOnlyPIVA" className="boxRadius btn btn-outline-primary w-100 mt-3 d-none">Analizza</button>
                                    <button onClick={handleClickClearAll} className='boxRadius btn btn-outline-danger w-100 mt-3'><i class="bi bi-trash"></i></button>
                                </div> 
                            </div>
                        </div> 
                    </div>    
                </div>
                        <div className="colorBackground"><div className="d-flex justify-content-center align-items-center spinner"><LoadingResults /></div></div>
                    </div>
                </> : 
                <>
                    <Popup img={errorImage} title="Non è presente nessun bilancio pubblico per questa P.IVA" />
                    <div className="section">
                        <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <h3>Codice Ateco</h3>
                            <Select id="prova" isClearable="true" className="mb-3 selectMercato" isDisabled={isDisabledMacroCategory} options={optionsCat} onChange={handleChangeMacro} />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledFirstCategory} onChange={handleChangeFirst} options={getFirstCategory(data, filterFirstCategory)} />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledSecondCategory} onChange={handleChangeSecond} options={getSecondCategory(data, filterSecondCategory)}  />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledThirdCategory} onChange={handleChangeThird}  options={getThirdCategory(data, filterThirdCategory, filterSecondCategory)}  />
                            <Select isClearable='true' className="mb-3 selectMercato" isDisabled={isDisabledFourthCategory}  onChange={handleChangeFourth} options={getFourthCategory(data, filterSecondCategory, filterFourthCategory)}  />
                        </div>
                        <div className="col-md-3">
                            <h3>Range Fatturato</h3>
                            <Select name="ateco_range" isDisabled={isDisabledRange} className="mb-3" onChange={handleChangeRange} options={optionsRange} isClearable="true" />
                        </div>
                        <div className="col-md-3">
                            <h3>Partita IVA</h3>
                            <InputGroup className="mb-3">
                                <Form.Control onChange={handleChangePIVA} disabled={isDisabledPIVA} id='textPIVA' aria-label="Text input with checkbox" />
                            </InputGroup>
                        </div>
                        <div className="col-md-3">
                            <div className="card colorBackground">
                                <div className="card-body">
                                    <h3>Suggerimenti</h3>
                                    <div>
                                        <p className="text-warning" id='spiegazione'>Inserisci la Macro Categoria</p>
                                    </div>
                                    <div>
                                        <p className="text-danger" id="spiegazioneRange" >Devi inserire il range Fatturato</p>
                                    </div>
                                    <div>
                                        <h3>Calcolo</h3>
                                        <ul>
                                            <li><label>Codice Ateco: </label><span id='codiceAtecoCalcolo'></span></li>
                                            <li><label>Range Fatturato: </label><span id='rangeCalcolo'></span></li>
                                            <li><label>Partita IVA: </label><span id='partitaIVACalcolo'></span></li>
                                        </ul>
                                    </div>
                                    <button onChange={handleChangePIVA} onClick={handleSingleClick} id="buttonOnlyPIVA" className="boxRadius btn btn-outline-primary w-100 mt-3 d-none">Analizza</button>
                                    <button onClick={handleClickClearAll} className='boxRadius btn btn-outline-danger w-100 mt-3'><i class="bi bi-trash"></i></button>
                                </div> 
                            </div>
                        </div> 
                    </div>    
                </div>
                    </div>
                </>
            }
        </>
    )
    } export default AlberaturaMercato


function getFirstCategory(data, filterFirstCategory) {
    const options = []
    let labelData = ""
    let control = ""
    let count = 0;
    for(let i = 0; i < data.records.length;i++){
        labelData = data.records[i][1].split('.')
        if(labelData[0] !== control){
            if(macroLetter.records[count][0] === filterFirstCategory){
                if(labelData[0] >= macroLetter.records[count][1] && labelData[0] <= macroLetter.records[count][2]){
                    options[i] = {value: labelData[0], label: data.records[i][2]}
                }
            } else {
                if(count < 20){
                    count = count + 1;
                }
            }
            control = labelData[0]
        }
    }
    return options
}

function getSecondCategory(data, filterSecondCategory){
    const options = []
    let labelData = ""
    let control = ""
    let filtrato = data.records.filter(d => {
        let labelData = d[1].split('.')
        return labelData[0] === filterSecondCategory
    })
    for(let i = 1; i < filtrato.length ; i++){
        labelData = filtrato[i][1].split('.')
        labelData = Array.from(labelData[1])
        if(labelData[0] !== control){
            options[i] = {value: labelData[0], label: filtrato[i][2]}
            control = labelData[0]
        }
    }
    return options
}

function getThirdCategory(data, filterThirdCategory, filterSecondCategory){
    const options = []
    let labelData = ""
    let filtratoThird = []
    let control = ""
    let filtratoSecond = data.records.filter(d => {
        let labelData = d[1].split('.')
        return labelData[0] === filterSecondCategory
    })
    for(let j = 1; j < filtratoSecond.length; j++){
        labelData = filtratoSecond[j][1].split('.')
    }

    filtratoThird = filtratoSecond.filter(d => {
        let labelData = Array.from(d[1])
        return labelData[3] === filterThirdCategory
    })

    for(let i = 1; i < filtratoThird.length ; i++){
        labelData = filtratoThird[i][1].split('.')
        if(labelData[1] !== control){
            options[i] = {value: labelData[1], label: filtratoThird[i][2]}
            control = labelData[1]
        }
    }
    return options
}


function getFourthCategory(data, filterSecondCategory, filterFourthCategory){
    const options = []
    let labelData = ""
    let filtratoFourth = []
    let filtratoSecond = data.records.filter(d => {
        let labelData = d[1].split('.')
        return labelData[0] === filterSecondCategory
    })

    for(let j = 1; j < filtratoSecond.length; j++){
        labelData = filtratoSecond[j][1].split('.')
    }

    filtratoFourth = filtratoSecond.filter(d => {
        let labelData = d[1].split('.')
        return labelData[1] === filterFourthCategory
    })

    for(let i = 1; i < filtratoFourth.length ; i++){
        labelData = filtratoFourth[i][1].split('.')
        if(labelData[2] < 10 || labelData[2] === "00"){
            if(labelData[2].length === 1){
                options[i] = {value: labelData[2] + "0", label: filtratoFourth[i][2]}
            } else {
                options[i] = {value: labelData[2], label: filtratoFourth[i][2]}
            }
        }
    }
    return options
}

function checkCallAVG(error, items, isLoaded){
    if(error) {
        return <div>Error: {error.message}</div>
    } else if (!isLoaded) {
        return <div className="colorBackground"><div className="d-flex justify-content-center align-items-center spinner"><LoadingResults /></div></div>
    } else {
        if (items.message === 'Missing required request parameters: [ateco_range]') {
            return ''
        } else {
            return stampa(items)
        }
    }
}

function checkCallIVA(errorPIVA, itemsPIVA, isLoadedPIVA, itemsAVG){
    if(errorPIVA) {
        return <div>Error: {errorPIVA.message}</div>
    } else if (!isLoadedPIVA) {
        return <div className="colorBackground"><div className="d-flex justify-content-center align-items-center spinner"><LoadingResults /></div></div>
    } else {
        if (itemsAVG.message === 'Missing required request parameters: [ateco_range]') {
            return ''
        } else {
            return stampaIVA(itemsAVG, itemsPIVA)
        }
    }
}


function checkOnlyIVA(errorAVG, errorPIVA, isLoadedAVG, isLoadedPIVA, itemsAVG, itemsPIVA) {
    if(errorAVG || errorPIVA){
        if(errorAVG){
            return <div>Error: {errorAVG.message}</div>
        } else {
            return <div>Error: {errorPIVA.message}</div>
        }
    } else if(!isLoadedAVG || !isLoadedPIVA){
        return <div className="colorBackground"><div className="d-flex justify-content-center align-items-center spinner"><LoadingResults /></div></div>
    } else {
        return stampaOnlyPIVA(itemsAVG, itemsPIVA)
    }
}



function stampa(data) {
    return (
        <>
            <div className="colorBackground" >
                <div className="container pt-5">
                    <button type="button" class="btn btn-outline-primary" onClick={() => {
                        document.getElementsByName('bar')[0].classList.add('d-none')
                        document.getElementsByName('cards')[0].classList.add('d-block')
                        document.getElementsByName('bar')[0].classList.remove('d-block')
                        document.getElementsByName('cards')[0].classList.remove('d-none')
                        document.getElementsByName('chart')[0].classList.add('d-none')
                        document.getElementsByName('chart')[0].classList.remove('d-block')
                    }}>Card view</button>  
                    <button type="button" class="btn btn-outline-primary" onClick={() => {
                        document.getElementsByName('bar')[0].classList.remove('d-none')
                        document.getElementsByName('cards')[0].classList.remove('d-block')
                        document.getElementsByName('bar')[0].classList.add('d-block')
                        document.getElementsByName('cards')[0].classList.add('d-none')
                        document.getElementsByName('chart')[0].classList.add('d-none')
                        document.getElementsByName('chart')[0].classList.remove('d-block')
                    }}>Bar view</button>
                    <button type="button" class="btn btn-outline-primary" onClick={() => {
                        document.getElementsByName('bar')[0].classList.remove('d-block')
                        document.getElementsByName('cards')[0].classList.remove('d-block')
                        document.getElementsByName('bar')[0].classList.add('d-none')
                        document.getElementsByName('cards')[0].classList.add('d-none')
                        document.getElementsByName('chart')[0].classList.add('d-block')
                        document.getElementsByName('chart')[0].classList.remove('d-none')
                    }}>Chart view</button>
                </div>
                <h1 className='text-center mt-5'>Analisi di Mercato</h1>
                <div name='bar' className='d-none'>
                    <StampaMercatoBar
                    totale={data.result.final_avg.totale_immobilizzazioni !== undefined ? addDot(data.result.final_avg.totale_immobilizzazioni) : "- - -"}
                    tot_val_produzione={data.result.final_avg.tot_val_produzione !== undefined ? addDot(data.result.final_avg.tot_val_produzione) : "- - -"}
                    totale_debiti={data.result.final_avg.totale_debiti !== undefined ? addDot(data.result.final_avg.totale_debiti) : "- - -"}
                    pfn_ebitda={data.result.final_avg.pfn_ebitda !== undefined ? addDot(data.result.final_avg.pfn_ebitda) : "- - -"}
                    totale_fondi_rischi={data.result.final_avg.totale_fondi_rischi !== undefined ? addDot(data.result.final_avg.totale_fondi_rischi) : "- - -"}
                    attivo_circolante={data.result.final_avg.attivo_circolante !== undefined ? addDot(data.result.final_avg.attivo_circolante) : "- - -"}
                    ratei_risconti={data.result.final_avg.ratei_risconti !== undefined ? addDot(data.result.final_avg.ratei_risconti) : "- - -"}
                    ebitda={data.result.final_avg.ebitda !== undefined ? addDot(data.result.final_avg.ebitda) : "- - -"}
                    pfn={data.result.final_avg.pfn !== undefined ? addDot(data.result.final_avg.pfn) : "- - -"}
                    utile={data.result.final_avg.utile !== undefined ? addDot(data.result.final_avg.utile) : "- - -"}
                    debiti_breve={data.result.final_avg.debiti_breve !== undefined ? addDot(data.result.final_avg.debiti_breve) : "- - -"}
                    debiti_lungo={data.result.final_avg.debiti_lungo !== undefined ? addDot(data.result.final_avg.debiti_lungo) : "- - -"}
                    dipendenti={data.result.final_avg.dipendenti !== undefined ? addDot(data.result.final_avg.dipendenti) : "- - -"}
                    ev={data.result.final_avg["ebitda/vendite"] !== undefined ? addDot(data.result.final_avg["ebitda/vendite"]) : "- - -"}
                    ebitda_fatturato={data.result.final_avg.ebitda_fatturato !== undefined ? addDot(data.result.final_avg.ebitda_fatturato) : "- - -"}
                    fatturato={data.result.final_avg.fatturato !== undefined ? addDot(data.result.final_avg.fatturato) : "- - -"}
                    oneri_fin_ebitda={data.result.final_avg.oneri_fin_ebitda !== undefined ? addDot(data.result.final_avg.oneri_fin_ebitda) : "- - -"}
                    pn={data.result.final_avg.pn !== undefined ? addDot(data.result.final_avg.pn) : "- - -"}
                    pn_tot_passivo={data.result.final_avg.pn_tot_passivo !== undefined ? addDot(data.result.final_avg.pn_tot_passivo) : "- - -"}
                    tfr={data.result.final_avg.tfr !== undefined ? addDot(data.result.final_avg.tfr) : "- - -"}
                    />
                </div>
                <div name='cards'>
                    <StampaMercato totale={data.result.final_avg.totale_immobilizzazioni !== undefined ? addDot(data.result.final_avg.totale_immobilizzazioni) : "- - -"}
                        tot_val_produzione={data.result.final_avg.tot_val_produzione !== undefined ? addDot(data.result.final_avg.tot_val_produzione) : "- - -"}
                        totale_debiti={data.result.final_avg.totale_debiti !== undefined ? addDot(data.result.final_avg.totale_debiti) : "- - -"}
                        pfn_ebitda={data.result.final_avg.pfn_ebitda !== undefined ? addDot(data.result.final_avg.pfn_ebitda) : "- - -"}
                        totale_fondi_rischi={data.result.final_avg.totale_fondi_rischi !== undefined ? addDot(data.result.final_avg.totale_fondi_rischi) : "- - -"}
                        attivo_circolante={data.result.final_avg.attivo_circolante !== undefined ? addDot(data.result.final_avg.attivo_circolante) : "- - -"}
                        ratei_risconti={data.result.final_avg.ratei_risconti !== undefined ? addDot(data.result.final_avg.ratei_risconti) : "- - -"}
                        ebitda={data.result.final_avg.ebitda !== undefined ? addDot(data.result.final_avg.ebitda) : "- - -"}
                        pfn={data.result.final_avg.pfn !== undefined ? addDot(data.result.final_avg.pfn) : "- - -"}
                        utile={data.result.final_avg.utile !== undefined ? addDot(data.result.final_avg.utile) : "- - -"}
                        debiti_breve={data.result.final_avg.debiti_breve !== undefined ? addDot(data.result.final_avg.debiti_breve) : "- - -"}
                        debiti_lungo={data.result.final_avg.debiti_lungo !== undefined ? addDot(data.result.final_avg.debiti_lungo) : "- - -"}
                        dipendenti={data.result.final_avg.dipendenti !== undefined ? addDot(data.result.final_avg.dipendenti) : "- - -"}
                        ev={data.result.final_avg["ebitda/vendite"] !== undefined ? addDot(data.result.final_avg["ebitda/vendite"]) : "- - -"}
                        ebitda_fatturato={data.result.final_avg.ebitda_fatturato !== undefined ? addDot(data.result.final_avg.ebitda_fatturato) : "- - -"}
                        fatturato={data.result.final_avg.fatturato !== undefined ? addDot(data.result.final_avg.fatturato) : "- - -"}
                        oneri_fin_ebitda={data.result.final_avg.oneri_fin_ebitda !== undefined ? addDot(data.result.final_avg.oneri_fin_ebitda) : "- - -"}
                        pn={data.result.final_avg.pn !== undefined ? addDot(data.result.final_avg.pn) : "- - -"}
                        pn_tot_passivo={data.result.final_avg.pn_tot_passivo !== undefined ? addDot(data.result.final_avg.pn_tot_passivo) : "- - -"}
                        tfr={data.result.final_avg.tfr !== undefined ? addDot(data.result.final_avg.tfr) : "- - -"}
                    />
                </div>
                <div name='chart' className='d-none'>
                    <StampaChart
                    totale={data.result.final_avg.totale_immobilizzazioni !== undefined ? data.result.final_avg.totale_immobilizzazioni : "0"}
                    tot_val_produzione={data.result.final_avg.tot_val_produzione !== undefined ? data.result.final_avg.tot_val_produzione : "0"}
                    totale_debiti={data.result.final_avg.totale_debiti !== undefined ? data.result.final_avg.totale_debiti : "0"}
                    pfn_ebitda={data.result.final_avg.pfn_ebitda !== undefined ? data.result.final_avg.pfn_ebitda : "0"}
                    totale_fondi_rischi={data.result.final_avg.totale_fondi_rischi !== undefined ? data.result.final_avg.totale_fondi_rischi : "0"}
                    attivo_circolante={data.result.final_avg.attivo_circolante !== undefined ? data.result.final_avg.attivo_circolante: "0"}
                    ratei_risconti={data.result.final_avg.ratei_risconti !== undefined ? data.result.final_avg.ratei_risconti : "0"}
                    ebitda={data.result.final_avg.ebitda !== undefined ? data.result.final_avg.ebitda : "0"}
                    pfn={data.result.final_avg.pfn !== undefined ? data.result.final_avg.pfn : "0"}
                    utile={data.result.final_avg.utile !== undefined ? data.result.final_avg.utile : "0"}
                    debiti_breve={data.result.final_avg.debiti_breve !== undefined ? data.result.final_avg.debiti_breve : "0"}
                    debiti_lungo={data.result.final_avg.debiti_lungo !== undefined ? data.result.final_avg.debiti_lungo : "0"}
                    dipendenti={data.result.final_avg.dipendenti !== undefined ? data.result.final_avg.dipendenti: "0"}
                    ev={data.result.final_avg["ebitda/vendite"] !== undefined ? data.result.final_avg["ebitda/vendite"] : "0"}
                    ebitda_fatturato={data.result.final_avg.ebitda_fatturato !== undefined ? data.result.final_avg.ebitda_fatturato : "0"}
                    fatturato={data.result.final_avg.fatturato !== undefined ? data.result.final_avg.fatturato : "0"}
                    oneri_fin_ebitda={data.result.final_avg.oneri_fin_ebitda !== undefined ? data.result.final_avg.oneri_fin_ebitda : "0"}
                    pn={data.result.final_avg.pn !== undefined ? data.result.final_avg.pn: "0"}
                    pn_tot_passivo={data.result.final_avg.pn_tot_passivo !== undefined ? data.result.final_avg.pn_tot_passivo : "0"}
                    tfr={data.result.final_avg.tfr !== undefined ? data.result.final_avg.tfr : "0"}/>
                </div>
            </div>
        </>
    );
}

function stampaIVA(data, dataIVA) {
    return (
        <>
            <div className="colorBackground" >
                <div className="container pt-5">
                    <button type="button" class="btn btn-outline-primary" onClick={() => {
                        document.getElementsByName('bar')[0].classList.remove('d-none')
                        document.getElementsByName('bar')[0].classList.add('d-block')
                        document.getElementsByName('chart')[0].classList.add('d-none')
                        document.getElementsByName('chart')[0].classList.remove('d-block')
                    }}>Bar view</button>
                    <button type="button" class="btn btn-outline-primary" onClick={() => {
                        document.getElementsByName('bar')[0].classList.remove('d-block')
                        document.getElementsByName('bar')[0].classList.add('d-none')
                        document.getElementsByName('chart')[0].classList.add('d-block')
                        document.getElementsByName('chart')[0].classList.remove('d-none')
                    }}>Chart view</button>
                </div>
                <h1 className='text-center mt-5'>Analisi di Mercato</h1>
                <div name='bar' className='d-block'>
                    <StampaMercatoBar
                    totale={data.result.final_avg.totale_immobilizzazioni !== undefined ? addDot(data.result.final_avg.totale_immobilizzazioni) : "- - -"}
                    totaleIVA={dataIVA.results.year1.TOTALE_IMMOBILIZZAZIONI !== 'n.d.' ? addDot(dataIVA.results.year1.TOTALE_IMMOBILIZZAZIONI) : "- - -"}
                    totaleVariation={dataIVA.results.year1.TOTALE_IMMOBILIZZAZIONI !== 'n.d.' && data.result.final_avg.totale_immobilizzazioni !== undefined ? addDot(getVariation(dataIVA.results.year1.TOTALE_IMMOBILIZZAZIONI,data.result.final_avg.totale_immobilizzazioni)): ''}
                    tot_val_produzione={data.result.final_avg.tot_val_produzione !== undefined ? addDot(data.result.final_avg.tot_val_produzione) : "- - -"}
                    tot_val_produzioneIVA={dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE !== undefined ? addDot(dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE): '- - -'}
                    tot_val_produzioneVariation={dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE !== 'n.d.' && data.result.final_avg.tot_val_produzione  !== undefined ? addDot(getVariation(dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE,data.result.final_avg.tot_val_produzione )): ''}
                    totale_debiti={data.result.final_avg.totale_debiti !== undefined ? addDot(data.result.final_avg.totale_debiti) : "- - -"}
                    totale_debitiIVA={dataIVA.results.year1.TOTALE_DEBITI !== 'n.d.' ? addDot(dataIVA.results.year1.TOTALE_DEBITI): '- - -'}
                    totale_debitiVariation={dataIVA.results.year1.TOTALE_DEBITI !== 'n.d.' && data.result.final_avg.totale_debiti !== undefined ? addDot(getVariation(dataIVA.results.year1.TOTALE_DEBITI,data.result.final_avg.totale_debiti)): ''}
                    pfn_ebitda={data.result.final_avg.pfn_ebitda !== undefined ? addDot(data.result.final_avg.pfn_ebitda) : "- - -"}
                    pfn_ebitdaIVA={dataIVA.results.year1['Posizione finanziaria netta EUR'] !== 'n.d.' &&  dataIVA.results.year1.EBITDA !== 'n.d.' ? addDot(getRapporto(dataIVA.results.year1['Posizione finanziaria netta EUR'],dataIVA.results.year1.EBITDA)): '- - -'}
                    pfn_ebitdaVariation={dataIVA.results.year1['Posizione finanziaria netta EUR'] !== 'n.d.' && dataIVA.results.year1.EBITDA  !== 'n.d.' && data.result.final_avg.pfn_ebitda !== undefined ? addDot(getVariationPFN(addDot(getRapporto(dataIVA.results.year1['Posizione finanziaria netta EUR'], dataIVA.results.year1.EBITDA)), data.result.final_avg.pfn_ebitda)): ''}
                    totale_fondi_rischi={data.result.final_avg.totale_fondi_rischi !== undefined ? addDot(data.result.final_avg.totale_fondi_rischi) : "- - -"}
                    totale_fondi_rischiIVA={dataIVA.results.year1.TOTALE_FONDI_RISCHI !== 'n.d.' ? addDot(dataIVA.results.year1.TOTALE_FONDI_RISCHI): '- - -'}
                    totale_fondi_rischiVariation={dataIVA.results.year1.TOTALE_FONDI_RISCHI !== 'n.d.' && data.result.final_avg.totale_fondi_rischi !== undefined ? addDot(getVariation(dataIVA.results.year1.TOTALE_FONDI_RISCHI,data.result.final_avg.totale_fondi_rischi)): ''}
                    attivo_circolante={data.result.final_avg.attivo_circolante !== undefined ? addDot(data.result.final_avg.attivo_circolante) : "- - -"}
                    attivo_circolanteIVA={dataIVA.results.year1.ATTIVO_CIRCOLANTE !== 'n.d.' ? addDot(dataIVA.results.year1.ATTIVO_CIRCOLANTE): '- - -'}
                    attivo_circolanteVariation={dataIVA.results.year1.ATTIVO_CIRCOLANTE !== 'n.d.' && data.result.final_avg.attivo_circolante !== undefined ? addDot(getVariation(dataIVA.results.year1.ATTIVO_CIRCOLANTE,data.result.final_avg.attivo_circolante)): ''}
                    ratei_risconti={data.result.final_avg.ratei_risconti !== undefined ? addDot(data.result.final_avg.ratei_risconti) : "- - -"}
                    ratei_riscontiIVA={dataIVA.results.year1.RATEI_E_RISCONTI2 !== 'n.d.' ? addDot(dataIVA.results.year1.RATEI_E_RISCONTI2): '- - -'}
                    ratei_riscontiVariation={dataIVA.results.year1.RATEI_E_RISCONTI  !== 'n.d.' && data.result.final_avg.ratei_risconti !== undefined ? addDot(getVariation(dataIVA.results.year1.RATEI_E_RISCONTI2 ,data.result.final_avg.ratei_risconti)): ''}
                    ebitda={data.result.final_avg.ebitda !== undefined ? addDot(data.result.final_avg.ebitda) : "- - -"}
                    ebitdaIVA={dataIVA.results.year1.EBITDA !== 'n.d.' ? addDot(dataIVA.results.year1.EBITDA): '- - -'}
                    ebitdaVariation={dataIVA.results.year1.EBITDA  !== 'n.d.' && data.result.final_avg.ebitda !== undefined ? addDot(getVariation(dataIVA.results.year1.EBITDA ,data.result.final_avg.ebitda)): ''}
                    pfn={data.result.final_avg.pfn !== undefined ? addDot(data.result.final_avg.pfn) : "- - -"}
                    pfnIVA={dataIVA.results.year1['Posizione finanziaria netta EUR'] !== 'n.d.' ? addDot(dataIVA.results.year1['Posizione finanziaria netta EUR']): '- - -'}
                    pfnVariation={dataIVA.results.year1['Posizione finanziaria netta EUR']  !== 'n.d.' && data.result.final_avg.pfn !== undefined ? addDot(getVariationPFN(dataIVA.results.year1['Posizione finanziaria netta EUR'] ,data.result.final_avg.pfn)): ''}
                    utile={data.result.final_avg.utile !== undefined ? addDot(data.result.final_avg.utile) : "- - -"}
                    utileIVA={dataIVA.results.year1.Utile_perdita_di_esercizio !== 'n.d.' ? addDot(dataIVA.results.year1.Utile_perdita_di_esercizio): '- - -'}
                    utileVariation={dataIVA.results.year1.Utile_perdita_di_esercizio  !== 'n.d.' && data.result.final_avg.utile !== undefined ? addDot(getVariation(dataIVA.results.year1.Utile_perdita_di_esercizio ,data.result.final_avg.utile)): ''}
                    debiti_breve={data.result.final_avg.debiti_breve !== undefined ? addDot(data.result.final_avg.debiti_breve) : "- - -"}
                    debiti_breveIVA={dataIVA.results.year1.DEBITI_A_BREVE !== 'n.d.' ? addDot(dataIVA.results.year1.DEBITI_A_BREVE): '- - -'}
                    debiti_breveVariation={dataIVA.results.year1.DEBITI_A_BREVE  !== 'n.d.' && data.result.final_avg.debiti_breve !== undefined ? addDot(getVariation(dataIVA.results.year1.DEBITI_A_BREVE, data.result.final_avg.debiti_breve)): ''}
                    debiti_lungo={data.result.final_avg.debiti_lungo !== undefined ? addDot(data.result.final_avg.debiti_lungo) : "- - -"}
                    debiti_lungoIVA={dataIVA.results.year1.DEBITI_A_OLTRE !== 'n.d.' ? addDot(dataIVA.results.year1.DEBITI_A_OLTRE): '- - -'}
                    debiti_lungoVariation={dataIVA.results.year1.DEBITI_A_OLTRE  !== 'n.d.' && data.result.final_avg.debiti_lungo !== undefined ? addDot(getVariation(dataIVA.results.year1.DEBITI_A_OLTRE, data.result.final_avg.debiti_lungo)): ''}
                    dipendenti={data.result.final_avg.dipendenti !== undefined ? addDot(data.result.final_avg.dipendenti) : "- - -"}
                    dipendentiIVA={dataIVA.results.year1.Dipendenti !== 'n.d.' ? addDot(dataIVA.results.year1.Dipendenti): '- - -'}
                    dipendentiVariation={dataIVA.results.year1.Dipendenti  !== 'n.d.' && data.result.final_avg.dipendenti !== undefined ? addDot(getVariation(dataIVA.results.year1.Dipendenti, data.result.final_avg.dipendenti)): ''}
                    ev={data.result.final_avg["ebitda/vendite"] !== undefined ? addDot(data.result.final_avg["ebitda/vendite"]) : "- - -"}
                    evIVA={dataIVA.results.year1['EBITDA/Vendite (%)%'] !== 'n.d.' ? addDot(dataIVA.results.year1['EBITDA/Vendite (%)%']): '- - -'}
                    evVariation={dataIVA.results.year1['EBITDA/Vendite (%)%']  !== 'n.d.' && data.result.final_avg["ebitda/vendite"] !== undefined ? addDot(getVariation(dataIVA.results.year1['EBITDA/Vendite (%)%'], data.result.final_avg["ebitda/vendite"])): ''}
                    ebitda_fatturato={data.result.final_avg.ebitda_fatturato !== undefined ? addDot(data.result.final_avg.ebitda_fatturato) : "- - -"}
                    ebitda_fatturatoIVA={dataIVA.results.year1.EBITDA !== 'n.d.' && dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE !== 'n.d.' ? addDot(getRapporto(dataIVA.results.year1.EBITDA, dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE)): '- - -'}
                    ebitda_fatturatoVariation={dataIVA.results.year1.EBITDA !== 'n.d.' && dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE !== 'n.d.' && data.result.final_avg.ebitda_fatturato !== undefined ? addDot(getVariation(getRapporto(dataIVA.results.year1.EBITDA, dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE), data.result.final_avg.ebitda_fatturato)): ''}
                    fatturato={data.result.final_avg.fatturato !== undefined ? addDot(data.result.final_avg.fatturato) : "- - -"}
                    fatturatoIVA={dataIVA.results.year1.Ricavi_vendite_e_prestazioni !== 'n.d.' ? addDot(dataIVA.results.year1.Ricavi_vendite_e_prestazioni): '- - -'}
                    fatturatoVariation={dataIVA.results.year1.Ricavi_vendite_e_prestazioni  !== 'n.d.' && data.result.final_avg.fatturato !== undefined ? addDot(getVariation(dataIVA.results.year1.Ricavi_vendite_e_prestazioni, data.result.final_avg.fatturato)): ''}
                    oneri_fin_ebitda={data.result.final_avg.oneri_fin_ebitda !== undefined ? addDot(data.result.final_avg.oneri_fin_ebitda) : "- - -"}
                    oneri_fin_ebitdaIVA={dataIVA.results.year1.Totale_Oneri_finanziari !== 'n.d.' ? addDot(getRapporto(dataIVA.results.year1.Totale_Oneri_finanziari, dataIVA.results.year1.EBITDA)): '- - -'}
                    oneri_fin_ebitdaVariation={dataIVA.results.year1.Totale_Oneri_finanziari  !== 'n.d.' && data.result.final_avg.oneri_fin_ebitda !== undefined ? addDot(getVariation(dataIVA.results.year1.Totale_Oneri_finanziari, data.result.final_avg.oneri_fin_ebitda)): ''}
                    pn={data.result.final_avg.pn !== undefined ? addDot(data.result.final_avg.pn) : "- - -"}
                    pnIVA={dataIVA.results.year1.TOTALE_PATRIMONIO_NETTO !== 'n.d.' ? addDot(dataIVA.results.year1.TOTALE_PATRIMONIO_NETTO): '- - -'}
                    pnVariation={dataIVA.results.year1.TOTALE_PATRIMONIO_NETTO  !== 'n.d.' && data.result.final_avg.pn !== undefined ? addDot(getVariation(dataIVA.results.year1.TOTALE_PATRIMONIO_NETTO, data.result.final_avg.pn)): ''}
                    pn_tot_passivo={data.result.final_avg.pn_tot_passivo !== undefined ? addDot(data.result.final_avg.pn_tot_passivo) : "- - -"}
                    pn_tot_passivoIVA={dataIVA.results.year1.TOTALE_PASSIVO !== 'n.d.' ? addDot(getRapporto(dataIVA.results.year1.TOTALE_PATRIMONIO_NETTO, dataIVA.results.year1.TOTALE_PASSIVO) * 100): '0'}
                    pn_tot_passivoVariation={dataIVA.results.year1.TOTALE_PASSIVO  !== 'n.d.' && data.result.final_avg.pn_tot_passivo !== undefined ? addDot(getVariation(getRapporto(dataIVA.results.year1.TOTALE_PATRIMONIO_NETTO, dataIVA.results.year1.TOTALE_PASSIVO) * 100, data.result.final_avg.pn_tot_passivo)): ''}
                    tfr={data.result.final_avg.tfr !== undefined ? addDot(data.result.final_avg.tfr) : "- - -"}
                    tfrIVA={dataIVA.results.year1.TRATTAMENTO_DI_FINE_RAPPORTO !== 'n.d.' ? addDot(dataIVA.results.year1.TRATTAMENTO_DI_FINE_RAPPORTO): '- - -'}
                    tfrVariation={dataIVA.results.year1.TRATTAMENTO_DI_FINE_RAPPORTO  !== 'n.d.' && data.result.final_avg.tfr !== undefined ? addDot(getVariation(dataIVA.results.year1.TRATTAMENTO_DI_FINE_RAPPORTO, data.result.final_avg.tfr)): ''}
                    />
                </div>
                <div name='chart' className='d-none'>
                    <StampaChartPIVA
                    totale={data.result.final_avg.totale_immobilizzazioni !== undefined ? data.result.final_avg.totale_immobilizzazioni : "0"}
                    tot_val_produzione={data.result.final_avg.tot_val_produzione !== undefined ? data.result.final_avg.tot_val_produzione : "0"}
                    totale_debiti={data.result.final_avg.totale_debiti !== undefined ? data.result.final_avg.totale_debiti : "0"}
                    pfn_ebitda={data.result.final_avg.pfn_ebitda !== undefined ? data.result.final_avg.pfn_ebitda : "0"}
                    totale_fondi_rischi={data.result.final_avg.totale_fondi_rischi !== undefined ? data.result.final_avg.totale_fondi_rischi : "0"}
                    attivo_circolante={data.result.final_avg.attivo_circolante !== undefined ? data.result.final_avg.attivo_circolante: "0"}
                    ratei_risconti={data.result.final_avg.ratei_risconti !== undefined ? data.result.final_avg.ratei_risconti : "0"}
                    ebitda={data.result.final_avg.ebitda !== undefined ? data.result.final_avg.ebitda : "0"}
                    pfn={data.result.final_avg.pfn !== undefined ? data.result.final_avg.pfn : "0"}
                    utile={data.result.final_avg.utile !== undefined ? data.result.final_avg.utile : "0"}
                    debiti_breve={data.result.final_avg.debiti_breve !== undefined ? data.result.final_avg.debiti_breve : "0"}
                    debiti_lungo={data.result.final_avg.debiti_lungo !== undefined ? data.result.final_avg.debiti_lungo : "0"}
                    dipendenti={data.result.final_avg.dipendenti !== undefined ? data.result.final_avg.dipendenti: "0"}
                    ev={data.result.final_avg["ebitda/vendite"] !== undefined ? data.result.final_avg["ebitda/vendite"] : "0"}
                    ebitda_fatturato={data.result.final_avg.ebitda_fatturato !== undefined ? data.result.final_avg.ebitda_fatturato : "0"}
                    fatturato={data.result.final_avg.fatturato !== undefined ? data.result.final_avg.fatturato : "0"}
                    oneri_fin_ebitda={data.result.final_avg.oneri_fin_ebitda !== undefined ? data.result.final_avg.oneri_fin_ebitda : "0"}
                    pn={data.result.final_avg.pn !== undefined ? data.result.final_avg.pn: "0"}
                    pn_tot_passivo={data.result.final_avg.pn_tot_passivo !== undefined ? data.result.final_avg.pn_tot_passivo : "0"}
                    tfr={data.result.final_avg.tfr !== undefined ? data.result.final_avg.tfr : "0"}
                    totaleIVA={dataIVA.results.year1.TOTALE_IMMOBILIZZAZIONI !== 'n.d.' ? dataIVA.results.year1.TOTALE_IMMOBILIZZAZIONI : "0"}
                    tot_val_produzioneIVA={dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE !== undefined ? dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE: '0'}
                    totale_debitiIVA={dataIVA.results.year1.TOTALE_DEBITI !== 'n.d.' ? dataIVA.results.year1.TOTALE_DEBITI: '0'}
                    pfn_ebitdaIVA={dataIVA.results.year1['Posizione finanziaria netta EUR'] !== 'n.d.' &&  dataIVA.results.year1.EBITDA !== 'n.d.' ? getRapporto(dataIVA.results.year1['Posizione finanziaria netta EUR'],dataIVA.results.year1.EBITDA): '0'}
                    totale_fondi_rischiIVA={dataIVA.results.year1.TOTALE_FONDI_RISCHI !== 'n.d.' ? dataIVA.results.year1.TOTALE_FONDI_RISCHI: '0'}
                    attivo_circolanteIVA={dataIVA.results.year1.ATTIVO_CIRCOLANTE !== 'n.d.' ? dataIVA.results.year1.ATTIVO_CIRCOLANTE: '0'}
                    ratei_riscontiIVA={dataIVA.results.year1.RATEI_E_RISCONTI2 !== 'n.d.' ? dataIVA.results.year1.RATEI_E_RISCONTI2: '0'}
                    ebitdaIVA={dataIVA.results.year1.EBITDA !== 'n.d.' ? dataIVA.results.year1.EBITDA: '0'}
                    pfnIVA={dataIVA.results.year1['Posizione finanziaria netta EUR'] !== 'n.d.' ? dataIVA.results.year1['Posizione finanziaria netta EUR']: '0'}
                    utileIVA={dataIVA.results.year1.Utile_perdita_di_esercizio !== 'n.d.' ? dataIVA.results.year1.Utile_perdita_di_esercizio: '0'}
                    debiti_breveIVA={dataIVA.results.year1.DEBITI_A_BREVE !== 'n.d.' ? dataIVA.results.year1.DEBITI_A_BREVE: '0'}
                    debiti_lungoIVA={dataIVA.results.year1.DEBITI_A_OLTRE !== 'n.d.' ? dataIVA.results.year1.DEBITI_A_OLTRE: '0'}
                    dipendentiIVA={dataIVA.results.year1.Dipendenti !== 'n.d.' ? dataIVA.results.year1.Dipendenti: '0'}
                    evIVA={dataIVA.results.year1['EBITDA/Vendite (%)%'] !== 'n.d.' ? dataIVA.results.year1['EBITDA/Vendite (%)%']: '0'}
                    ebitda_fatturatoIVA={dataIVA.results.year1.EBITDA !== 'n.d.' && dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE !== 'n.d.' ? getRapporto(dataIVA.results.year1.EBITDA, dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE): '0'}
                    fatturatoIVA={dataIVA.results.year1.Ricavi_vendite_e_prestazioni !== 'n.d.' ? dataIVA.results.year1.Ricavi_vendite_e_prestazioni: '0'}
                    oneri_fin_ebitdaIVA={dataIVA.results.year1.Totale_Oneri_finanziari !== 'n.d.' ? getVariation(getRapporto(dataIVA.results.year1.Totale_Oneri_finanziari, dataIVA.results.year1.EBITDA) * 100, data.result.final_avg.oneri_fin_ebitda): '0'}
                    pnIVA={dataIVA.results.year1.TOTALE_PATRIMONIO_NETTO !== 'n.d.' ? dataIVA.results.year1.TOTALE_PATRIMONIO_NETTO: '0'}
                    pn_tot_passivoIVA={dataIVA.results.year1.TOTALE_PASSIVO !== 'n.d.' ? getRapporto(dataIVA.results.year1.TOTALE_PATRIMONIO_NETTO, dataIVA.results.year1.TOTALE_PASSIVO) * 100: '0'}
                    tfrIVA={dataIVA.results.year1.TRATTAMENTO_DI_FINE_RAPPORTO !== 'n.d.' ? dataIVA.results.year1.TRATTAMENTO_DI_FINE_RAPPORTO: '0'}
                    />
                </div>
            </div>
        </>
    );
}

function stampaOnlyPIVA(data, dataIVA){
        return (
            <>
                <div className="colorBackground" >
                    <div className="container pt-5">
                        <button type="button" class="btn btn-outline-primary" onClick={() => {
                            document.getElementsByName('bar')[0].classList.remove('d-none')
                            document.getElementsByName('bar')[0].classList.add('d-block')
                            document.getElementsByName('chart')[0].classList.add('d-none')
                            document.getElementsByName('chart')[0].classList.remove('d-block')
                        }}>Bar view</button>
                        <button type="button" class="btn btn-outline-primary" onClick={() => {
                            document.getElementsByName('bar')[0].classList.remove('d-block')
                            document.getElementsByName('bar')[0].classList.add('d-none')
                            document.getElementsByName('chart')[0].classList.add('d-block')
                            document.getElementsByName('chart')[0].classList.remove('d-none')
                        }}>Chart view</button>
                    </div>
                    <h1 className='text-center mt-5'>Analisi di Mercato</h1>
                    <div name='bar' className='d-block'>
                        <StampaMercatoBar
                        totale={data.result.final_avg.totale_immobilizzazioni !== undefined ? addDot(data.result.final_avg.totale_immobilizzazioni) : "- - -"}
                        totaleIVA={dataIVA.results.year1.TOTALE_IMMOBILIZZAZIONI !== 'n.d.' ? addDot(dataIVA.results.year1.TOTALE_IMMOBILIZZAZIONI) : "- - -"}
                        totaleVariation={dataIVA.results.year1.TOTALE_IMMOBILIZZAZIONI !== 'n.d.' && data.result.final_avg.totale_immobilizzazioni !== undefined ? addDot(getVariation(dataIVA.results.year1.TOTALE_IMMOBILIZZAZIONI,data.result.final_avg.totale_immobilizzazioni)): ''}
                        tot_val_produzione={data.result.final_avg.tot_val_produzione !== undefined ? addDot(data.result.final_avg.tot_val_produzione) : "- - -"}
                        tot_val_produzioneIVA={dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE !== undefined ? addDot(dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE): '- - -'}
                        tot_val_produzioneVariation={dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE !== 'n.d.' && data.result.final_avg.tot_val_produzione  !== undefined ? addDot(getVariation(dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE,data.result.final_avg.tot_val_produzione )): ''}
                        totale_debiti={data.result.final_avg.totale_debiti !== undefined ? addDot(data.result.final_avg.totale_debiti) : "- - -"}
                        totale_debitiIVA={dataIVA.results.year1.TOTALE_DEBITI !== 'n.d.' ? addDot(dataIVA.results.year1.TOTALE_DEBITI): '- - -'}
                        totale_debitiVariation={dataIVA.results.year1.TOTALE_DEBITI !== 'n.d.' && data.result.final_avg.totale_debiti !== undefined ? addDot(getVariation(dataIVA.results.year1.TOTALE_DEBITI,data.result.final_avg.totale_debiti)): ''}
                        pfn_ebitda={data.result.final_avg.pfn_ebitda !== undefined ? addDot(data.result.final_avg.pfn_ebitda) : "- - -"}
                        pfn_ebitdaIVA={dataIVA.results.year1['Posizione finanziaria netta EUR'] !== 'n.d.' &&  dataIVA.results.year1.EBITDA !== 'n.d.' ? addDot(getRapporto(dataIVA.results.year1['Posizione finanziaria netta EUR'],dataIVA.results.year1.EBITDA)): '- - -'}
                        pfn_ebitdaVariation={dataIVA.results.year1['Posizione finanziaria netta EUR'] !== 'n.d.' && dataIVA.results.year1.EBITDA  !== 'n.d.' && data.result.final_avg.pfn_ebitda !== undefined ? addDot(getVariationPFN(addDot(getRapporto(dataIVA.results.year1['Posizione finanziaria netta EUR'], dataIVA.results.year1.EBITDA)), data.result.final_avg.pfn_ebitda)): ''}
                        totale_fondi_rischi={data.result.final_avg.totale_fondi_rischi !== undefined ? addDot(data.result.final_avg.totale_fondi_rischi) : "- - -"}
                        totale_fondi_rischiIVA={dataIVA.results.year1.TOTALE_FONDI_RISCHI !== 'n.d.' ? addDot(dataIVA.results.year1.TOTALE_FONDI_RISCHI): '- - -'}
                        totale_fondi_rischiVariation={dataIVA.results.year1.TOTALE_FONDI_RISCHI !== 'n.d.' && data.result.final_avg.totale_fondi_rischi !== undefined ? addDot(getVariation(dataIVA.results.year1.TOTALE_FONDI_RISCHI,data.result.final_avg.totale_fondi_rischi)): ''}
                        attivo_circolante={data.result.final_avg.attivo_circolante !== undefined ? addDot(data.result.final_avg.attivo_circolante) : "- - -"}
                        attivo_circolanteIVA={dataIVA.results.year1.ATTIVO_CIRCOLANTE !== 'n.d.' ? addDot(dataIVA.results.year1.ATTIVO_CIRCOLANTE): '- - -'}
                        attivo_circolanteVariation={dataIVA.results.year1.ATTIVO_CIRCOLANTE !== 'n.d.' && data.result.final_avg.attivo_circolante !== undefined ? addDot(getVariation(dataIVA.results.year1['Capitale circolante netto'],data.result.final_avg.attivo_circolante)): ''}
                        ratei_risconti={data.result.final_avg.ratei_risconti !== undefined ? addDot(data.result.final_avg.ratei_risconti) : "- - -"}
                        ratei_riscontiIVA={dataIVA.results.year1.RATEI_E_RISCONTI2 !== 'n.d.' ? addDot(dataIVA.results.year1.RATEI_E_RISCONTI2): '- - -'}
                        ratei_riscontiVariation={dataIVA.results.year1.RATEI_E_RISCONTI2  !== 'n.d.' && data.result.final_avg.ratei_risconti !== undefined ? addDot(getVariation(dataIVA.results.year1.RATEI_E_RISCONTI2 ,data.result.final_avg.ratei_risconti)): ''}
                        ebitda={data.result.final_avg.ebitda !== undefined ? addDot(data.result.final_avg.ebitda) : "- - -"}
                        ebitdaIVA={dataIVA.results.year1.EBITDA !== 'n.d.' ? addDot(dataIVA.results.year1.EBITDA): '- - -'}
                        ebitdaVariation={dataIVA.results.year1.EBITDA  !== 'n.d.' && data.result.final_avg.ebitda !== undefined ? addDot(getVariation(dataIVA.results.year1.EBITDA ,data.result.final_avg.ebitda)): ''}
                        pfn={data.result.final_avg.pfn !== undefined ? addDot(data.result.final_avg.pfn) : "- - -"}
                        pfnIVA={dataIVA.results.year1['Posizione finanziaria netta EUR'] !== 'n.d.' ? addDot(dataIVA.results.year1['Posizione finanziaria netta EUR']): '- - -'}
                        pfnVariation={dataIVA.results.year1['Posizione finanziaria netta EUR']  !== 'n.d.' && data.result.final_avg.pfn !== undefined ? addDot(getVariationPFN(dataIVA.results.year1['Posizione finanziaria netta EUR'] ,data.result.final_avg.pfn)): ''}
                        utile={data.result.final_avg.utile !== undefined ? addDot(data.result.final_avg.utile) : "- - -"}
                        utileIVA={dataIVA.results.year1.Utile_perdita_di_esercizio !== 'n.d.' ? addDot(dataIVA.results.year1.Utile_perdita_di_esercizio): '- - -'}
                        utileVariation={dataIVA.results.year1.Utile_perdita_di_esercizio  !== 'n.d.' && data.result.final_avg.utile !== undefined ? addDot(getVariation(dataIVA.results.year1.Utile_perdita_di_esercizio ,data.result.final_avg.utile)): ''}
                        debiti_breve={data.result.final_avg.debiti_breve !== undefined ? addDot(data.result.final_avg.debiti_breve) : "- - -"}
                        debiti_breveIVA={dataIVA.results.year1.DEBITI_A_BREVE !== 'n.d.' ? addDot(dataIVA.results.year1.DEBITI_A_BREVE): '- - -'}
                        debiti_breveVariation={dataIVA.results.year1.DEBITI_A_BREVE  !== 'n.d.' && data.result.final_avg.debiti_breve !== undefined ? addDot(getVariation(dataIVA.results.year1.DEBITI_A_BREVE, data.result.final_avg.debiti_breve)): ''}
                        debiti_lungo={data.result.final_avg.debiti_lungo !== undefined ? addDot(data.result.final_avg.debiti_lungo) : "- - -"}
                        debiti_lungoIVA={dataIVA.results.year1.DEBITI_A_OLTRE !== 'n.d.' ? addDot(dataIVA.results.year1.DEBITI_A_OLTRE): '- - -'}
                        debiti_lungoVariation={dataIVA.results.year1.DEBITI_A_OLTRE  !== 'n.d.' && data.result.final_avg.debiti_lungo !== undefined ? addDot(getVariation(dataIVA.results.year1.DEBITI_A_OLTRE, data.result.final_avg.debiti_lungo)): ''}
                        dipendenti={data.result.final_avg.dipendenti !== undefined ? addDot(data.result.final_avg.dipendenti) : "- - -"}
                        dipendentiIVA={dataIVA.results.year1.Dipendenti !== 'n.d.' ? addDot(dataIVA.results.year1.Dipendenti): '- - -'}
                        dipendentiVariation={dataIVA.results.year1.Dipendenti  !== 'n.d.' && data.result.final_avg.dipendenti !== undefined ? addDot(getVariation(dataIVA.results.year1.Dipendenti, data.result.final_avg.dipendenti)): ''}
                        ev={data.result.final_avg["ebitda/vendite"] !== undefined ? addDot(data.result.final_avg["ebitda/vendite"]) : "- - -"}
                        evIVA={dataIVA.results.year1['EBITDA/Vendite (%)%'] !== 'n.d.' ? addDot(dataIVA.results.year1['EBITDA/Vendite (%)%']): '- - -'}
                        evVariation={dataIVA.results.year1['EBITDA/Vendite (%)%']  !== 'n.d.' && data.result.final_avg["ebitda/vendite"] !== undefined ? addDot(getVariation(dataIVA.results.year1['EBITDA/Vendite (%)%'], data.result.final_avg["ebitda/vendite"])): ''}
                        ebitda_fatturato={data.result.final_avg.ebitda_fatturato !== undefined ? addDot(data.result.final_avg.ebitda_fatturato) : "- - -"}
                        ebitda_fatturatoIVA={dataIVA.results.year1.EBITDA !== 'n.d.' && dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE !== 'n.d.' ? addDot(getRapporto(dataIVA.results.year1.EBITDA, dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE) * 100): '- - -'}
                        ebitda_fatturatoVariation={dataIVA.results.year1.EBITDA !== 'n.d.' && dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE !== 'n.d.' && data.result.final_avg.ebitda_fatturato !== undefined ? addDot(getVariation(getRapporto(dataIVA.results.year1.EBITDA, dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE), data.result.final_avg.ebitda_fatturato)): ''}
                        fatturato={data.result.final_avg.fatturato !== undefined ? addDot(data.result.final_avg.fatturato) : "- - -"}
                        fatturatoIVA={dataIVA.results.year1.Ricavi_vendite_e_prestazioni !== 'n.d.' ? addDot(dataIVA.results.year1.Ricavi_vendite_e_prestazioni): '- - -'}
                        fatturatoVariation={dataIVA.results.year1.Ricavi_vendite_e_prestazioni  !== 'n.d.' && data.result.final_avg.fatturato !== undefined ? addDot(getVariation(dataIVA.results.year1.Ricavi_vendite_e_prestazioni, data.result.final_avg.fatturato)): ''}
                        oneri_fin_ebitda={data.result.final_avg.oneri_fin_ebitda !== undefined ? addDot(data.result.final_avg.oneri_fin_ebitda) : "- - -"}
                        oneri_fin_ebitdaIVA={dataIVA.results.year1.Totale_Oneri_finanziari !== 'n.d.' ? addDot(getRapporto(dataIVA.results.year1.Totale_Oneri_finanziari, dataIVA.results.year1.EBITDA) * 100): '- - -'}
                        oneri_fin_ebitdaVariation={dataIVA.results.year1.Totale_Oneri_finanziari  !== 'n.d.' && data.result.final_avg.oneri_fin_ebitda !== undefined ? getVariation(getRapporto(dataIVA.results.year1.Totale_Oneri_finanziari, dataIVA.results.year1.EBITDA) * 100, data.result.final_avg.oneri_fin_ebitda): ''}
                        pn={data.result.final_avg.pn !== undefined ? addDot(data.result.final_avg.pn) : "- - -"}
                        pnIVA={dataIVA.results.year1.TOTALE_PATRIMONIO_NETTO !== 'n.d.' ? addDot(dataIVA.results.year1.TOTALE_PATRIMONIO_NETTO): '- - -'}
                        pnVariation={dataIVA.results.year1.TOTALE_PATRIMONIO_NETTO  !== 'n.d.' && data.result.final_avg.pn !== undefined ? addDot(getVariation(dataIVA.results.year1.TOTALE_PATRIMONIO_NETTO, data.result.final_avg.pn)): ''}
                        pn_tot_passivo={data.result.final_avg.pn_tot_passivo !== undefined ? addDot(data.result.final_avg.pn_tot_passivo) : "- - -"}
                        pn_tot_passivoIVA={dataIVA.results.year1.TOTALE_PASSIVO !== 'n.d.' ? addDot(getRapporto(dataIVA.results.year1.TOTALE_PATRIMONIO_NETTO, dataIVA.results.year1.TOTALE_PASSIVO) * 100): '- - -'}
                        pn_tot_passivoVariation={dataIVA.results.year1.TOTALE_PASSIVO  !== 'n.d.' && data.result.final_avg.pn_tot_passivo !== undefined ? addDot(getVariation(getRapporto(dataIVA.results.year1.TOTALE_PATRIMONIO_NETTO, dataIVA.results.year1.TOTALE_PASSIVO) * 100, data.result.final_avg.pn_tot_passivo)): ''}
                        tfr={data.result.final_avg.tfr !== undefined ? addDot(data.result.final_avg.tfr) : "- - -"}
                        tfrIVA={dataIVA.results.year1.TRATTAMENTO_DI_FINE_RAPPORTO !== 'n.d.' ? addDot(dataIVA.results.year1.TRATTAMENTO_DI_FINE_RAPPORTO): '- - -'}
                        tfrVariation={dataIVA.results.year1.TRATTAMENTO_DI_FINE_RAPPORTO  !== 'n.d.' && data.result.final_avg.tfr !== undefined ? addDot(getVariation(dataIVA.results.year1.TRATTAMENTO_DI_FINE_RAPPORTO, data.result.final_avg.tfr)): ''}
                        />
                    </div>
                    <div name='chart' className='d-none'>
                        <StampaChartPIVA
                        totale={data.result.final_avg.totale_immobilizzazioni !== undefined ? data.result.final_avg.totale_immobilizzazioni : "0"}
                        tot_val_produzione={data.result.final_avg.tot_val_produzione !== undefined ? data.result.final_avg.tot_val_produzione : "0"}
                        totale_debiti={data.result.final_avg.totale_debiti !== undefined ? data.result.final_avg.totale_debiti : "0"}
                        pfn_ebitda={data.result.final_avg.pfn_ebitda !== undefined ? data.result.final_avg.pfn_ebitda : "0"}
                        totale_fondi_rischi={data.result.final_avg.totale_fondi_rischi !== undefined ? data.result.final_avg.totale_fondi_rischi : "0"}
                        attivo_circolante={data.result.final_avg.attivo_circolante !== undefined ? data.result.final_avg.attivo_circolante: "0"}
                        ratei_risconti={data.result.final_avg.ratei_risconti !== undefined ? data.result.final_avg.ratei_risconti : "0"}
                        ebitda={data.result.final_avg.ebitda !== undefined ? data.result.final_avg.ebitda : "0"}
                        pfn={data.result.final_avg.pfn !== undefined ? data.result.final_avg.pfn : "0"}
                        utile={data.result.final_avg.utile !== undefined ? data.result.final_avg.utile : "0"}
                        debiti_breve={data.result.final_avg.debiti_breve !== undefined ? data.result.final_avg.debiti_breve : "0"}
                        debiti_lungo={data.result.final_avg.debiti_lungo !== undefined ? data.result.final_avg.debiti_lungo : "0"}
                        dipendenti={data.result.final_avg.dipendenti !== undefined ? data.result.final_avg.dipendenti: "0"}
                        ev={data.result.final_avg["ebitda/vendite"] !== undefined ? data.result.final_avg["ebitda/vendite"] : "0"}
                        ebitda_fatturato={data.result.final_avg.ebitda_fatturato !== undefined ? data.result.final_avg.ebitda_fatturato : "0"}
                        fatturato={data.result.final_avg.fatturato !== undefined ? data.result.final_avg.fatturato : "0"}
                        oneri_fin_ebitda={data.result.final_avg.oneri_fin_ebitda !== undefined ? data.result.final_avg.oneri_fin_ebitda : "0"}
                        pn={data.result.final_avg.pn !== undefined ? data.result.final_avg.pn: "0"}
                        pn_tot_passivo={data.result.final_avg.pn_tot_passivo !== undefined ? data.result.final_avg.pn_tot_passivo : "0"}
                        tfr={data.result.final_avg.tfr !== undefined ? data.result.final_avg.tfr : "0"}
                        totaleIVA={dataIVA.results.year1.TOTALE_IMMOBILIZZAZIONI !== 'n.d.' ? dataIVA.results.year1.TOTALE_IMMOBILIZZAZIONI : "0"}
                        tot_val_produzioneIVA={dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE !== undefined ? dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE: '0'}
                        totale_debitiIVA={dataIVA.results.year1.TOTALE_DEBITI !== 'n.d.' ? dataIVA.results.year1.TOTALE_DEBITI: '0'}
                        pfn_ebitdaIVA={dataIVA.results.year1['Posizione finanziaria netta EUR'] !== 'n.d.' &&  dataIVA.results.year1.EBITDA !== 'n.d.' ? getRapporto(dataIVA.results.year1['Posizione finanziaria netta EUR'],dataIVA.results.year1.EBITDA): '0'}
                        totale_fondi_rischiIVA={dataIVA.results.year1.TOTALE_FONDI_RISCHI !== 'n.d.' ? dataIVA.results.year1.TOTALE_FONDI_RISCHI: '0'}
                        attivo_circolanteIVA={dataIVA.results.year1.ATTIVO_CIRCOLANTE !== 'n.d.' ? dataIVA.results.year1.ATTIVO_CIRCOLANTE: '0'}
                        ratei_riscontiIVA={dataIVA.results.year1.RATEI_E_RISCONTI !== 'n.d.' ? dataIVA.results.year1.RATEI_E_RISCONTI: '0'}
                        ebitdaIVA={dataIVA.results.year1.EBITDA !== 'n.d.' ? dataIVA.results.year1.EBITDA: '0'}
                        pfnIVA={dataIVA.results.year1['Posizione finanziaria netta EUR'] !== 'n.d.' ? dataIVA.results.year1['Posizione finanziaria netta EUR']: '0'}
                        utileIVA={dataIVA.results.year1.Utile_perdita_di_esercizio !== 'n.d.' ? dataIVA.results.year1.Utile_perdita_di_esercizio: '0'}
                        debiti_breveIVA={dataIVA.results.year1.DEBITI_A_BREVE !== 'n.d.' ? dataIVA.results.year1.DEBITI_A_BREVE: '0'}
                        debiti_lungoIVA={dataIVA.results.year1.DEBITI_A_OLTRE !== 'n.d.' ? dataIVA.results.year1.DEBITI_A_OLTRE: '0'}
                        dipendentiIVA={dataIVA.results.year1.Dipendenti !== 'n.d.' ? dataIVA.results.year1.Dipendenti: '0'}
                        evIVA={dataIVA.results.year1['EBITDA/Vendite (%)%'] !== 'n.d.' ? dataIVA.results.year1['EBITDA/Vendite (%)%']: '0'}
                        ebitda_fatturatoIVA={dataIVA.results.year1.EBITDA !== 'n.d.' && dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE !== 'n.d.' ? getRapporto(dataIVA.results.year1.EBITDA, dataIVA.results.year1.TOT_VAL_DELLA_PRODUZIONE): '0'}
                        fatturatoIVA={dataIVA.results.year1.Ricavi_vendite_e_prestazioni !== 'n.d.' ? dataIVA.results.year1.Ricavi_vendite_e_prestazioni: '0'}
                        oneri_fin_ebitdaIVA={dataIVA.results.year1.Totale_Oneri_finanziari !== 'n.d.' ? getVariation(getRapporto(dataIVA.results.year1.Totale_Oneri_finanziari, dataIVA.results.year1.EBITDA) * 100, data.result.final_avg.oneri_fin_ebitda): '0'}
                        pnIVA={dataIVA.results.year1.TOTALE_PATRIMONIO_NETTO !== 'n.d.' ? dataIVA.results.year1.TOTALE_PATRIMONIO_NETTO: '0'}
                        pn_tot_passivoIVA={dataIVA.results.year1.TOTALE_PASSIVO !== 'n.d.' ? getRapporto(dataIVA.results.year1.TOTALE_PATRIMONIO_NETTO, dataIVA.results.year1.TOTALE_PASSIVO) * 100: '0'}
                        tfrIVA={dataIVA.results.year1.TRATTAMENTO_DI_FINE_RAPPORTO !== 'n.d.' ? dataIVA.results.year1.TRATTAMENTO_DI_FINE_RAPPORTO: '0'}
                        />
                    </div>
                </div>
            </>
        );
}

function addDot(number) {
    return new Intl.NumberFormat('de-DE').format(parseFloat(number));
}

function getVariation(year1, year2){

    let num1 = parseFloat(year1)

    if(num1 < 0 && year2 > 0){
        let variation = parseFloat(((num1-year2) / year2) * 100).toFixed(2)
        variation = variation.replace('.',',')
        return variation
    } else if(num1 > 0 && year2 < 0){
        let variation = parseFloat(((num1+year2) / year2) * 100).toFixed(2)
        variation = variation.replace('.',',')
        return variation
    } else if(num1 < 0 && year2 < 0){
        let variation = parseFloat(((num1-year2) / year2) * (-100)).toFixed(2)
        variation = variation.replace('.',',')
        return variation
    } else {
        if(year2 !== 0){
            let variation = parseFloat(((num1-year2) / year2) * 100).toFixed(2)
            variation = variation.replace('.',',')
            return variation
        } else {
            return 0
        }
    }
}

function getVariationPFN(year1, year2){
    let cambioSegno = -1
    let num1 = parseFloat(year1)
    if(num1 < 0 && year2 > 0){
        let variation = parseFloat(((num1-year2) / year2) * 100).toFixed(2)
        variation = variation * cambioSegno
        variation = variation.toString().replace('.',',')
        return variation
    } else if(num1 > 0 && year2 < 0){
        let variation = parseFloat(((num1+year2) / year2) * 100).toFixed(2)
        variation = variation * cambioSegno
        variation = variation.toString().replace('.',',')
        return variation
    } else if(num1 < 0 && year2 < 0){
        let variation = parseFloat(((num1-year2) / year2) * (-100)).toFixed(2)
        variation = variation * cambioSegno
        variation = variation.toString().replace('.',',')
        return variation
    } else {
        if(year2 !== 0){
            let variation = parseFloat(((num1-year2) / year2) * 100).toFixed(2)
            variation = variation * cambioSegno
            variation = variation.toString().replace('.',',')
            return variation
        } else {
            return 0
        }
    }
}

function getRapporto(num1, num2){
    let rapporto = (num1 / num2)
    return parseFloat(rapporto).toFixed(4)
}