import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { ResultsSearch } from "../components/general/results";
import logoPmi from '../assets/logos/logo-pmi.png'
import Header from "../components/NavbarComponent";
import Footer from "../components/Footer";
import config from '../.config.json'
import { LoadingResults } from "../components/general/results";
import { Helmet } from 'react-helmet'
import BoxTitleSubTitle from "../components/BoxTitleSubTitle";
import BoxAnalisi from "../components/BoxAnalisi";
import backgroundAnalisi from '../assets/images/backgroundAnalisi.png'
import diagram from '../assets/images/diagram.png'
import cardremove1 from '../assets/images/cardremove1.png'
import danger from '../assets/images/danger.png'
import TextImageResults from "../components/TextImageResults";
import backgroundResultsImage from '../assets/images/backgroundResults1.png'
import backgroundResultsImage2 from '../assets/images/backgroundResults2.png'
import chartImage1 from '../assets/images/chartImage1.png'
import chartImage2 from '../assets/images/chartImage2.png'
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import imgError from '../assets/images/404.png'
import { Button, Col, Container, Row } from "react-bootstrap";
import logoOG from './../assets/logos/imgeOG.png'

export default function Results(props) {

    const [error, setError] = useState('')
    const [isLoaded, setIsLoaded] = useState(false)
    const [item, setItem] = useState([])
    const [itemAltreRicherce, setItemAltreRicerche] = useState([])
    const params = useParams()
    const navigate = useNavigate()
    const { state } = useLocation()


    const getBilancio = useCallback(async () => {
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': config.RESULTS,
            }
        }
        fetch("https://6kcj3trvik.execute-api.eu-south-1.amazonaws.com/v1/company_lake?piva=" + params.piva.split('-')[params.piva.split('-').length - 1], options)
            .then(res => res.json())
            .then((result) => {
                setIsLoaded(true)
                setItem(result)
                console.log(result)
            },
                (error) => {
                    setIsLoaded(true)
                    setError(error)
                })
    }, [params.piva]) 

    useEffect(() => {

        if(!state?.checkCompanyLake) {
            getBilancio()
            return
        }

        setItem(state.item)
        setIsLoaded(true)
    }, [state?.checkCompanyLake, state?.item, getBilancio])

    useEffect(() => {
        getAziendeCercate()
    }, [])

    async function getAziendeCercate() {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        
        fetch("https://officina10-public-assets.s3.eu-west-1.amazonaws.com/pmiagile/aziende.json", requestOptions)
            .then(response => response.text())
            .then(result => setItemAltreRicerche(JSON.parse(result)))
            .catch(error => console.log('error', error));
    }

    const stampaAziendeCercate = itemAltreRicherce.map(p => {

        let tmp_nome = ''
        const nome = removePartitaIva(p)

        return <><Button style={{backgroundColor: 'lightgrey'}} variant="" className="robotoNormal py-1 px-2 mt-2 ms-5" onClick={() => { navigate(`/search/${p}`) }}>{nome}</Button><br/></>

    })

    function removePartitaIva(string) {

        let tmp_nome = ''

        string.split('-').map((d, index) => {
            if(string.split('-').length === (index + 1)) {
                return tmp_nome
            }
            tmp_nome += d + ' '

        })
        return tmp_nome
    }

    function getPIVA(piva, codfisca) {
        if(piva === codfisca) {
            return `(${piva})`
        } else {
            return '(Partita IVA: '+ piva + ' | Codice Fiscale: ' + codfisca + ')'
        }
    }

    function getDescription(ragioneSociale, piva, codfisca, indirizzo, codicePostale, localita, codiceAteco, codiceAtecoEsteso, anniAttivita, ultimoBilancio) {
        let string = ''
        
        if(piva === codfisca) {
            string = ragioneSociale + ` (${piva}) - ` + indirizzo + ', ' + codicePostale + ', ' + localita + ' - CODICE ATECO: ' + codiceAteco + ` (${codiceAtecoEsteso})`
        } else {
            string = ragioneSociale + ` (Partita IVA: '+ ${piva} + ' | Codice Fiscale: ' + ${codfisca} + ') - ` + indirizzo + ', ' + codicePostale + ', ' + localita + ' - CODICE ATECO: ' + codiceAteco + ` (${codiceAtecoEsteso})`
        }

        if(anniAttivita !== undefined) {
            string += ' - ANNI DI ATTIVITà: ' + anniAttivita
        }

        if(ultimoBilancio !== undefined) {
            string += ' - ULTIMO BILANCIO DEPOSITATO: ' + ultimoBilancio
        }

        return string

    }

        if (error) {
            return <div>Error: {error.message}</div>
        } else if (!isLoaded) {
            return <div className="colorBackground d-flex justify-content-center" style={{height: '100vh'}}><div className="d-flex justify-content-center align-items-center spinner"><LoadingResults /></div></div>
        } else {
            if ((item.result.url === params.piva || state?.checkCompanyLake) && item.message !== 'There is no data for this Vat_Number' && item.message !== 'Internal server error' && item.message !== 'Endpoint request timed out') {
                return (
                    <>
                        <Helmet>
                            <title>{item.result.ragione_sociale} - {getPIVA(item.result.VAT_NUMBER, item.result.CODFISCA)}</title>
                            <link rel='canonical' href={'https://www.pmiagile.com/search/' + item.result.url} />
                            <meta name="description" content={getDescription(item.result.ragione_sociale, item.result.VAT_NUMBER, item.result.CODFISCA, item.result.indirizzo, item.result.codice_postale, item.result.localita, item.result.codice_ateco, item.result.codice_ateco_esteso, item.result.anni_attivita !== undefined ? item.result.anni_attivita : undefined, item.result.bilancio !== undefined ? item.result.bilancio.anno : undefined)} />
                            <meta property="og:url" content={'https://www.pmiagile.com/search/' + item.result.url} />
                            <meta property="og:url" content={'https://www.pmiagile.com/search/' + item.result.url} />
                            <meta property="og:type" content="website" />
                            <meta property="og:title" content={item.result.ragione_sociale + '-' + getPIVA(item.result.VAT_NUMBER, item.result.CODFISCA)} />
                            <meta property="og:description" content={getDescription(item.result.ragione_sociale, item.result.VAT_NUMBER, item.result.CODFISCA, item.result.indirizzo, item.result.codice_postale, item.result.localita, item.result.codice_ateco, item.result.codice_ateco_esteso, item.result.anni_attivita !== undefined ? item.result.anni_attivita : undefined, item.result.bilancio !== undefined ? item.result.bilancio.anno : undefined)} />
                            <meta property="og:image" content={'http://static.pmiagile.com/img-social-sharing.png'} />
                            
                            
                            <meta property="twitter:domain" content="pmiagile.com" />
                            <meta property="twitter:url" content={'https://www.pmiagile.com/search/' + item.result.url} />
                            <meta name="twitter:title" content={item.result.ragione_sociale + '-' + getPIVA(item.result.VAT_NUMBER, item.result.CODFISCA)} />
                            <meta name="twitter:description" content={getDescription(item.result.ragione_sociale, item.result.VAT_NUMBER, item.result.CODFISCA, item.result.indirizzo, item.result.codice_postale, item.result.localita, item.result.codice_ateco, item.result.codice_ateco_esteso, item.result.anni_attivita !== undefined ? item.result.anni_attivita : undefined, item.result.bilancio !== undefined ? item.result.bilancio.anno : undefined)} />
                            <meta name="twitter:image" content={'http://static.pmiagile.com/img-social-sharing.png'} />
                        </Helmet>
                        <Header link='/' logo={logoPmi} />
                        <ResultsSearch items={item} error={error} isLoaded={isLoaded} />
                        <BoxTitleSubTitle className={'mt-2'} textTitle={'<strong>Cosa trovi se attivi il profilo di PMI Agile'} textSubTitle={'Accesso alle informazioni economico-finaziarie e patrimoniali <br/>Con un click potrai ottenere in modo semplice e chiaro lo stato di qualsiasi azienda'} />
                        <BoxAnalisi text4={''} reverse={false} image1={diagram} text1={'Analisi <label style="font-weight: 600; color: #565656">Economica</label>'} image2={diagram} text2={'Analisi <label style="font-weight: 600; color: #565656">Dipendenti</label>'} image3={diagram} text3={'Analisi <label style="font-weight: 600; color: #565656">Patrimoniale</label>'} radius={'20px'} backgroundImage={backgroundAnalisi} className={'w-100 mt-5 py-5 boxAnalisi mx-5'} textButton={'Attiva ora la prova gratuita'} onClick={() => window.location.href = 'https://data.pmiagile.com/'} typeBootstrapButton={'primary'} textTitle={'Analisi di Bilancio'} textSubTitle={`Accesso alle informazioni economico-finaziarie e patrimoniali per la tua valutazione d'impresa`} checkButton={true} />
                        <TextImageResults imageSrc={chartImage1} backgroundImage={backgroundResultsImage} text='Grafici semplici e interattivi per una visione <br/>immediata dei <strong>dati di bilancio</strong>' />
                        <BoxAnalisi text4={''} reverse={true} image1={diagram} text1={'Analisi <label style="font-weight: 600; color: #565656">Affidamenti</label>'} image2={danger} text2={'Alert su <label style="font-weight: 600; color: #565656">Sofferenza</label>'} image3={cardremove1} text3={'Presenza <label style="font-weight: 600; color: #565656">Insoluti</label>'} radius={'20px'} backgroundImage={backgroundAnalisi} className={'mt-5 py-5 w-100 boxAnalisiReverse mx-5'} textButton={'Attiva ora la prova gratuita'} onClick={() => window.location.href = 'https://data.pmiagile.com/'} typeBootstrapButton={'primary'} textTitle={"Centrale Rischi Banca D'Italia"} textSubTitle={`La nostra piattaforma convertirà i dati presenti nella CR in rappresentazione grafiche chiare e immediate`} checkButton={true} />
                        <TextImageResults imageSrc={chartImage2} backgroundImage={backgroundResultsImage2} text='I nostri grafici aiuteranno ad avere una visione <br/>completa della propria <strong>storia creditizia</strong>' />
                        <Footer />
                    </>
                )
            } else {
                return (
                    <>
                        <Header link='/' logo={logoPmi} />
                        <Container>
                            <Row className="mt-5">
                                <Col>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <img src={imgError} className='img-fluid' alt="" />
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <a href="/" className="btn btn-primary styleButton">Ritorna alla Home</a>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <div>
                                            { item.result.url !== undefined ? 
                                            <>
                                                <p className="ms-5">Forse cercavi:</p> 
                                                <Button className="robotoNormal py-1 px-2 ms-5 mb-4" onClick={() => { navigate(`/search/${item.result.url}`) }}>{removePartitaIva(item.result.url)}</Button>
                                            </> : null
                                            }
                                            <p className="ms-5">Ultime ricerche sulla piattaforma:</p>
                                            {/* {
                                                itemAltreRicherce.map(p => {
                                                    return <Button style={{backgroundColor: 'lightgrey'}} variant="" className="robotoNormal py-1 px-2 mt-2 ms-5" onClick={() => { navigate(`/search/${p}`) }}>{p}</Button>
                                                })
                                            } */}
                                            {stampaAziendeCercate}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <Footer />
                    </>
                )
            }
        }
}

// function getQueryString() {
//     let q = "";
//     let queryString = window.location.search;
//     let urlParams = new URLSearchParams(queryString)
//     q = urlParams.get('q');
//     return q;
// }